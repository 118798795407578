import React, { memo, useState, useEffect, useCallback } from 'react';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';
import * as R from 'reactstrap';
import { newReview, editReview, deleteReview } from '@/functions';

const Footer = (props) => {
  const { 
    isEditable,
    body_review,
    match,
    store_review_user,
    review_error_recomendacao,
    review_error_nota,
  } = props; 
  const [nota_review, set_nota_review] = useState(0);
  
  const [isRecomendationActiveSIM, set_isRecomendationActiveSIM] = useState(false);
  const [isRecomendationActiveNAO, set_isRecomendationActiveNAO] = useState(false);
  const [starSelected_1, set_starSelected_1] = useState(false);
  const [starSelected_2, set_starSelected_2] = useState(false);
  const [starSelected_3, set_starSelected_3] = useState(false);
  const [starSelected_4, set_starSelected_4] = useState(false);
  const [starSelected_5, set_starSelected_5] = useState(false);
  
  const star_size = 36;

  const toggleRecomendation = useCallback((button) => {
    switch (button) {
      case 'SIM':
        set_isRecomendationActiveSIM(!isRecomendationActiveSIM);
        set_isRecomendationActiveNAO(false);
      break;
      case 'NAO':
        set_isRecomendationActiveNAO(!isRecomendationActiveNAO);
        set_isRecomendationActiveSIM(false);
      break;
      default:
    }
  }, [isRecomendationActiveSIM, isRecomendationActiveNAO]);

  const starSelect = useCallback((id) => {
    switch (id) {
      case 0:
        set_starSelected_1(false);
        set_starSelected_2(false);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 1:
        set_starSelected_1(true);
        set_starSelected_2(false);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 2:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 3:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 4:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(false);
      break;
      case 5:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(true);
      break;
      default:
    }
  }, []);

  const starOver = useCallback((start) => {
    starSelect(start);
    set_nota_review(start);
  }, [starSelect]);

  const delReview = useCallback(() => {
    set_isRecomendationActiveSIM(false);
    set_isRecomendationActiveNAO(false);
    starOver(0);
    deleteReview(props, store_review_user[0]?.id);
    props.dispatch({ type: '@DELETE_REVIEW_FLAG_TRUE' });
  }, [props, starOver, store_review_user]);

  const sendReview = useCallback(() => {
    let recomendacao;
    if (isRecomendationActiveSIM && !isRecomendationActiveNAO) { recomendacao = true; }
    if (!isRecomendationActiveSIM && isRecomendationActiveNAO) { recomendacao = false; }

    let id_manga;
    id_manga = parseInt(match.params.id_manga, 10);
    
    switch (isEditable) {
      /// NEW REVIEW
      case false:        
        const formNew = {
          body_review,
          recomendacao,
          id_manga,
          nota_review,
        }

        newReview(props, formNew);
      break;
      /// EDIT REVIEW
      case true:
        const id_review = store_review_user[0]?.id;
        const formEdit = {
          body_review,
          recomendacao,
          id_review,
          id_manga,
          nota_review,
        }
        editReview(props, formEdit);
      break;
      default:
    }
  },[props, nota_review, isRecomendationActiveSIM, isRecomendationActiveNAO, body_review, match, isEditable, store_review_user])

  const Star = useCallback(({ id, selected }) => (
    <FaStar key={`nota-id-${id}`}
              size={star_size} 
              className={`cursor ${selected ? `text-orange` : `text-muted`}`}              
              onClick={() => starOver(id)}
    />
  ), [starOver]);

  const Button = useCallback(({ children, onClick, mb, color }) => (
    <R.Button style={{ height: 38, width: 108 }}
          className={`btn btn-${color} mr-1 mb-${mb} text-center`}
          onClick={onClick}
    >
      { children }
    </R.Button>
  ), []);

  useEffect(() => {
    if (!store_review_user[0]?.msg && isEditable) {
      const { nota, recomendacao } = store_review_user[0];
      starSelect(nota);
      set_nota_review(nota);
      recomendacao
        ? toggleRecomendation('SIM')
        : toggleRecomendation('NAO')
    }
  // eslint-disable-next-line
  }, [store_review_user]);

  return(
    <>    
      <R.CardFooter>
        <R.Row className="pb-2">
          
          {/*** RECOMENDACOES ***/}
          <R.Col xs={12} sm={5} md={4} className="review-coluna-recomendacao">
            <div className="review-container-recomendacao">
              <p className={`${review_error_recomendacao ? `review-error ` : ``}`}>Essa recomenda esse Mangá?</p>
              <Button 
                color={isRecomendationActiveSIM ? "info" : "muted"}
                onClick={() => toggleRecomendation('SIM')}
              >
                <AiFillLike size={22} className="text-white mr-2" />
                SIM
              </Button>
              <Button 
                color={isRecomendationActiveNAO ? "danger" : "muted"}
                onClick={() => toggleRecomendation('NAO')}
              >
                <AiFillDislike size={22} className="text-white mr-2" />
                NÃO
              </Button>
            </div>
          </R.Col>
          
          {/*** NOTAS ***/}
          <R.Col xs={12} sm={4} md={5} className="review-column-nota text-center">
            <p className={`${review_error_nota ? `review-error ` : ``}`}>Qual a nota desse Mangá?</p>
            
            <div onMouseLeave={()=> starOver(nota_review)}>
              <Star id={1} selected={starSelected_1} />
              <Star id={2} selected={starSelected_2} />
              <Star id={3} selected={starSelected_3} />
              <Star id={4} selected={starSelected_4} />
              <Star id={5} selected={starSelected_5} />
            </div>
          </R.Col>

          {/*** PUBLICAR ***/}
          <R.Col xs={12} sm={3} md={3} className="review-column-publicar">

            {
              isEditable
              ? <p className="text-info cursor mr-2" onClick={() => delReview(props, store_review_user[0]?.id)}>
                  Deletar review
                </p>
              : null
            }

            <Button color="success" onClick={() => sendReview()}>
              PUBLICAR
            </Button>
          </R.Col>
        
        </R.Row>
      </R.CardFooter>   
    </>
  )
}

export default memo(Footer);
