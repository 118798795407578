import React, { memo } from 'react';
import { RiCheckLine } from "react-icons/ri";

const itemFiltro = (props) => {
  const { nome, checked, alt, onClick } = props;

  let visible, visible_icon;
  !!checked ? visible = 'public-catalogo-selected' : visible = '';
  !!checked ? visible_icon = '' : visible_icon = 'hide';

  return(
    <p onClick={onClick}
        className={`public-catalogo-content p-0 m-0 text-muted noselect ${visible}`}
        title={alt}>
      <small>{ nome.toUpperCase() }</small>
      <RiCheckLine 
        size={22}     
        className={`float-right text-success mr-4 ${visible_icon}`}  
        />
    </p>
  )
}

export default memo(itemFiltro);