import React, { memo } from 'react';
import { Col } from "reactstrap";
import FooterText from './footerText';
import FooterTitle from './footerTitle';

const Stats = ({ stats }) => (
  <>
    <Col sm={4} lg={4} xl={2}  className="col-12 footer-box p-4 text-lg-right">
      <FooterTitle>Stats</FooterTitle>
      <FooterText>
        <span className="text-white lead">{ stats.total_mangas }</span> Mangás.
      </FooterText>
      <FooterText>
        <span className="text-white lead">{ stats.total_volumes }</span> Volumes.
      </FooterText>
      <FooterText>
        <span className="text-white lead">{ stats.total_usuarios }</span> Usuários.
      </FooterText>
      <FooterText>
        <span className="text-white lead">{ stats.total_reviews }</span> Reviews.
      </FooterText>
      <FooterText>
        <span className="text-white lead">{ stats.total_comentarios }</span> Comentários.
      </FooterText>
      <FooterText>
        <span className="text-white lead">{ stats.total_artistas }</span> Artistas.
      </FooterText>
      <FooterText>
        <span className="text-white lead">{ stats.total_editoras }</span> Editoras.
      </FooterText>
    </Col>
  </>
)

export default memo(Stats);
