import React, { memo } from 'react';
import Colecao from './colecao';
import Publicacao from './publicacao';
import Titulo from './titulo';
import SubTitulo from './subtitulo';
import Original from './original';
import LinkAmazon from './linkAmazon';
import TotalColecao from './totalColecao';
import Paginas from './paginas';
import './style.css';

const Info = (props) => (
  <>
    <figcaption className="mt-2 text-center" >
      <strong className="text-muted">

        <Colecao {...props} />
        <LinkAmazon {...props} />

        {
          props.match !== undefined && props.match.path === '/colecao/:share_id'
              ? <TotalColecao {...props} />
              : null
        }
        {
          props.match !== undefined && props.match.path !== '/info/:id_manga/:titulo'
            ? <>
                <Titulo {...props} />
                <SubTitulo {...props} />
                <Original {...props} />
              </>
            : null
        }
        
        <Publicacao {...props} />
        <Paginas {...props} />

      </strong>
    </figcaption>
  </>
)

export default memo(Info);
