import api from '@/services/api';
import { TDispatch } from '@/types';

type TLoadRank = {
   dispatch: (p: TDispatch) => void
}

const loadRank = async ({ dispatch }: TLoadRank): Promise<void> => {
   const response = await api.get(`/rank-mangas`);
   const rank = response.data;  
   dispatch({ type: '@SET_RANK_MANGAS', response: rank })
}

export default loadRank;