import api from '@/services/api';
import { ShowMsg } from '@/components';
import { loadReview, loadReviewUser } from '@/functions';

const deleteReview = async (props, id) => {
  const { store_user, dispatch, match } = props;
  const auth = store_user.token;

  const form = {
    id,
    id_user_edt: store_user.id,
  }

  await api.post('/del-review/', form, auth);
  await loadReview(props, match.params.id_manga);
  await loadReviewUser(dispatch, store_user, match.params.id_manga);
  ShowMsg(
    'Sucesso!',
    'Review deletado com sucesso.',
    'info',
  );   
}

export default deleteReview;
