import { Props } from '@/types';

const navigateTo = (props: Props, url: string, sidebar_id: number) => {
  const { history, dispatch } = props;

  if (!!dispatch) {
    dispatch({ type: "@PUBLIC_LOADING_TRUE"});
    dispatch({ type: "@PUBLIC_SIDEBAR_ACTIVE", response: sidebar_id});
    history?.push(url);
    //setTimeout(() => dispatch({ type: "@PUBLIC_LOADING_FALSE"}), 750);
  }
};

export default navigateTo;
