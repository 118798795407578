import React, { memo } from 'react';

const Header = ({ titulo }) => (
  <>
    <p className="h5 text-light mt-2">
      <b>{ titulo.toUpperCase() }</b>
    </p> 
    <hr className="bg-muted mt-0 mb-2" style={{ width: '98%', marginRight: '2%' }} />
  </>
)

export default memo(Header);
