import React, { memo, useState } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { catalogoViewContainer } from '@/functions';

const titleFiltro = ({ text, id, up, down, onClickCapture }) => {
  
  const [isVisible, setIsVisible] = useState(up === 'hide');
  
  return (
    <p  
      onClick={() => catalogoViewContainer(id, isVisible)}
      onClickCapture={() => {
        setIsVisible(!isVisible);
        onClickCapture();
      }}
      className="public-catalogo-title p-0 m-0 noselect"
    >
      <small>{ text.toUpperCase() }</small>
      
      <RiArrowUpSLine id={`${id}-up`}
        size={20} className={`float-right mr-4 mt-1 ${up}`} />
      
      <RiArrowDownSLine id={`${id}-down`}
        size={20} className={`float-right mr-4 mt-1 ${down}`} />
    </p>
  )
};

export default memo(titleFiltro);
