import React, { memo } from 'react';
// import { GoogleLogin } from 'react-google-login';
// import { ShowMsg } from 'components';
// import { login } from 'functions';

const UserLogin = (props) => (
  <>
    Para criar sua conta é fácil, basta
    <span className="text-orange cursor" onClick={() => props.dispatch({ type: '@SET_MODAL', response: '@MODAL_LOGIN' })}> clicar aqui </span>
    e deixar o resto conosco.
    
    <p className="h6 text-muted pt-3">
      E o melhor de tudo! O acesso é totalmente <span className="text-orange">gratuito</span>.
    </p>
  </>
)

export default memo(UserLogin);
