import React, { memo } from 'react';

const RenderLI = ({ icon, text, onClick }) => (
  <li className="flex-container flex-justify-left" onClick={onClick}>
    <div style={{ width: 36 }}>
      { icon }
    </div>
    { text }
  </li>  
);

export default memo(RenderLI);
