import React, { memo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";

const Table = ({ tableData, tableColumns }) => (
  <div className="pl-3 pr-3 pb-3 pt-0">
    <BootstrapTable
      keyField="id"
      data={tableData}
      columns={tableColumns}      
      striped
      bootstrap4        
      bordered={false}              
      pagination={paginationFactory({
        sizePerPage: 6,
        sizePerPageList: [6, 25, 50, 100, 150, 200]
      })}      
    />
  </div>
);

export default memo(Table);
