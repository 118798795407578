import React, { memo, useEffect, useCallback, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import { FaEdit, FaTrash  } from 'react-icons/fa';
import * as R from 'reactstrap';
import * as C from '@/components';
import * as F from '@/functions';
import './style.css';

const CardComentario = (props) => {
  const { comentario, resposta, openEditar, store_user } = props;

  const [starSelected_1, set_starSelected_1] = useState(false);
  const [starSelected_2, set_starSelected_2] = useState(false);
  const [starSelected_3, set_starSelected_3] = useState(false);
  const [starSelected_4, set_starSelected_4] = useState(false);
  const [starSelected_5, set_starSelected_5] = useState(false);

  const Star = useCallback(({ id, selected }) => (
    <FaStar key={`nota-id-${id}`}
            size={20}
            className={selected ? "text-orange" : "text-muted"  }
    />
  ), []);

  const starSelect = useCallback((id) => {
    switch (id) {
      case 1:
        set_starSelected_1(true);
        set_starSelected_2(false);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 2:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 3:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 4:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(false);
      break;
      case 5:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(true);
      break;
      default:
    }
  },[]);

  const deleteCard = useCallback(() => { 
    const { comentario } = props;
    const RCI = F.checkRCI(comentario);
    
    switch (RCI) {
      case 'REVIEW': 
        F.deleteReview(props, comentario.id);
      break;
      case 'COMENTARIO':  
        F.deleteResposta(props, comentario.id);
      break;
      case 'INTERACAO':  
        F.deleteInteracao(props, comentario.id);
      break;
      default:
    }

  }, [props]);

  useEffect(() => {
    starSelect(comentario.nota);
  },[comentario, starSelect]);

  return (
    <>
      <R.CardHeader className="p-0 border-none flex-container flex-justify-space-between">
        <div className="flex-container flex-justify-space-between">
          
          {
            !resposta
            ? !comentario.delete_flag 
                ? <div style={{ width: 45, height: 90 }} 
                       className={`card-comentario-header-recomendacao ${comentario.recomendacao ? `bg-info mr-2` : `bg-danger mr-2`}`} >
                    {
                      comentario.recomendacao 
                        ? <AiFillLike size={28} className='text-white' />
                        : <AiFillDislike size={28} className='text-white' />
                    }
                  </div>
                : <div style={{ width: 45, height: 90 }} 
                       className="card-comentario-header-recomendacao bg-muted mr-2" />      
             : <div style={{ width: 18, height: 90 }} className="" />
          }
          
          <div className='pl-2'>
            <C.AvatarContainer src={comentario.user_add_imgur_link} />
          </div>
          
          <div className="text-left pl-3">
            <p className="m-0 lead text-orange">{`${comentario.user_add_nome} ${comentario.user_add_sobrenome}`}</p>
            { !resposta ? <p className="m-0 text-info">{comentario.user_total_reviews} reviews</p> : null }
            <p className={`m-0 ${resposta ? `mt-2` : null}`}>{comentario.user_total_comentarios} comentários</p>
          </div>

        </div>

        <div className='flex-container flex-justify-center pr-1'>
          {
            !resposta ?
              <div className='pr-3'>
                <p className="m-0 text-right">Nota</p>
                <div>              
                  <Star id={1} selected={starSelected_1} />
                  <Star id={2} selected={starSelected_2} />
                  <Star id={3} selected={starSelected_3} />
                  <Star id={4} selected={starSelected_4} />
                  <Star id={5} selected={starSelected_5} />
                </div>
              </div>
            : <div style={{ height: 50 }} className="pl-4 pr-3 flex-container flex-direction-column flex-justify-space-between">  
                {
                  store_user.id === comentario.id_user_add
                  ? <>
                      <FaTrash size={14} className="cursor text-danger" title="Deletar" onClick={() => deleteCard()} />
                      <FaEdit size={16} className="cursor text-info" title="Editar" onClick={() => openEditar()}  />
                    </>
                  : null
                }
              </div>
          }

          {
            comentario?.imgur_link ? 
              <img 
                width={62}
                height={90}
                src={comentario?.imgur_link} 
                title={`mangá ${ comentario?.titulo } ${ comentario?.subtitulo ? comentario?.subtitulo : `` }`} 
                alt={`mangá ${ comentario?.titulo } ${ comentario?.subtitulo ? comentario?.subtitulo : `` }`} 
              />
            : null
          }
        </div>
      </R.CardHeader>
    </>
  )

}

export default memo(CardComentario);
