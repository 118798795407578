import api from '@/services/api';
import { ShowMsg } from '@/components';
import { loadRespostas, loadReview } from '@/functions';

const deleteResposta = async (props, id) => {
  const { store_user, comentario, match } = props;
  const auth = store_user.token;
  const id_manga = match.params.id_manga;

  const form = {
    id,
    id_user_edt: store_user.id,
  }
  
  await api.post('/del-resposta/', form, auth);
  await loadRespostas(props, comentario.id_review);
  await loadReview(props, id_manga);
  ShowMsg('Sucesso!', 'Resposta deletado com sucesso.', 'info', 2500); 
}

export default deleteResposta;
