const mapState = state => ({
  isLogin: state.auth.isLogin,
  id_sessao: state.auth.id_sessao,
  store_user: state.auth.store_user,
  windowSize: state.sistema.windowSize,

  
});

export default mapState;
