import React, { memo } from 'react';
import { connect } from 'react-redux';
import { CardCriarConta } from '@/components';
import FormSettings from './form';
import mapState from './mapstate';
import './styles.css';

const Settings = (props) => (
  <>
    {
    props.isLogin
      ? <FormSettings {...props} />
      : <CardCriarConta {...props} />
    }
  </>
)

export default memo(connect(() => (mapState))(Settings));
