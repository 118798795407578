import React, { memo } from 'react';
import { Col } from "reactstrap";
import moment from 'moment';
import 'moment/locale/pt-br';
import FooterText from './footerText';
import FooterTitle from './footerTitle';

const Links = ({ history }) => {

  const ano_atual = moment().format("YYYY");
  const mes_atual = moment().format("MM");

  return(
    <>
      <Col sm={5} lg={4} xl={2}  className="col-12 footer-box p-4">
        <FooterTitle>Links</FooterTitle>

        <FooterText onClick={() => history.push('/')}>Home</FooterText>
        <FooterText onClick={() => history.push(`/checklist/${mes_atual}/${ano_atual}`)}>Checklist</FooterText>
        <FooterText onClick={() => history.push('/colecao')}>Coleção</FooterText>
        <FooterText onClick={() => history.push('/catalogo')}>Catálogo</FooterText>      
        <FooterText onClick={() => history.push('/ofertas-amazon')}>Ofertas Amazon</FooterText>  
        <FooterText onClick={() => history.push('/reviews')}>Reviews</FooterText>       
      </Col>
    </>
  )
}

export default memo(Links);
