import React, { memo } from 'react';
import { CardHeader } from 'reactstrap';
import { MdClose } from 'react-icons/md';

const Header = ({ titulo, isEditable, handleEditar }) => (
  <>
    <CardHeader className="border-none">
      <p className="h5 text-info mb-3">Escreva uma análise de <span className="h4 text-orange">{ titulo }</span></p>
      <p className="h6 mb-3">Descreva o que gostou e o que não gostou desse Mangá e se você o recomenda a outros</p>
      <p className="h6">Não se esqueça de ser bem-educado e seguir as <u className="text-info">regras e diretrizes</u></p>
      {
        isEditable
          ? <MdClose size={40} className="review-button-close text-muted" onClick={handleEditar} />
          : null
      }
    </CardHeader>
  </>
)

export default memo(Header);
