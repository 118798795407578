import React, { memo, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as I from "react-icons/fa";
import * as F from '@/functions';
import { MouseHover, TagReimpressao } from "@/components";
import Info from './info';
import mapState from './mapstate';
import './style.css';

const CapaContainer = (props) => {
  ////// REDUX
  const { 
    match,
    dispatch, 
    colecao_action,
    colecao_btnAll_action,
    Hover_ID,
    store_user,
    nota,
  } = props;

  ////// PROPS
  const { 
    key,
    dados,
    colecao,
    mouseHover,
    modal,
    noClick
  } = props;

  const { 
    titulo,
    subtitulo,
    // original,
    // numero,
    // publicacao,
    // paginas, 
    // preco,
    editora, 
    status,
    id_manga, 
    id_volume,
    id_editora,
    // comprar,
    // amazon_link,
    capa,
    reimpressao_flag,
  } = dados;
  
  const {
    active: isColecao,
    group: isColecaoGroup,
    comprado,
    total, 
    id_drop,
  } = colecao;

  const { 
    active: isMouseHover,
    // totalLinha,
    // cardPosition,
    // menuBasic,
  } = mouseHover;

  const { 
    // active: isModal,
    capaModal,
  } = modal;

  const { 
    active: isNoClick,
  } = noClick;
 
  const path = match?.path.split('/');
  const [height, set_height] = useState(380);
  const [isLoad, set_isLoad] = useState(false);
  const history = useHistory();

  ////// VISIBILIDADE
  const [card_visibility, set_card_visibility] = useState(false);

  /// CONTROLE
  const [flag_colecao, ] = useState(isColecao);
  const [flag_comprado, set_flag_comprado] = useState(false);
  const [flag_lido, set_flag_lido] = useState(false);
  const [flag_compras, set_flag_compras] = useState(false);
  const [flag_favorito, set_flag_favorito] = useState(false);
  
  const url_info = `/info/${id_manga}/${F.formatUrl(titulo, subtitulo)}`;

  const renderCard = useCallback((visibility) => {
    if (isMouseHover) {
      set_card_visibility(visibility)

      visibility
        ? dispatch({ type: '@SET_PUBLIC_CARDHOVER_ID', payload: id_manga })
        : dispatch({ type: '@SET_PUBLIC_CARDHOVER_ID', payload: 0 });
    }
  }, [dispatch, id_manga, isMouseHover]);

  const addManga = useCallback((props) => {
    const { store_user } = props;

    const form = {
      id_user: store_user.id,
      id_manga,
      id_volume,
      id_editora,
      id_user_add: store_user.id,
      id_user_edt: store_user.id
    }

    store_user.id !== 9999 
      ? F.addColecao(props, form)
      : dispatch({ type: '@SET_MODAL', response: '@USER_LOGIN' });
  },[id_manga, id_volume, id_editora, dispatch]);

  const delManga = useCallback((props) => {
    const { store_user } = props;
    
    store_user.id > 0
      ? F.deleteColecao(props, id_volume)
      : dispatch({ type: '@SET_MODAL', response: '@USER_LOGIN' });
  },[id_volume, dispatch]);

  const handleLido = useCallback(() => {
    set_flag_lido(!flag_lido);
  },[flag_lido]);

  const handleCompras = useCallback(() => {
    set_flag_compras(!flag_compras);
  },[flag_compras]);

  const handleFavorito = useCallback(() => {
    set_flag_favorito(!flag_favorito);
  },[flag_favorito]);

  const handleClick = useCallback(() => {
    /// MODAL COLECAO GROUP
    if (isColecaoGroup) {
      const row = {
        id: id_volume,
        id_manga,
        manga: titulo,
        subtitulo,
        editora, 
        status,
        imgur_link: capa,
        total: total[1],
        colecao: total[0],
        id_drop,
        ...nota,
      }      
      F.processModalColecaoClick(props, row);
    }

    /// CLICK DEMAIS PÁGINAS
    if (!isMouseHover && !isNoClick && !isColecaoGroup) {
      if (!capaModal) {
        F.modalVolumes(props, id_manga);
      } else {
        dispatch({ type:'@SET_MODAL', response: '@RESET_MODAL' });
        F.navigateTo({...props, history}, url_info, 8);
      }
    }
  }, [isMouseHover, 
      isNoClick, 
      nota, 
      capaModal, 
      dispatch, 
      id_manga, 
      isColecaoGroup, 
      url_info,
      capa,
      editora,
      id_volume,
      id_drop,
      titulo,
      subtitulo,
      status,
      total,
      history,
      props]);
  
  //// ALTURA POR TELA OU MODAL
  useEffect(() => {
    if ( path !== undefined ) {
      switch (path[1]) {
        case 'info': set_height(425); break;
        case 'checklist': set_height(450); break;
        default:
      }
    } 
  }, [path]);

  //// LOADING CAPA
  useEffect(() => {
    var img = new Image();
    img.src = capa;
    img.onload = function () {
      setTimeout(() => set_isLoad(true), 25)
    }
  }, [capa]);

  //// HOVER ID CLICK
  useEffect(() => {
    if (Hover_ID !== id_manga) {
      set_card_visibility(false)      
    } 
  }, [Hover_ID, id_manga]);

  //// ADD/REMOVE ALL
  useEffect(() => {
    let response = { 
      add: false, remove: false, id_manga: null, quantidade: 0, colecao_edicao: false 
    };
    const totalModal = total?.length || 0; 
    const {add, remove, id_manga: action_id_manga, quantidade } = colecao_btnAll_action;
    
    if (add && action_id_manga === id_manga) {
      set_flag_comprado(true);
      let quantidadeAdd = quantidade+1;
      if (quantidadeAdd < totalModal) {
        response = { quantidade: quantidadeAdd }
      }
      dispatch({ type: '@PUBLIC_COLECAO_BTN_ALL_ACTION', response });
    }

    if (remove && action_id_manga === id_manga) {  
      set_flag_comprado(false);
      let quantidadeRemove = (quantidade === 0 ? totalModal : quantidade) - 1;
      if (quantidadeRemove > 0) {
        response = { quantidade: quantidadeRemove }
      }
      dispatch({ type: '@PUBLIC_COLECAO_BTN_ALL_ACTION', response });
    }

    /** PS: validação da quantidade
     * quando estamos atualizando a coleção e a visualização é por capa
     * tem MUITA capa na tela, e as vezes não dava tempo de atualizar a capa certa 
     * com o add / remove antes do dispactch executar com o false do add / remove
     * então eu fiz um calculo que ele verifique se eu já passei por todas as capas 
     * necessárias antes de mandar o dispatch correto 
     * (Isso é mais pra quando for modal de edição, mas vai funcionar normal quando
     * não for)*/
  },[dispatch, colecao_btnAll_action, total, id_manga]);

  //// VOLUME ADD E REMOVE
  useEffect(() => {
    const response = { add: false, remove: false, id_volume: null };
    const {add, remove, id_volume: action_id_volume } = colecao_action;
    if (remove && action_id_volume === id_volume) {
      set_flag_comprado(false);
      dispatch({ type: '@PUBLIC_COLECAO_ACTION', response });
    }

    if (add && action_id_volume === id_volume) {
      set_flag_comprado(true);
      dispatch({ type: '@PUBLIC_COLECAO_ACTION', response });
    }
  }, [dispatch, id_volume, colecao_action]);

  //// FLAG COMPRADO
  useEffect(() => {
    set_flag_comprado(comprado)
  }, [comprado]);

  return (
    <> 
      <figure 
        style={{ height }} 
        key={key}
        className={`p-3 m-0 public-capa-container text-center ${capaModal ? `public-capa-container-modal` : ``}`} 
      >

        <div className={`text-white public-capa shadow ${capaModal ? `public-capa-modal` : ``}`}>
          
          {
            isLoad 
              ? <div className="public-cover">
                  {
                    store_user.config_reimpressao 
                      ? <TagReimpressao flag={ reimpressao_flag } />
                      : null
                  }
                  <img src={capa} loading="lazy" alt="capa" 
                     onMouseEnter={() => renderCard(true)}
                     //onClick={!isMouseHover ? !isNoClick ? (!capaModal ? link_modal : link_info) : null : null}
                     onClick={() => handleClick()}
                     className={`cursor noselect ${capaModal ? `public-capa-modal` : ``}`}
                  />
                </div>
              : <I.FaSpinner size={22} className="icon-spin" />
          }
          {
            isMouseHover ?
              <MouseHover 
                {...props}
                flags={{              
                  flag_colecao,
                  flag_comprado,
                  flag_lido,
                  flag_compras,
                  flag_favorito,
                }}        
                visiblility={card_visibility}
                addManga={() => addManga(props)}
                delManga={() => delManga(props)}
                onMouseLeave={() => renderCard(false)}
                handleLido={() => handleLido()}
                handleCompras={() => handleCompras()}
                handleFavorito={() => handleFavorito()}
              /> : null
          }
        </div>

        <Info 
          {...props} 
          comprado={flag_comprado}
          card_visibility={card_visibility} 
          addManga={() => addManga(props)}
          delManga={() => delManga(props)}
        />
      </figure>
    </>
  )
}

export default memo(connect(() => (mapState))(CapaContainer));
