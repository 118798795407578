import React, { memo, useCallback } from "react";
import { Col } from "reactstrap";
import * as I from "react-icons/io";
import * as FA from "react-icons/fa";
import FooterText from "./footerText";
import FooterTitle from "./footerTitle";

const Contato = () => {
  const RenderText = useCallback(
    ({ href, children }) => (
      <FooterText>
        <a
          href={href}
          rel="noopener noreferrer"
          target="_blank"
          className="text-link"
        >
          {children}
        </a>
      </FooterText>
    ),
    []
  );

  return (
    <>
      <Col
        sm={7}
        lg={4}
        xl={3}
        className="col-12 footer-box p-4 text-sm-right text-lg-left"
      >
        <FooterTitle>Contato</FooterTitle>

        <RenderText href="https://www.linkedin.com/in/danilo-rocha-ab57a984">
          <I.IoLogoLinkedin size={17} className="mr-2" />
          Danilo Rocha
        </RenderText>

        <RenderText href="https://twitter.com/dnocamargo">
          <I.IoLogoTwitter size={17} className="mr-2" />
          @dnocamargo
        </RenderText>

        <RenderText href="mailto:contato@meusmangas.com.br">
          <I.IoMdMail size={17} className="mr-2" />
          contato@meusmangas.com.br
        </RenderText>

        <FooterTitle>Desenvolvimento</FooterTitle>

        <p className="pt-1">
          Bugs, Críticas, Sugestões ou Pedido de cadastro de Mangás?
        </p>

        <RenderText href="https://admin.meusmangas.com.br">
          <FA.FaTrello size={17} className="mr-2" />
          Acesse: Painel Administrativo
        </RenderText>
      </Col>
    </>
  );
};

export default memo(Contato);
