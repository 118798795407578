// import { getLogSessao } from 'functions';

const logout = (props) => {
  const CACHE = import.meta.env.VITE_APP_USER_CACHE;
  localStorage.removeItem(CACHE);

  // getLogSessao(props, 9999);

  props.dispatch({ type: '@PUBLIC_LOADING_TRUE' });
  props.dispatch({ type: '@RESET_USER' });  
  
  props.history.push('/');
  setTimeout(() => props.dispatch({ type: '@PUBLIC_LOADING_FALSE' }), 500);  
}

export default logout;