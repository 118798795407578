import React from 'react';
import empty from '@/assets/capas/empty.jpg';
import { processMangasLimit, processCardPosition } from '@/functions';
import { CapaContainer } from '@/components';

const RenderContainer = ({ props, counter, limit, customKey, titulo, id_manga, id_volume, capa }) => (
  <CapaContainer  
    {...props} 
    key={`${customKey}-${id_volume}`}
    dados={{
      titulo,
      subtitulo: '',
      original: '',
      numero: false,
      publicacao: false,
      paginas: false, 
      preco: false,
      editora: false, 
      status: false,
      id_manga, 
      id_volume,
      id_editora: 0,
      comprar: false,
      amazon_link: '',
      capa,
    }}
    colecao={{
      active: false,
      total: false,
    }}
    mouseHover={{
      active: true,
      totalLinha: limit,
      cardPosition: processCardPosition(counter, limit),
      menuBasic: true,
    }}
    modal={{
      active: false,                      
      capaModal: false,
    }}
    noClick={{ 
      active: false 
    }}  
  />
)

const processRecomendacoesArray = (props) => {
  const { windowSize, store_recomendacoes, match } = props;
  if (store_recomendacoes.length === 0) { return }

  const { 
    recomendacoes_autor,
    recomendacoes_genero,
    recomendacoes_gerais,
    recomendacoes_tags,
  } = store_recomendacoes;

  let counter = 0;
  const _temp = [];
  const limit = processMangasLimit(windowSize);

  ////// AUTOR
  if ( recomendacoes_autor !== undefined ) {
    recomendacoes_autor.forEach(async item => {
      const { id: id_volume, imgur_link, Manga } = item;
      const { id: id_manga, nome: titulo  } = Manga;
  
      let capa;
      !!imgur_link ? capa = imgur_link : capa = empty;
  
      if (counter < limit || limit === 0) {
        if ( id_manga !== parseInt(match.params.id_manga, 10)) {
          _temp.push(            
            <RenderContainer 
              key={`render-container-${id_manga}`}
              customKey={`recomendacoes-autor-${id_manga}`} props={props} counter={counter} limit={limit} titulo={titulo} id_manga={id_manga} id_volume={id_volume} capa={capa} />          
          );   
        }    
      }
      counter++;
    });
  }
  
  ////// TAGS 
  if ( recomendacoes_tags !== undefined ) {
    recomendacoes_tags.forEach(async item => {
      const { id: id_volume, imgur_link, Manga } = item;
      const { id: id_manga, nome: titulo  } = Manga;
  
      let capa;
      !!imgur_link ? capa = imgur_link : capa = empty;
  
      if (counter < limit || limit === 0) {
        if ( id_manga !== parseInt(match.params.id_manga, 10)) {
          _temp.push(
            <RenderContainer 
            key={`render-container-${id_manga}`}
            customKey={`recomendacoes-tags-${id_manga}`} props={props} counter={counter} limit={limit} titulo={titulo} id_manga={id_manga} id_volume={id_volume} capa={capa} />
          );   
        }    
      }
      counter++;
    });
  }

  ////// GENERO 
  if ( recomendacoes_genero !== undefined ) {
    recomendacoes_genero.forEach(async item => {
      const { id: id_volume, imgur_link, Manga } = item;
      const { id: id_manga, nome: titulo  } = Manga;
  
      let capa;
      !!imgur_link ? capa = imgur_link : capa = empty;
  
      if (counter < limit || limit === 0) {
        if ( id_manga !== parseInt(match.params.id_manga, 10)) {
          _temp.push(
            <RenderContainer 
            key={`render-container-${id_manga}`}
            customKey={`recomendacoes-genero-${id_manga}`} props={props} counter={counter} limit={limit} titulo={titulo} id_manga={id_manga} id_volume={id_volume} capa={capa} />
          );   
        }    
      }
      counter++;
    });
  }

  ////// GERAIS 
  if ( recomendacoes_gerais !== undefined ) {
    recomendacoes_gerais.forEach(async item => {
      const { id: id_volume, imgur_link, Manga } = item;
      const { id: id_manga, nome: titulo  } = Manga;
  
      let capa;
      !!imgur_link ? capa = imgur_link : capa = empty;
  
      if (counter < limit || limit === 0) {
        if ( id_manga !== parseInt(match.params.id_manga, 10)) {
          _temp.push(
            <RenderContainer 
            key={`render-container-${id_manga}`}
            customKey={`recomendacoes-geral-${id_manga}`} props={props} counter={counter} limit={limit} titulo={titulo} id_manga={id_manga} id_volume={id_volume} capa={capa} />
          );   
        }    
      }
      counter++;
    });
  }
  
  return _temp;
};

export default processRecomendacoesArray;
