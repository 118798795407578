const mapState = state => ({
  store_user: state.auth.store_user,

  store_volumes_modal: state.manga.store_volumes_modal,
  store_volumes_modal_nome: state.manga.store_volumes_modal_nome,
  store_volumes_modal_elements: state.manga.store_volumes_modal_elements,

  /** Esses 2 actions ainda não são usádos no index.js do modal.
   *  - Só que sem os actions o botão add / remove all não vai funcionar.
   *  - Favor não remover, sem avaliar o botão add / remove all de todos os lugares
   *  ou sem avaliar o add / remove de capa-container */
  colecao_action: state.sistema.colecao_action,
  colecao_btnAll_action: state.sistema.colecao_btnAll_action,

  windowSize: state.sistema.windowSize,
});

export default mapState;