import React, { memo } from 'react';
import { FaSpinner } from "react-icons/fa";

const Loading = () => (
  <>
    <div className="flex-container flex-justify-center" >
      <FaSpinner size={22} className="icon-spin" /> 
    </div> 
  </>
)

export default memo(Loading);
