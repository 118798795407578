import React from 'react';
import { FaList } from 'react-icons/fa';
import { TGridCapas } from './GridCapas.type';
import './style.css';

const gridCapas: React.FC<TGridCapas> = ({ 
  customKey, 
  capas, 
  listaCompleta, 
  onClick 
}) => (
  <>
    <div 
      style={{ width: '100%' }} 
      id={`grid-capas-${customKey}`}
      className="flex-container flex-direction-row flex-justify-left pl-3 pr-0"
    >{ capas }</div>
    {
      listaCompleta ? 
        <div style={{ width: '100%' }} className="mt-5 text-right text-white">
          <span className="cursor tag-lista-completa" onClick={onClick}>
            <FaList size={16} className="mr-3 tag-lista-icon" />
            LISTA COMPLETA
          </span>
        </div>
      : null
    }
  </>
)

export default gridCapas;
