import { ShowMsg } from '@/components';
import { processLinkYoutube } from '@/functions';

const processReview = async (props, data) => {
  const { dispatch } = props;
  const { body_review, recomendacao, nota_review } = data;
  const { reviewText, reviewYtb, reviewBlog } = body_review;

  dispatch({ type: '@SET_REVIEW_ERROR_BODY', payload: false })
  dispatch({ type: '@SET_REVIEW_ERROR_RECOMENDACAO', payload: false })
  dispatch({ type: '@SET_REVIEW_ERROR_NOTA', payload: false })

  let link_youtube = '';
  if (reviewYtb !== undefined) { link_youtube = processLinkYoutube(reviewYtb) }

  let link_blog = '';
  if (reviewBlog !== undefined) { link_blog = reviewBlog } 

  if (
      reviewText === undefined || 
      reviewText === '' || 
      recomendacao === undefined ||
      nota_review === 0
    ) {
   
    if (reviewText === undefined) { dispatch({ type: '@SET_REVIEW_ERROR_BODY', payload: true }) }
    if (recomendacao === undefined) { dispatch({ type: '@SET_REVIEW_ERROR_RECOMENDACAO', payload: true }) }
    if (nota_review === 0) { dispatch({ type: '@SET_REVIEW_ERROR_NOTA', payload: true }) }
    
    ShowMsg(
      'Erro!',
      'Favor preencher os campos obrigatórios.',
      'error',
    );
    
    return { error: true }
  }

  return {
    error: false,
    texto: reviewText,
    link_youtube,
    link_blog,
  }
}

export default processReview;
