import React, { memo } from 'react';
import { Col } from "reactstrap";
import FooterText from './footerText';
import Logo from '@/assets/img/logo_chapado.png';

const Desc = () => (
  <>
    <Col sm={8} lg={12} xl={5} className="col-12 footer-box p-4 text-sm-right text-lg-center text-xl-right">
      <img src={Logo} alt="Logo Meus Mangás" className="mb-2" />
      <FooterText>O portal de mangás publicados no Brasil.</FooterText>
      <FooterText top="3">
        Gerencie sua coleção de Mangás <span className="text-orange">gratuitamente</span>.
      </FooterText>
      <FooterText top="1">
        Receba notificações das últimas novidades das editoras.
      </FooterText>
    </Col>
  </>
)

export default memo(Desc);
