import { AmazonStore, ReducerType } from '@/types';
import * as I from './initials';

export const INITIAL_STATE: AmazonStore = {  
  store_amazon_offers: [],
  store_amazon_editoras: [],
  store_amazon_filtro: I.initial_store_amazon_filtro,
  store_amazon_filtro_page_flag: false,
  store_amazon_filtro_page: 1,
  store_amazon_filtro_form: I.initial_store_filtro_form,
}
 
export default function manga_reducer(state = INITIAL_STATE, action: ReducerType) {
  switch(action.type) {  

    case '@SET_AMAZON_OFFERS': return { ...state, store_amazon_offers: action.response };
    case '@RESET_AMAZON_OFFERS': return { ...state, store_amazon_offers: [] };
    
    case '@SET_AMAZON_EDITORAS': return { ...state, store_amazon_editoras: action.response };
    case '@RESET_AMAZON_EDITORAS': return { ...state, store_amazon_editoras: [] };

    case '@SET_AMAZON_FILTRO': return { ...state, store_amazon_filtro: action.response };
    case '@RESET_AMAZON_FILTRO': return { ...state, store_amazon_filtro: I.initial_store_amazon_filtro };
    
    case '@FLAG_AMAZON_FILTRO_PAGE_TRUE': return { ...state, store_amazon_filtro_page_flag: true };
    case '@FLAG_AMAZON_FILTRO_PAGE_FALSE': return { ...state, store_amazon_filtro_page_flag: false };
    case '@SET_AMAZON_FILTRO_PAGE': return { ...state, store_amazon_filtro_page: action.response };

    case '@SET_AMAZON_FILTRO_FORM': return { ...state, store_amazon_filtro_form: action.response };

    default: return state;
  }
}
