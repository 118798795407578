import React from "react";
import { Form } from "rsuite";
import login from "@/functions/sistema/loginEmail";
import GoogleButton from "@/functions/sistema/login/googleButton";
import { modelStepOne } from "../model";
import * as U from "../utils";

const StepOne = (props) => {
  const { setStep } = props;

  const [isSubmited, setIsSubmited] = React.useState(false);

  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({ email: "", password: "" });

  const handleSubmit = React.useCallback(() => {
    const hasError = Object.keys(formError).length > 0;

    if (isSubmited && !hasError) {
      login(props, formValue);
    }

    setIsSubmited(false);
  }, [props, formValue, formError, isSubmited]);

  React.useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <div className="fadeIn">
      <Form
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        onSubmit={() => setIsSubmited(true)}
        model={modelStepOne}
      >
        <>
          <hr className="mt-0 mb-4" style={{ borderColor: "#6c757d" }} />
          <GoogleButton />
          <U.Divisor />
        </>
        <>
          <U.TextField name="email" label="Email" autoComplete="off" />

          <U.TextField
            name="password"
            label="Senha"
            type="password"
            autoComplete="off"
          />

          <U.ButtonContainer align="space-between">
            <span
              className="h6 mt-1 text-muted cursor"
              onClick={() => {
                setStep(3);
                setFormValue({ email: "", password: "" });
              }}
            >
              ESQUECEU SUA SENHA?
            </span>

            <U.ButtonModal submit label="ENTRAR" disabled={isSubmited} />
          </U.ButtonContainer>
        </>

        <>
          <U.Divisor />

          <U.ButtonContainer align="center">
            <U.ButtonModal
              label="FAÇA O SEU CADASTRO"
              color="success"
              onClick={() => {
                setStep(2);
                setFormValue({ email: "", password: "" });
              }}
            />
          </U.ButtonContainer>
        </>
      </Form>
    </div>
  );
};

export default React.memo(StepOne);
