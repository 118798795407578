const mapState = state => ({
  isLogin: state.auth.isLogin,
  store_user: state.auth.store_user,
  id_sessao: state.auth.id_sessao,
  windowSize: state.sistema.windowSize,
  
  store_catalogo: state.manga.store_catalogo,
  store_catalogo_tags: state.manga.store_catalogo_tags,  
  store_catalogo_filtro: state.manga.store_catalogo_filtro,  

  store_amazon_offers: state.amazon.store_amazon_offers,
  store_amazon_editoras: state.amazon.store_amazon_editoras,  
  store_amazon_filtro: state.amazon.store_amazon_filtro,  
  store_amazon_filtro_page: state.amazon.store_amazon_filtro_page,  
  store_amazon_filtro_page_flag: state.amazon.store_amazon_filtro_page_flag,  
  store_amazon_filtro_form: state.amazon.store_amazon_filtro_form,  
});

export default mapState;
