import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as pages from '../pages';

const Routes = () => (
  <BrowserRouter>
    <Switch>
                
      <Route exact path="/" component={pages.PublicHome} />
                
      <Route exact path="/checklist/:mes/:ano" component={pages.PublicChecklist} />
      <Route exact path="/colecao" component={pages.PublicColecao} />
      <Route exact path="/colecao/:share_id" component={pages.PublicColecao} />
      
      <Route exact path="/catalogo/:id_editora/:editora" component={pages.PublicCatalogo} />
      <Route exact path="/catalogo" component={pages.PublicCatalogo} />
      <Route exact path="/ofertas-amazon" component={pages.PublicAmazon} />

      <Route exact path="/editora" component={pages.PublicEditora} />
      <Route exact path="/info/:id_manga/:titulo/:comentarios" component={pages.PublicInfo} />
      <Route exact path="/info/:id_manga/:titulo" component={pages.PublicInfo} />

      <Route exact path="/reviews" component={pages.PublicReviews} />
      <Route exact path="/perfil" component={pages.PublicPerfil} />

      <Route exact path="/hooks" component={pages.Hooks} />
            
    </Switch>
  </BrowserRouter>
);

export default Routes;
