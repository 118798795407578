import React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from "@/custom/react-redux-toastr";
import store from '@/store';
import Routes from '@/routes';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/custom/react-redux-toastr/lib/css/react-redux-toastr.min.css';
import '@/assets/css/App.css';
import '@/assets/css/colors_class.less';
import '@/assets/css/responsive.css';
import '@/assets/css/App.less';

export default function App() {
  return (
    <Provider store={ store } >
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar        
          closeOnToastrClick
        /> 
        <Routes />  
    </Provider>
  );
}
