import React, { memo } from 'react';
import { navigateTo, formatUrl } from '@/functions';

const SubTitulo = (props) => {
  ////// PROPS
  const { dados, noClick, modal, card_visiblility } = props;
  const { titulo, subtitulo, id_manga } = dados;
  const { capaModal } = modal;

  const { active: isNoClick } = noClick;
  const link = () => navigateTo(props, `/info/${id_manga}/${formatUrl(titulo, subtitulo)}`, 8);

  return(
    <>
      {
        !card_visiblility ? 
          subtitulo && !capaModal
            ? <div className="text-muted h6 cursor mt-1" 
                   onClick={ !isNoClick ? link : null }>
                { subtitulo }
              </div> 
            : null
        : null
      }
    </>
  )
}

export default memo(SubTitulo);
