import React, { memo, useState, useEffect, useCallback } from 'react';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { AppContainer } from '../../components';
import * as R from 'reactstrap';
import * as F from '../../functions';
import mapState from './mapstate';
import './style.css';

const TitleWeb = lazy(() => import('../../components/title'));
const GridCapas = lazy(() => import('../../components/grid-capas'));
const Search = lazy(() => import('./search'));
const Filter = lazy(() => import('./filter'));

const Catalogo = (props) => {
  const {
    dispatch,
    windowSize,
    store_amazon_filtro_form,
    store_amazon_offers,
  } = props;
  
  const [isLoading, set_isLoading] = useState(true);
  const [isError, set_isError] = useState(false);
  const [lista_capas, set_lista_capas] = useState([]);

  const resetLoading = useCallback(() => {
    set_isError(true);
    set_isLoading(false);
    set_lista_capas([]);
  }, [])

  useEffect(() => {
    set_isError(false);
    set_isLoading(true);
    F.loadAmazonOffers(dispatch, store_amazon_filtro_form);
    // eslint-disable-next-line
  },[dispatch, store_amazon_filtro_form])

  useEffect(() => {
    if (isLoading) {
      const timerId = setInterval(() => resetLoading(), 6000);
      return () => clearInterval(timerId);
    }
    // eslint-disable-next-line
  },[isLoading, resetLoading])
 
  useEffect(() => {
    if (store_amazon_offers.length === 0) { return }
    set_lista_capas([]);

    if (store_amazon_offers.Code === "NoResults") { 
      resetLoading();
      return 
    }
    
    set_isError(false);
    set_isLoading(false);
    set_lista_capas(F.processAmazonOffers(props));
    // eslint-disable-next-line
  },[store_amazon_offers, resetLoading]);

  return (
    <>
      <AppContainer {...props}>
        <Suspense fallback="">
          <R.Row className="m-0 flex-container flex-container-top">
          
          {
            windowSize.width >= 576
              ? <div className="public-catalogo-filtro">
                  {/*** FILTRO ***/}
                  <Filter {...props} />
                </div>
              : null
          }

          {/*** CONTENT ***/}
          <div className="public-catalogo-capas">

            <TitleWeb 
              {...props}
              titulo="Ofertas Amazon" 
              subtitulo="Procure por produtos e ofertas da Amazon de forma rápida e fácil" 
              image 
            />

            <Search {...props} count={store_amazon_offers?.count} />

            {
              isError
                ? <div style={{ height: 300 }} className="text-danger amazon-search-loading p-3 h5">                    
                    <p className="pl-3 pt-3">Ops, algo deu errado. Tente novamente.</p>
                  </div>
                : null
            }

            {
              store_amazon_offers.length <= 0 && !isError
              ? <div style={{ height: 300 }} className="text-orange amazon-search-loading p-3">
                  <div className="spinner-border"/>
                  <p className="pl-3 pt-3">CARREGANDO...</p>
                </div>
              : <GridCapas {...props} capas={lista_capas} />
            }

          </div>

        </R.Row>  
        </Suspense>
      </AppContainer>
    </>
  )
}

export default memo(connect( () => (mapState))(Catalogo));
