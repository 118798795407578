import React from 'react';
import store from '@/store';

import { DadosModal, Props } from '@/types';

import * as R from './render'; 

const initialState: DadosModal = {
  addAll: false,
  isOpen: true,
  scroll: false,
  height: 'auto',
  title: '',
  subtitle: '',
  body: <></>
}

const heightFull = '800';

const showModal = (props: Props, type: any): DadosModal => {
  switch (type) {
    case '@HELLO': return { 
      ...initialState,
      title: 'Hello World',
      subtitle: 'Teste de modal.',
      body: <> Body </>, 
    };
    
    case '@USER_LOGIN': return {
      ...initialState,     
      title: 'Que tal criar uma conta?',
      subtitle: 'Recurso exclusivo para clientes logados no sistema.',
      body: <R.UserLogin {...props} />, 
    };
    
    case '@LOADING': return {
      ...initialState,
      body: <R.Loading/>, 
    };

    case '@MODAL_LOGIN': return {
      ...initialState,     
      width: '500px',
      title: 'Entrar | Cadastrar',
      subtitle: 'Faça login ou crie a sua conta.',
      body: <R.ModalLogin {...props} />, 
    };

    case '@MODAL_LISTA_VOLUMES': return {
      ...initialState,
      addAll: true,
      scroll: true,
      height: heightFull,      
      title: store.getState().manga.store_volumes_modal_nome,
      subtitle: 'Lista completa da coleção',
      body: <R.ModalListaVolumes {...props} />, 
    };

    case '@MODAL_RESPOSTAS': return {
      ...initialState,
      scroll: true,
      height: heightFull,      
      title: `Respostas`,
      body: <R.ModalRespostas {...props} />, 
    };

    case '@MODAL_COLECAO_EDITAR': return {
      ...initialState,
      addAll: true,
      scroll: true,
      height: heightFull,
      title: store.getState().manga.store_colecao_modal!.manga,
      subtitle: store.getState().manga.store_colecao_modal?.subtitulo || '',
      body: <R.ModalColecaoEditar {...props} />, 
    };

    case '@MODAL_CONFIG': return {
      ...initialState,
      height: heightFull,
      title: 'Configurações',
      subtitle: 'Defina suas preferências do sistema',
      body: <R.ModalConfig {...props} />, 
    };

    case '@MODAL_SEARCH': return {
      ...initialState,
      height: heightFull,
      title: 'Busca de Mangás',
      subtitle: 'Pesquise em todo o nosso catálogo',
      body: <R.ModalSearch {...props} />, 
    };

    case '@MODAL_DONATION': return {
      ...initialState,
      height: heightFull,
      title: 'Doação',
      subtitle: 'O Meus Mangás precisa da sua ajuda',
      body: <R.ModalDonation/>, 
    };

    case '@RESET_MODAL': return {...initialState, isOpen: false};
    default: return {...initialState, isOpen: false};
  }
}

export default showModal;
