import React, { memo } from 'react';
import { TagReimpressao } from '@/components'

const RenderImage = (props) => (
  <div className="public-capa-hover-image text-muted">

    {
      props.store_user.config_reimpressao 
        ? <TagReimpressao flag={ props.dados.reimpressao_flag } />
        : null
    }

    <img 
      src={props.dados.capa} 
      alt="capa" 
      className="cursor noselect" 
      loading="lazy" 
      onClick={props.dados.link_info} 
    />
    <span onClick={props.dados.link_info}>
      { 
        props.dados.numero 
          ? <span className="text-orange pr-2">#{props.dados.numero}</span>
          : null
      }
      { 
        props.dados.titulo?.length > 36 
          ? `${props.dados.titulo.substring(0,35)}...`
          : props.dados.titulo 
      }
    </span>
  </div>
);

export default memo(RenderImage);
