import { ComentariosStore, ReducerType } from '@/types';

export const INITIAL_STATE: ComentariosStore = {  
  id_review: 0,
  store_review: [],
  store_review_user: [],
  store_respostas: [],
  store_interacoes: [],
  body_review: { 
    reviewBlog: "",
    reviewText: "",
    reviewYtb: "",
  },

  review_error_body: false,
  review_error_recomendacao: false,
  review_error_nota: false,
  delete_review_flag: false,
  new_review_flag: false,
}
 
 export default function manga_reducer(state = INITIAL_STATE, action: ReducerType) {
  switch(action.type) {  
    case '@SET_REVIEW': return { ...state,  store_review: action.payload };
    case '@SET_REVIEW_USER': return { ...state,  store_review_user: action.payload };
    case '@SET_RESPOSTAS': return { ...state,  store_respostas: action.payload };
    case '@SET_INTERACOES': return { ...state,  store_interacoes: action.payload };

    case '@SET_ID_REVIEW': return { ...state,  id_review: action.payload };
    case '@SET_BODY_REVIEW': return { ...state,  body_review: action.payload };

    case '@SET_REVIEW_ERROR_BODY': return { ...state,  review_error_body: action.payload };
    case '@SET_REVIEW_ERROR_RECOMENDACAO': return { ...state,  review_error_recomendacao: action.payload };
    case '@SET_REVIEW_ERROR_NOTA': return { ...state,  review_error_nota: action.payload };

    case '@DELETE_REVIEW_FLAG_TRUE': return { ...state,  delete_review_flag: true };
    case '@DELETE_REVIEW_FLAG_FALSE': return { ...state,  delete_review_flag: false };

    case '@FLAG_NEW_REVIEW_TRUE': return { ...state,  new_review_flag: true };
    case '@FLAG_NEW_REVIEW_FALSE': return { ...state,  new_review_flag: false };

    case '@RESET_COMENTARIOS': return {
      ...state,  
      id_review: 0,
      id_interacoes: 0,
      store_respostas: [],
      store_interacoes: [],
      body_review: {},

      review_error_body: false,
      review_error_recomendacao: false,
      review_error_nota: false,
      delete_review_flag: false,
    };

    default: return state;
  }
}
