import { Props, LoadVolumes } from '@/types';
import api from '@/services/api';
import { ShowMsg } from '@/components';

export type dadosHandle = {
  id_manga: LoadVolumes['id_manga'];
  id_volume: LoadVolumes['id'];
  id_editora: LoadVolumes['Manga']['id_editora'];
  id_user: Props['store_user']['id'];
  id_user_add: Props['store_user']['id'];
  id_user_edt: Props['store_user']['id'];
};

const newColecao = async (props: Props, dados: dadosHandle) => {
  const { dispatch, store_user } = props;
  const auth = store_user?.token;
  const id_volume = dados.id_volume;

  await api.post('/add-new-colecao', dados, auth)
          .then( null, error => ShowMsg({
            title: 'Ops!',
            message: error.response.data.error,
            type: 'error',
            tempo: 3000,
          }));
    
  dispatch({ 
    type: '@PUBLIC_COLECAO_ACTION', 
    response: { add: true, remove: false, id_volume }
  });

  ShowMsg(
    'Sucesso!',
    'Volume adicionado na sua coleção com sucesso.',
    'success'
  );
}

export default newColecao;
