import React from 'react';
import empty from '@/assets/capas/empty.jpg';
import { loadVolumes } from '@/functions';
import { CapaContainer } from '@/components';

const processModalVolumesArray = async (props, id_manga) => {
  let manga;
  const { dispatch } = props;
  const lista_volumes = [];
  const volumes = await loadVolumes(dispatch, id_manga, props.store_user);
  ////// CAPAS VOLUMES
  volumes.forEach(volume => {
    const { id: id_volume, id_manga, Manga, numero, imgur_link , amazon_link, colecao} = volume;
    const { id_editora, nome: titulo, subtitulo, original } = Manga;
    manga = titulo;

    let capa;
    !!imgur_link ? capa = imgur_link : capa = empty;

    lista_volumes.push( 
      <CapaContainer {...props} 
        key={`capa-container-${id_manga}-${id_volume}`}
        dados={{
          titulo,
          subtitulo,
          original,
          numero,
          publicacao: false,
          paginas: false, 
          preco: false,
          editora: false, 
          status: false,
          id_manga, 
          id_volume,
          id_editora,
          comprar: true,
          amazon_link,
          capa,
        }}
        colecao={{
          active: true,
          comprado: colecao > 0 ? true : false,
          total: 0
        }}
        mouseHover={{
          active: false,
          totalLinha: 0,
        }}
        modal={{
          active: true,
          capaModal: true,
        }}
        noClick={{ 
          active: true 
        }}
      />
    );
  });
  
  dispatch({ type: '@SET_VOLUMES_MODAL', payload: { manga, volumes, lista_volumes} });
  setTimeout(() => dispatch({ type: '@SET_MODAL', response: '@MODAL_LISTA_VOLUMES' }), 750);
};

export default processModalVolumesArray;
