import api from '@/services/api';
import { ShowMsg } from '@/components';
import { loadReview, loadReviewUser, processReview } from '@/functions';

const newReview = async (props, data) => {
  const { store_user, dispatch, match } = props;
  const { id_manga, recomendacao, nota_review, id_review } = data;
  const auth = store_user.token;

  const review = await processReview(props, data);

  if (review.error) { return }
  const { texto, link_youtube, link_blog } = review;

  const form = {
    id: id_review, 
    texto,
    link_youtube,
    link_blog,
    
    recomendacao,
    nota: nota_review,
    
    id_manga,
  }

  await api.put('/edt-review', form, auth)
           .then( null, error => ShowMsg({ title: 'Ops!', message: error.response.data.error, type: 'error', tempo: 3000, }));

  await loadReview(props, id_manga);
  await loadReviewUser(dispatch, store_user, match.params.id_manga);
  ShowMsg('Sucesso!', 'Resposta enviada com sucesso.', 'success');
}

export default newReview;
