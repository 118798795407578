import React, { memo, useCallback } from 'react';

const Title = (props) =>  {
  const { history, match, titulo, subtitulo, url, manga } = props;
 
  const page = match?.path.toString().replace('/','');

  const handleClick = useCallback((url) => {
    if (url) { history.push(url); }
  },[history])

  return (
    <>  
      <div className="noselect cursor">
        
        <p className="p-0 m-0 h1 text-light public-title" onClick={ () => handleClick(url) }>
          <b>{ titulo?.toUpperCase() }</b>
        </p>

        {
          subtitulo 
            ? <p className="p-0 m-0 pt-1 pb-2 h6 text-orange">
                { subtitulo }
              </p> : null
        }

        {
          page?.substring(0,4) === 'info' ?
            
            manga?.subtitulo ?
              <p className="p-0 m-0 pb-1 h5 text-muted noselect cursor public-title">
                <b>{ manga?.subtitulo?.toUpperCase() }</b>
              </p>
            : null

          : null
        }

        {
          page?.substring(0,4) === 'info' ?
            
            manga?.original ?
              <p className="p-0 m-0 pb-1 lead text-info noselect cursor public-title">
                <b>{ manga?.original?.toUpperCase() }</b>
              </p> : null
        
          : null
        }

      </div>
    </>
  )
}

export default memo(Title);
