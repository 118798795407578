import React from "react";
import { Helmet } from "react-helmet"; // TODO: analisar.

import OfertasAmazon from "@/components/ofertas-amazon";
import { AppContainer } from "@/components";
import { Props } from "@/types";

import Rank from "./rank";
import Checklist from "./checklist";
import Usuarios from "./usuarios";
import Banner from "./banner";
import Recentes from "./recentes";

const Home: React.FC = (props: Props) => {
  return (
    <>
      <AppContainer {...props}>
        {/* @ts-ignore */}
        <Helmet>
          <title>Home - Meus Mangás</title>
        </Helmet>

        <Banner />
        <Checklist {...props} />
        <OfertasAmazon {...props} />
        <Usuarios {...props} />
        <Rank {...props} />
        <Recentes {...props} />
      </AppContainer>
    </>
  );
};

export default Home;
