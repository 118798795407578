const replaceAccents = text => { 
  let newText = text;
  newText = newText.replace(/' '/g, '-');
  newText = newText.replace(/'%'/g, '');
  newText = newText.replace(/'ª'/g, '');
  newText = newText.replace(/'º'/g, '');
  newText = newText.replace(/':'/g, '');
  newText = newText.replace(/';'/g, '');
  newText = newText.replace(/'!'/g, '');
  newText = newText.replace(/'?'/g, '');
  newText = newText.replace(/'---'/g, '-');
  newText = newText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return newText;
}

const formatUrl = (titulo, subtitulo) => {
  let newSubtitulo;

  subtitulo === null || subtitulo === undefined
    ? newSubtitulo = ''
    : newSubtitulo = `-${replaceAccents(subtitulo)}`;

  return `${replaceAccents(titulo)}${newSubtitulo}`;
}

export default formatUrl;