import React, { memo, useEffect, useState } from 'react';

const TotalColecao = (props) => {

  const { colecao, dados } = props;
  const { total } = colecao;
  const { status } = dados;

  const [corTitulo, set_corTitulo] = useState('text-muted');
  const [Titulo, set_Titulo] = useState('bg-warning'); 

  useEffect(() => {
    set_Titulo(status)
    switch (status) {
      case 'Drop':
        set_corTitulo("bg-muted")
      break;
      case 'Completo':
        set_corTitulo("bg-success")
      break;
      case 'Incompleto':
        set_corTitulo("bg-danger")
      break;
      default:
    }
  },[status])
  
  return(
    <>
      { total
        ? <div className="mt-2 mb-1 flex-container flex-justify-right">
            
            <div className="public-status-label">
              <span className="text-light">{ total[0] }</span>
              <span className="text-orange"> / { total[1] }</span>
            </div>
            <div className={`public-status-colecao ${corTitulo}`}>
              { Titulo.toUpperCase() }
            </div>

          </div> 
        : null
      }   
    </>
  )
}


export default memo(TotalColecao);