import React, { memo, useState, useEffect } from 'react';
import { FaBloggerB, FaYoutube } from 'react-icons/fa'
import * as R from 'reactstrap';
import * as C from '@/components';
import Avatar from '@/assets/img/avatar.jpg';
import './style.less';

const Body = (props) => {
  const { 
    isLogin,
    dispatch,
    isEditable,
    store_user, 
    delete_review_flag,
    store_review_user,
    review_error_body,
  } = props;
  const { imagem, nome, sobrenome, apelido } = store_user;

  const [reviewText, set_reviewText] = useState();
  const [reviewYtb, set_reviewYtb] = useState();
  const [reviewBlog, set_reviewBlog] = useState();

  useEffect(() => {
    dispatch({ type: '@SET_BODY_REVIEW', payload: { reviewText, reviewYtb, reviewBlog }})
  }, [reviewText, reviewYtb, reviewBlog, dispatch]);

  useEffect(() => {
    if (!store_review_user[0]?.msg && isEditable) {
      const { texto, link_blog, link_youtube } = store_review_user[0];
      set_reviewText(texto);
      set_reviewYtb(link_youtube);
      set_reviewBlog(link_blog);
    }
  }, [isEditable, store_review_user]);

  useEffect(() => {
    if (delete_review_flag) {
      set_reviewText('');
      set_reviewYtb('');
      set_reviewBlog('');
      dispatch({ type: '@DELETE_REVIEW_FLAG_FALSE' });
    }
  }, [delete_review_flag, dispatch]);

  return (
    <R.CardBody className="border-none pt-1">
      {/**** TEXTO ****/}
      <R.Row className="review-usuario-comentario">
        <C.AvatarContainer
          src={ isLogin ? imagem : Avatar }
          title={apelido ? `${apelido}` : `${nome} ${sobrenome}`}
          size='md'
        />

        <R.Input
          className={` ml-1 review-input-area ${ review_error_body? `validation-error` : ``}`}
          type="textarea"
          style={{ height: 250 }}
          value={reviewText}
          onChange={({target}) => set_reviewText(target.value)}
        />
      </R.Row>
      {/**** YOUTUBE ****/}
      <R.Row className="flex-container flex-justify-space-between mt-3 pr-2">
        <p style={{ width: 180 }}className="h5 m-0 pl-2">
          <FaYoutube size={20} className="text-danger mr-2" />
          Link Youtube
        </p>
      
        <R.Input
          className="review-input"
          type="text"
          value={reviewYtb}
          onChange={({target}) => set_reviewYtb(target.value)}
        />
      </R.Row>
      {/**** BLOG ****/}
      <R.Row className="flex-container flex-justify-space-between mt-3 pr-2">
        <p style={{ width: 180 }}className="h5 m-0 pl-2">
          <FaBloggerB size={20} className="text-info mr-2" />
          Link Blog / Site
        </p>
      
        <R.Input
          className="review-input"
          type="text"
          value={reviewBlog}
          onChange={({target}) => set_reviewBlog(target.value)}
        />
      </R.Row>
    </R.CardBody>
  )
}

export default memo(Body);
