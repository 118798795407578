import React, { memo, useState, FC } from 'react';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import './style.css';

type Props = {
  children: any
  title: String
}

const Collapse = ({ children, title }: Props) => {

  const [show, set_show] = useState(true);
  
  return (
    <div className="pl-4 pr-4 mb-1">
    
      <div     
        className="cursor collapse-title"
        onClick={() => set_show(!show)}
      >

        <p className={`h4 ${show ? `text-white` : `text-muted`} `}>
          { title.toUpperCase() }
        </p>

        {
          show 
            ? <FaSortDown size={26} className="text-white" />
            : <FaSortUp size={26} className="text-muted" />
        }
      </div>

      <div className={`pt-4 noselect ${show ? `fadeIn` : `hide`}`}>
        { children }
      </div>

    </div>
  )
}

export default memo(Collapse);
