import React, { memo } from 'react';
import { RiCalendar2Fill } from "react-icons/ri";

const Publicacao = ({ dados, card_visibility }) => (
  <>
    {
      !card_visibility ?
        dados?.publicacao
        ? <div className="text-info mt-3 flex-container flex-justify-center">
            <RiCalendar2Fill size={19} className="mr-2" />
            <span className="">{ dados?.publicacao }</span>
          </div> 
        : null
      : null
    }
  </>
)

export default memo(Publicacao);
