import React, { useState, useEffect, memo } from 'react';
import { Col } from "reactstrap";
import DonationBody from './body';
import './styles.css';

const Donation = (props) => {
  const { meta, total } = props;
  const [style, setStyle] = useState({});
  const [done, set_done] = useState(0);
	
  useEffect(() => {
    let percento = (total * 100) / meta;
    
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${percento}%`
      }
      set_done(percento);
      setStyle(newStyle);
    }, 200);

  }, [meta, total]);

  return(
    <>
      <Col className="col-12 p-4 pt-5">

        <DonationBody {...props} meta={`R$ ${meta},00`} />

        <div className="progress">
          <div className="progress-done" style={style}>
            {done}%
          </div>
        </div>

      </Col>
    </>
  )
}

export default memo(Donation);
