import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { AvatarGroup } from 'rsuite'

import { AuthStore } from '@/types';
import { AvatarContainer } from '@/components';
import { TGridUsuarios } from './GridUsuarios.type';

import './grid-usuarios.less';

const GridUsuarios: React.FC<TGridUsuarios> = ({ 
  users,
  limit,
}) => {
  return (
    <AvatarGroup className='row p-4 m-0'>
      <div className='grid col-12'>
        {!!users && users
          .filter((_, index) => index < limit)
          .map((user) => {
            const {apelido, nome, sobrenome, share_id, imgur_link} = user;
            const nomeCompleto = apelido ? apelido : `${nome} ${sobrenome}`;

            return (
              <Link to={`/colecao/${share_id}`} key={share_id}>
                <AvatarContainer
                  key={share_id}
                  src={imgur_link}
                  title={nomeCompleto}
                  tooltip={nomeCompleto}
                />
              </Link>
            )
          })
        }
      </div>
    </AvatarGroup>
  );
}

export default GridUsuarios;
