

import React, { memo } from 'react';
import * as R from 'reactstrap';
// import * as C from 'components';
// import * as F from 'functions';
import Header from './header';
import Footer from './footer';

const ReviewBlock = (props) => (
  props.store_user.id !== 9999 
    ? <>       
      <R.Row className="pl-1 pr-1 mb-4">
        <R.Col className="pl-5 pr-5">

          <R.Card className="border-none text-muted" style={{ maxWidth: 900, background: '#101820' }}>
            <Header {...props} comentario={props.store_review_user[0]} />
            <Footer {...props} />
          </R.Card>

        </R.Col>
      </R.Row>
    </>
  : null
)

export default memo(ReviewBlock);
