import api from '@/services/api';
import { TDispatch, IStore_all_mangas_recents } from '@/types';

type TLoadAllMangasRecents = {
  dispatch: (p: TDispatch) => void
}

const loadAllMangasRecents = async ({ dispatch }: TLoadAllMangasRecents): Promise<void> => {
   const response = await api.get('/all-mangas-recents');
   const lista: IStore_all_mangas_recents = response.data;   
   dispatch({ type: '@SET_ALL_MANGAS_RECENTS', response: lista });
}

export default loadAllMangasRecents;