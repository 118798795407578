import React, { memo, useState, useEffect } from 'react';
import { FaSpinner } from "react-icons/fa";
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { GridCapas } from '@/components';
import mapState from './mapstate';
import Header from './header';
import Titulo from './titulo';
import './style.css';

const ModalColecao = (props) => {
  const { 
    store_volumes_modal_elements,
  } = props;

  const [isLoading, set_isLoading] = useState(true);

  useEffect(() => {
    if (store_volumes_modal_elements.length > 0 ) {
      setTimeout(() => set_isLoading(false), 350)
    }
  }, [store_volumes_modal_elements])

  return (
    <>
      {
        isLoading
          ? <Col xs={12} className="flex-container flex-justify-center p-4">
              <FaSpinner size={22} className="icon-spin mr-2" />
              carregando...
            </Col> 
          : <Col xs={12} className="pl-0 pt-0 pr-0 pb-2">
              <Row className="text-white">
                <Col xs={12}>
                  <Header {...props}/>
                </Col>
                <Col xs={12} className="pt-2">
                  <Titulo {...props}>LISTA DE VOLUMES</Titulo>
                  <GridCapas {...props} capas={store_volumes_modal_elements} customKey="modal-colecao" />
                </Col>
              </Row>
            </Col>   
      }
    </>
  )

}

export default memo(connect( () => (mapState))(ModalColecao));
