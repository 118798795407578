import { ShowMsg } from '@/components';
import { saveCache } from '@/functions';

const processLogin = async (props, dados) => {
   const { dispatch } = props;
   const { user } = dados;

   //// DADOS DO BD
   const {
      id,
      imgur_link,
      config_show_msg,
      config_auto_login,
    } = user.user;
   const { token: user_token } = user;

   let imagem = '';
   if (imgur_link) imagem = imgur_link;

   //// SAVE CACHE
   const response =  {
                        ...user.user,
                        imagem,  
                        token: { headers: { Authorization: `Bearer ${user_token}` } }
                     }

   saveCache(config_auto_login, dados);
   await dispatch({ type: '@SET_USER', response });
   await dispatch({ type: '@SET_LOGIN_TRUE' });
   await dispatch({ type:'@SET_MODAL', response: '@RESET_MODAL' });
   
   if (config_show_msg) {
      ShowMsg(
         'Bem-vindo!',
         'Acesso garantido com sucesso.',
         'success',
         1000,
         true,
      );
   } 
   // getLogSessao(props, id);
};

export default processLogin;
