import React, { memo, useState, useEffect, useCallback } from 'react';
import { Button } from 'reactstrap';
import { newColecaoAll, deleteColecaoAll } from '@/functions';
import { Props, LoadVolumes } from '@/types';

const AddAll = (props: Props) =>  {
  const { 
    addAll,
    store_user,
    store_volumes_modal,
    store_volumes,
    colecao_action,
    colecao_btnAll_action,
    dados
  } = props;

  const defaultVolume: LoadVolumes[] = [];
  const [volumes, set_volumes] = useState(defaultVolume);
  const [tenhoTudo, set_tenhoTudo] = useState(false);

  const RenderLabel = useCallback(() => {
    return ( !tenhoTudo ? '+ ADICIONAR TODOS' : '- REMOVE TODOS' )
  }, [tenhoTudo]);

  const HendleOnClick = useCallback(() => {
    if(!tenhoTudo) {
      newColecaoAll(props, volumes);
    }
    else {
      deleteColecaoAll(props, volumes);
    }
  }, [props, volumes, tenhoTudo]);

  //AO RECEBER DADOS DE VOLUMES
  useEffect(() => {
    if (dados && store_volumes_modal) {
      set_volumes(store_volumes_modal);
    }
    else if (store_volumes) {
      set_volumes(store_volumes);
    }
  }, [dados, store_volumes_modal, store_volumes]);

  useEffect(() => {
    const { add: addAll, remove: removeAll } = colecao_btnAll_action || { add : false, remove: false };
    const { add, remove, id_volume } = colecao_action || { add : false, remove: false, id_volume: null };
    
    if (addAll || removeAll) {
      const addOrRemoveVolumes = [...volumes].map((volume) => {
        volume.colecao = addAll;
        return volume;
      });
      set_volumes(addOrRemoveVolumes);
    }

    if (add || remove) {
      const addOrRemoveVolume = [...volumes].map((volume) => {
        if(volume.id === id_volume){
          volume.colecao = add;
        }
        return volume;
      });
      set_volumes(addOrRemoveVolume);
    }
  }, [colecao_btnAll_action, colecao_action, volumes]);

  //AO TER VOLUMES PREENCHIDO
  useEffect(() => {
    if(addAll && store_user.id !== 9999 && volumes.length) {
      const temTodosVolumes = volumes.every(volume => !!volume.colecao);
      set_tenhoTudo(temTodosVolumes);
    }
  }, [addAll, store_user, volumes]);

  return (
    <>  
      {
        addAll
        ? <Button 
            style={{ width: 200 }} 
            className={`btn btn-sm border-none ${!tenhoTudo ? `btn-orange text-dark` : `text-white btn-danger`}`}
            onClick={() => HendleOnClick()}
          ><b>{RenderLabel()}</b></Button>
        : null
      }
    </>
  )
}

export default memo(AddAll);
