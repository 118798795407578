
import React, { memo, useState, useEffect, useCallback } from 'react';
import { loadNotas, saveNota } from '@/functions';
import { FaStar } from 'react-icons/fa';
import 'moment/locale/pt-br';

const Nota = (props) => {
  const { dispatch, match, store_nota, store_user, id_manga } = props;
  const { total_votos, nota_manga } = store_nota;

  const [starSelected_1, set_starSelected_1] = useState(false);
  const [starSelected_2, set_starSelected_2] = useState(false);
  const [starSelected_3, set_starSelected_3] = useState(false);
  const [starSelected_4, set_starSelected_4] = useState(false);
  const [starSelected_5, set_starSelected_5] = useState(false);
  
  const star_size = 36;

  const starSelect = useCallback((id) => {
    switch (id) {
      case 1:
        set_starSelected_1(true);
        set_starSelected_2(false);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 2:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 3:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 4:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(false);
      break;
      case 5:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(true);
      break;
      default:
    }
  },[]);

  const starSave = useCallback((id) => {
    const { 
      store_user,
      store_colecao_user,
      checked,
      match,
    } = props;

    const { id: id_user } = store_user;
    const { id: id_user_colecao } = store_colecao_user;

    if (id_user === 9999 || id_user === undefined) { return }
    
    if (id_user === id_user_colecao || match.path !== '/colecao/:share_id') {
      const form = {
        nota: id,
        id_user: store_user.id,
        id_manga,
      }

      starSelect(id);
      saveNota(props, form);
    }
  },[props, id_manga, starSelect]);

  const Star = useCallback(({ id, selected }) => (
    <FaStar key={`nota-id-${id}`}
              size={star_size} 
              className={`cursor ${selected ? `text-orange` : `text-muted`}`}
              onClick={() => starSave(id)}
    />
  ), [starSave]);

  const NotaGeral = useCallback(() => (
    <div>
      <p className="pt-1 m-0 text-info text-right">NOTA GERAL</p>

      <p className="h1 p-0 m-0 text-orange">
        { parseFloat(nota_manga).toFixed(1) }
        <span className="h4 text-muted"> / 5</span>
      </p>

      <p className="h6 p-0 m-0 text-right text-info">
        { total_votos } votos
      </p>
    </div>
  ), [nota_manga, total_votos])

  const StarsWrapper = useCallback(() => (
    <div>
      <p className=" m-0">
        <span className="p-1 pl-2 pr-2 bg-primary text-white text-center noselect">AVALIE</span>
      </p>
      
      {/* <div className="mt-3" onMouseLeave={()=> starOver(nota_usuario)}></div> */}
      <div className="mt-3">
        <Star id={1} selected={starSelected_1} />
        <Star id={2} selected={starSelected_2} />
        <Star id={3} selected={starSelected_3} />
        <Star id={4} selected={starSelected_4} />
        <Star id={5} selected={starSelected_5} />
      </div>
    </div>
  ), [starSelected_1, starSelected_2, starSelected_3, starSelected_4, starSelected_5,])

  useEffect(() => {
    if (match?.params.id_manga) {
      loadNotas(dispatch, {
        id_user: store_user.id,
        id_manga: match.params.id_manga,
      });
    }
  }, [store_user, dispatch, match,]);

  useEffect(() => {
    starSelect(store_nota?.nota_user);
  },[store_nota, starSelect]);

  return (
    <>
      <div className="noselect flex-container flex-justify-space-between">
        <StarsWrapper />
        <NotaGeral />
      </div>
    </>
  )
}

export default memo(Nota);
