import React, { memo, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Col } from "reactstrap";
import { navigateTo } from "@/functions";
// import * as IO from "react-icons/io";
import * as RI from "react-icons/ri";
import * as BS from "react-icons/bs";
// import * as FA from "react-icons/fa";
import * as BI from "react-icons/bi";
// import * as AI from "react-icons/ai";
// import * as MD from "react-icons/md";
import './style.css';

const Sidebar = (props) => {
  const {
    dispatch,
    match,
    store_user,
  } = props;

  const size = 30;
  const page = match.path.split('/')
  const classActive = "public-sidebar-box-active";
  const classInactive = "public-sidebar-box text-muted";

  const ano_atual = moment().format("YYYY");
  const mes_atual = moment().format("MM");

  const [active_1, set_active_1] = useState(classInactive);    // HOME
  const [active_2, set_active_2] = useState(classInactive);    // CHECKLIST
  const [active_3, set_active_3] = useState(classInactive);    // COLECAO
  const [active_4, set_active_4] = useState(classInactive);    // CATALOGO
  // const [active_5, set_active_5] = useState(classInactive); 
  // const [active_6,] = useState(classInactive);                // PERFIL
  const [active_7, set_active_7] = useState(classInactive);    // CONFIG
  const [active_8, set_active_8] = useState(classInactive);    // INFO
  const [active_9,] = useState(classInactive);                 // ADMIN
  const [active_10, set_active_10] = useState(classInactive);  // AMAZON
  const [active_11, set_active_11] = useState(classInactive);  // AMAZON

  const changePage = useCallback((id, url) => {
    dispatch({ type: "@PUBLIC_SIDEBAR_ACTIVE", response: id});
    if (props.match.path === url) { return }

    id === 9
      ? window.open(url)
      : navigateTo(props, url, id);
  }, [props, dispatch]);

  const addIndex = useCallback((id) => {
    const element = document.getElementById(id);
    element.classList.add("public-sidebar-zindex");
  },[]);

  const removeIndex = useCallback((id) => {
    const element = document.getElementById(id);
    element.classList.remove("public-sidebar-zindex");
  },[]);

  const Box = useCallback(({ id, url, className, children, onClick }) => (
    <div  onMouseEnter={() => addIndex("public-sidebar")} 
          onMouseLeave={() => removeIndex("public-sidebar")}
          onClick={ url ? () => changePage(id, url) : onClick } 
          className={`flex-container noselect flex-justify-center public-sidebar-${id} ${className}`}>
        { children }
    </div> 
), [addIndex, removeIndex, changePage]); 

  /// SET ACTIVE
  useEffect(() => {
    switch (page[1]) {
      case '':
        set_active_1(classActive)
      break;
      case 'checklist':
        set_active_2(classActive)
      break;
      case 'colecao':
        set_active_3(classActive)
      break;
      case 'catalogo':
        set_active_4(classActive)
      break;
      case 'config':
        set_active_7(classActive)
      break;
      case 'ofertas-amazon':
        set_active_10(classActive)
      break;
      case 'reviews':
        set_active_11(classActive)
      break;
      // case 'ofertas-amazon':
      //   set_active_10(classActive)
      // break;
      case 'info':
        set_active_8("public-sidebar-box public-sidebar-info-active");
      break;
      default:
    }
    // eslint-disable-next-line
  },[])

  return (
    <>
      <Col  id="public-sidebar" 
            className="public-sidebar shadow flex-container flex-direction-column flex-justify-space-between">
        <div style={{ alignSelf: 'flex-start' }}>

          {/*** HOME ***/}
          <Box id={1} className={active_1} url="/">            
              <BI.BiHomeAlt size={size} />
              <label id="sidebar-1">HOME</label>
          </Box>

          {/*** CHECKLIST ***/}
          <Box id={2} className={active_2} url={`/checklist/${mes_atual}/${ano_atual}`}>            
              <BI.BiListUl size={size} />
              <label className="" id="sidebar-2">CHECKLIST</label>
          </Box>

          {/*** COLECAO ***/}
          <Box id={3} className={active_3} url={`/colecao/${store_user.share_id}`}>            
              <BI.BiBook size={size} />
              <label className="" id="sidebar-3">COLEÇÃO</label>
          </Box>

          {/*** CATALOGO ***/}
          <Box id={4} className={active_4} url="/catalogo">            
              <BI.BiFilter size={size} />
              <label className="" id="sidebar-4">CATÁLOGO</label>
          </Box>

          {/*** PERFIL ***/}
          {/*
          <Box id={6} className={active_6} url="/perfil">
              <BI.BiUser  size={size} />
              <label className="" id="sidebar-6">PERFIL</label>
          </Box>
          */}
        
          {/*** LEITURA ***/}
          {/*
          <Box id={4} className={active_4} url="/">            
              <FA.FaEye size={size} />
              <label className="" id="sidebar-4">LEITURA</label>
          </Box>
          */}

          {/*** COMPRAS ***/}
          {/*
          <Box id={4} className={active_4} url="/">            
              <MD.MdShoppingCart size={size} />
              <label className="" id="sidebar-4">COMPRAS</label>
          </Box>
          */}

          {/*** FAVORITOS ***/}
          {/*
          <Box id={4} className={active_4} url="/">            
              <AI.AiFillHeart size={size} />
              <label className="" id="sidebar-4">FAVORITOS</label>
          </Box>
          */}

          {/*** OFERTAS AMAZON ***/}
          <Box id={10} className={active_10} url="/ofertas-amazon">            
              <RI.RiAmazonFill size={size} />
              <label className="" id="sidebar-10">OFERTAS AMAZON</label>
          </Box>

          {/*** INFO ***/}
          <div className={`flex-container flex-justify-center ${active_8}`}>
            <BI.BiBookAlt size={size} />
            <label className="" id="sidebar-8">+ INFO</label>
          </div>

          {/*** REVIEWS ***/}
          <Box id={11} className={active_11} url="/reviews">            
              <BS.BsChatSquareDots size={size} />
              <label className="" id="sidebar-11">REVIEWS</label>
          </Box>
          
        </div>

        <div style={{ alignSelf: 'flex-start' }}>
          {
            store_user.id !== 9999
            ? <Box id={7} className={active_7} onClick={() => dispatch({ type: '@SET_MODAL', response: '@MODAL_CONFIG' })}>
                <BI.BiCog size={size} />
                <label className="" id="sidebar-7">CONFIG</label>
              </Box> 
            : null
          }

          {/*** ADMIN ***/}
          {
            store_user.admin
            ? <Box id={9} className={active_9} url="http://admin.meusmangas.com.br/">            
                <BI.BiLock size={size} />
                <label className="" id="sidebar-9">ADMIN</label>
              </Box> 
            : null
          }
        </div>

      </Col> 
    </>
  )
}

const mapState = state => ({
  active: state.sidebar.active,
});

export default memo(connect(() => (mapState))(Sidebar));
