const mapState = state => ({
  isLogin: state.auth.isLogin,
  store_user: state.auth.store_user,
  store_colecao_user: state.auth.store_colecao_user,
  
  loading: state.sistema.loading,
  
  id_sessao: state.auth.id_sessao,
  windowSize: state.sistema.windowSize,
  
  store_volumes: state.manga.store_volumes,
  store_nota: state.manga.store_nota,
})

export default mapState;
