const mapState = state => ({
  isLogin: state.auth.isLogin,
  store_user: state.auth.store_user,
  store_review_user: state.comentarios.store_review_user,
  delete_review_flag: state.comentarios.delete_review_flag,

  body_review: state.comentarios.body_review,
  review_error_body: state.comentarios.review_error_body,
  review_error_recomendacao: state.comentarios.review_error_recomendacao,
  review_error_nota: state.comentarios.review_error_nota,
});

export default mapState;
