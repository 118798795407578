export const INITIAL_STORE_USER = {
  id: 9999, 
  email: '',
  nome: '', 
  sobrenome: '', 
  apelido: '',
  admin: false,
  imgur_link: '',
  imagem: '',

  colecao_filtro: '{"editora":"","status":""}',
  config_show_msg: false,
  config_reimpressao: true,
  config_auto_login: false,
  config_mail: false,
  config_colecao_filtro: false,
  config_colecao_tabela: false,

  total_comentarios: 0,
  total_mangas: 0,
  total_reviews: 0,
  total_volumes: 0,
  valor_colecao: 0,

  share_id: '',
  token: undefined, 
}

export const INITIAL_COLECAO_USER = {
  id: 9999, 
  nome: '', 
  sobrenome: '', 
  apelido: '',
  share_id: '',
  imgur_link: '',
  total_comentarios: 0,
  total_mangas: 0,
  total_reviews: 0,
  total_volumes: 0,
  valor_colecao: 0,
}

export const INITIAL_SHARE_USER = {
  id: 0, 
  nome: '', 
  sobrenome: '', 
  apelido: '',
  share_id: '',
  imgur_link: '',
  token: undefined
}

export const INITIAL_STORE_CACHE = {
  user: null,
  googleData: null,
  expires: '',
}