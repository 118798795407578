import api from '@/services/api';

const loadReview = async (props, id) => {
  const { dispatch, store_user  } = props;
  const auth = store_user.token;

  const form = {
    id_manga: id,
    id_user: store_user.id,	
  }

  const response = await api.post(`/list-reviews/`, form, auth);
  const review = response.data;
  dispatch({ type: '@SET_REVIEW', payload: review });
  // return review;
}

export default loadReview;