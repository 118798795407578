import React, { memo } from 'react';
import * as F from '@/functions';
import RenderImage from './renderImage';
import RenderInfo from './renderInfo';
import './style.css';

const MouseHover = (props) => {
  const { 
    flags,
    visiblility,
    handleLido,
    handleCompras,
    handleFavorito,
    delManga, 
    addManga,
    onMouseLeave,
  } = props;

  const {
    flag_comprado,
    flag_lido,
    flag_compras,
    flag_favorito,
  } = flags;

  ////// PROPS
  const { 
    dados,
    mouseHover,
    modal,
    noClick
  } = props;

  const { 
    titulo,
    subtitulo,
    numero,
    editora,
    id_manga, 
    amazon_link,
    capa,    
    reimpressao_flag,
  } = dados;
  
  const {
    cardPosition,
    menuBasic,
  } = mouseHover;

  const { 
    active: isModal,
  } = modal;

  const { 
    active: isNoClick,
  } = noClick;

  const url_info = `/info/${id_manga}/${F.formatUrl(titulo, subtitulo)}`;
  const link_info = () => F.navigateTo(props, url_info, 8);
  const link_modal = () => F.modalVolumes(props, id_manga);  
  const iconSize = 20;

  const dadosRenderInfo = {
    titulo,
    subtitulo,
    numero,
    editora,
    id_manga,
    flag_comprado, 
    delManga, 
    addManga, 
    flag_lido, 
    handleLido, 
    flag_compras, 
    handleCompras,  
    flag_favorito, 
    handleFavorito,
    amazon_link,
    noClick: isNoClick,
    modal: isModal,
    link_info,
    link_modal,
    menuBasic,
    url_info,
    iconSize,
  }

  return (
    <>
      {
      visiblility ?
        <div className={`bg-primary-dark public-capa-hover public-capa-hover-${cardPosition} flex-container flex-container-top flex-justify-space-between`}
              onMouseLeave={onMouseLeave}
              >
        
        {
          cardPosition === 'left' ? (
            <>
              <RenderInfo {...props} dados={dadosRenderInfo} />
              <RenderImage {...props} dados={{ capa, link_info, numero, titulo, reimpressao_flag }} />
            </>
          )
          : <>
              <RenderImage {...props} dados={{ capa, link_info, numero, titulo, reimpressao_flag }} />
              <RenderInfo {...props} dados={dadosRenderInfo} />
            </>
        }

        </div>
      : null
      }
    </>
  )
}

export default memo(MouseHover);
