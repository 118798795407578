import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

const msg = 'Este campo é obrigatório.';

export const modelStepOne = Schema.Model({
  email: StringType().isRequired(msg).isEmail('Por favor, digite um email válido.'),
  password: StringType().isRequired(msg),
});

export const modelStepTwo = Schema.Model({
  nome: StringType().isRequired(msg),
  email: StringType().isRequired(msg).isEmail('Por favor, digite um email válido.'),
  password: StringType().isRequired(msg),
});

export const modelStepThree = Schema.Model({
  email: StringType().isRequired(msg).isEmail('Por favor, digite um email válido.'),
});
