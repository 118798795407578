import React, { memo, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Input } from "reactstrap";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { formatPublicacao, loadChecklist } from '@/functions';
import './style.css';
import './responsive.css';

const Title = (props) => {
  const store_checklist_group = useSelector(({ manga }) => manga.store_checklist_group);
  const { match, history, dispatch, store_user, /*location, id_sessao,*/ } = props;
  const size = "80px";

  const mes_atual = match.params.mes;
  const ano_atual = match.params.ano;

  const [checklist_mes, set_checklist_mes] = useState(mes_atual);
  const [checklist_ano, set_checklist_ano] = useState(ano_atual);

  /// HANDLE CHECKLIST INPUTS
  const handleChecklistInputs = useCallback((mes, ano) => {
    set_checklist_mes(mes);
    set_checklist_ano(ano);
  }, []);

  /// HANDLE CHECKLIST ARROWS
  const handleChecklistArrows = useCallback((type, mes, ano) => {
    let _mes = parseInt(mes, 10);
    let _ano = parseInt(ano, 10);
    let new_mes = _mes;
    let new_ano = _ano;
    
    switch (type) {
      case 'minus':
      
        new_mes -= 1;
        if ( new_mes < 1 ) {
          new_mes = 12;
          new_ano -= 1; 
        }

      break;
      case 'plus':
        
        new_mes += 1;
        if ( new_mes > 12 ) {
          new_mes = 1;
          new_ano += 1; 
        }

      break;
      default:
    }

    if (new_mes < 10) { new_mes = `0${new_mes}` }

    set_checklist_mes(new_mes);
    set_checklist_ano(new_ano);

    dispatch({ type: '@RESET_CHECKLIST'});

    // eslint-disable-next-line
    // const data = {
    //   id_sessao,
    //   id_user: store_user.id,
    //   location: location.pathname,
    // }

    // getLogNavegacao(data);
  }, [dispatch, /*location, store_user, id_sessao*/]);

  // HANGLE WHEN HISTORY CHANGE WITHOUT MY CONTROLLER
  useEffect(() => {
    history.listen(location => {
      if (['PUSH', 'POP'].includes(history.action)) {
        const theUrl = location.pathname.split('/');
        const page = (Array.isArray(theUrl) && theUrl.length === 4) && theUrl[1];
        if (!!page && page === 'checklist') {
          const newAno = theUrl[3];
          const newMes = theUrl[2];
          let hadChange = false;

          if (checklist_ano !== newAno) {
            set_checklist_ano(newAno);
            hadChange = true;
          }
          if (checklist_mes !== newMes) {
            set_checklist_mes(newMes);
            hadChange = true;
          }
          if (hadChange) dispatch({ type: '@RESET_CHECKLIST'});
        }
      }
    });
  }, []);
  

  // HANDLE CHECKLIST LOADING AFTER SUCH CHANGES
  useEffect(() => {
    const publicacao = `${checklist_ano}${checklist_mes}`;
    const currentTitle =  localStorage.getItem('CHECKLIST_TITLE');
    const newTitle = formatPublicacao(publicacao);

    const newUrl = `/checklist/${checklist_mes}/${checklist_ano}`;
    const oldUrl = history.location.pathname;

    if (oldUrl !== newUrl) { 
      history.push(newUrl, oldUrl);
    }

    if (!store_checklist_group.length || currentTitle !== newTitle) {
      localStorage.setItem('CHECKLIST_TITLE', newTitle);
      
      loadChecklist({ 
        store_user, 
        dispatch,
        publicacao, 
        id_user: store_user.id, 
        group: true, 
      });
    }
    // eslint-disable-next-line
  }, [history, checklist_mes, checklist_ano, formatPublicacao]);
  
  return (
    <>
      <Row style={{ height:115 }} className="p-0 m-0 mt-1 flex-container flex-justify-space-between">
        
        <FaAngleLeft 
          size={size}
          onClick={() => handleChecklistArrows('minus', checklist_mes, checklist_ano)}
          className="ml-4 text-orange cursor"                  
        />

        <div className="public-checklist-inputs flex-container flex-justify-space-between"> 
          {/*** FILTRO ***/}
          <div style={{ width:260 }} className="flex-container flex-justify-space-between">
            <div>
              <Input 
                type="select"
                className="public-title-checklist noselect text-muted" 
                value={ checklist_mes }
                style={{ minWidth:155 }}
                onChange={ e => handleChecklistInputs(e.target.value, checklist_ano) }
              >
                <option className="p-2" value="0">Selecione...</option>
                <option className="p-2" value="01">JANEIRO</option>
                <option className="p-2" value="02">FEVEREIRO</option>
                <option className="p-2" value="03">MARÇO</option>
                <option className="p-2" value="04">ABRIL</option>
                <option className="p-2" value="05">MAIO</option>
                <option className="p-2" value="06">JUNHO</option>
                <option className="p-2" value="07">JULHO</option>
                <option className="p-2" value="08">AGOSTO</option>
                <option className="p-2" value="09">SETEMBRO</option>
                <option className="p-2" value="10">OUTUBRO</option>
                <option className="p-2" value="11">NOVEMBRO</option>
                <option className="p-2" value="12">DEZEMBRO</option> 
              </Input>
            </div> 
            
            <div>
              <Input 
                type="number"  
                className="public-title-checklist noselect text-muted"
                value={ checklist_ano }
                style={{ width:95 }}
                onChange={ e => handleChecklistInputs(checklist_mes, e.target.value) }
              />
            </div>
          </div>

          <FaAngleRight 
            size={size}
            onClick={() => handleChecklistArrows('plus', checklist_mes, checklist_ano)}
            className="mr-4 text-orange cursor"                  
          />
        </div>
      
      </Row>
    </>
  )
}

export default memo(Title);
