import { ReducerType, ISidebar } from '@/types';

export const INITIAL_STATE: ISidebar = {  
  active: 1
}
 
export default function sidebar_reducer(state = INITIAL_STATE, action: ReducerType) {
  switch(action.type) {  
    case '@PUBLIC_SIDEBAR_ACTIVE': return { ...state,  active: action.response };
    default: return state;
  }
}
