import React from 'react';
import { Button } from 'rsuite';
import { logout } from '@/functions';
import { AvatarContainer } from '@/components';
import { AuthStore } from '@/types';
import './navbar.less';

type propsType = {
  store_user: AuthStore['store_user']
};

export default function User (props: propsType) {
  const { store_user } = props;
  const { imagem, apelido, nome, sobrenome } = store_user;
  const nomeParaExibir = (!!apelido ? apelido : nome);
  const sobrenomeParaTitulo = (!!apelido ? '' : sobrenome);
  return (
    <>
      <div className="noselect">
        <div style={{ width: 250 }} className="navbar-login-container">
       
          <div>
            <span className="text-muted pr-1">Olá </span>
            <span className="text-white pr-1">{nomeParaExibir}</span>
          </div>     

          <AvatarContainer 
            src={imagem} 
            title={`${nomeParaExibir} ${sobrenomeParaTitulo}`}
            size='xs' 
          />

          <Button 
            className='nav_bar bold'
            color='orange'
            appearance="primary"
            onClick={() => logout(props)}
          >
            SAIR
          </Button>

        </div>
      </div>  
    </>
  );
};
