const mapState = state => ({
  store_user: state.auth.store_user,
  store_colecao_user: state.auth.store_colecao_user,
  
  colecao_action: state.sistema.colecao_action,
  colecao_btnAll_action: state.sistema.colecao_btnAll_action,
  Hover_ID: state.sistema.cardHover_ID,
  id_sessao: state.auth.id_sessao,
});

export default mapState;