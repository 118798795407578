import api from '@/services/api';

const loadRespostas = async (props, id) => {
  const { dispatch, store_user  } = props;
  const auth = store_user.token;

  const form = {
    id_review: id,
    id_user: store_user.id,	
  }

  const response = await api.post(`/list-respostas/`, form, auth);
  const respostas = response.data;
  dispatch({ type: '@SET_RESPOSTAS', payload: respostas });
  // return respostas;
}

export default loadRespostas;