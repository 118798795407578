import { RankStore, ReducerType } from '@/types';

export const INITIAL_STATE: RankStore = {  
  store_rank: [],
}
 
 export default function manga_reducer(state = INITIAL_STATE, action: ReducerType) {
  switch(action.type) {  

    case '@RESET_RANK_MANGAS': return { ...state,  store_rank: [] };
    case '@SET_RANK_MANGAS': return { ...state,  store_rank: action.response };

    default: return state;
  }
}
