import React, { memo } from 'react';
import { FaSpinner } from "react-icons/fa";

const AmazonContainer = ({ ASIN, onClick, isLoad, SRC, PriceDisplayAmount, SavingsPercentage, TitleDisplayValue }) => (
  <>
    <figure key={ASIN} style={{ width: 170, height: 300 }}
            className="cursor text-white noselect bg-primary-dark mr-2"
            onClick={onClick}
    >
      <div className="amazon-container p-3">
        {
          isLoad 
          ? <img key={ASIN} loading="lazy" className="float-left mb-1 public-cover" src={SRC} alt="" />
          : <div className="amazon-spinner-container"><FaSpinner size={22} className="icon-spin" /></div>
        }

        <span className="text-info h6 mt-2">{ PriceDisplayAmount } </span>
          
        {
            SavingsPercentage
              ? <span className="text-success h6">{SavingsPercentage}% desconto</span>
              : null
        }
        
        <span className="text-muted h6">
          { TitleDisplayValue?.replace(/'Volume'/g, 'Vol.').substring(0,35) }
        </span>
      </div>                                
    </figure>             
  </>
)

export default memo(AmazonContainer);
