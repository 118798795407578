import React from 'react';
import { connect } from 'react-redux';
import * as C from '../../components';

const Editora = (props) => {
  return (
    <>
      <C.AppContainer {...props}>

          <C.TitleWeb titulo="editora" subtitulo="" />

      </C.AppContainer>
    </>
  )
}

const mapState = state => ({
    isLogin: state.auth.isLogin,
    store_user: state.auth.store_user,
})

export default connect(() => (mapState))(Editora);