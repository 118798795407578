import React, { memo } from 'react';
import * as R from 'reactstrap';
import { FaHandPointRight } from 'react-icons/fa';
import { processLinkYoutube, formatLinkExternal } from '@/functions';
import './style.css';

const CardComentario = (props) => (  
  <>
    <R.CardBody style={{ whiteSpace: 'pre-wrap' }} className="border-none pt-3">       
      {
        props.comentario.link_youtube ? 
          <>
            <div className="flex-container-top fadeIn flex-justify-space-between">
              <div className="card-body-ytb pb-3">
                
                <iframe
                  width="420"
                  height="315"
                  src={`https://www.youtube.com/embed/${processLinkYoutube(props.comentario?.link_youtube)}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
              <div className="card-body-text text-justify">
                { props.comentario.texto }
              </div>
            </div>
          </>
        : props.comentario.texto 
      }

      {
        props.comentario.link_blog 
          ? <p className="h6 mt-3 flex-container text-orange">
              LEIA MAIS
              <FaHandPointRight size={22} className="ml-2 mr-3" />
              <a href={formatLinkExternal(props.comentario.link_blog)} rel="noopener noreferrer" target="_blank">
                <span className="cursor text-info">
                  <u>{ props.comentario.link_blog }</u>
                </span>
              </a>
            </p>  
          : null
      }

    </R.CardBody>
  </>
)

export default memo(CardComentario);
