import api from '@/services/api';
import processLogin from './processLogin';

const createLogin = async (props, dados) => {
   const { email, givenName } = dados.googleData;

   const form = {
      nome: givenName,
      sobrenome: "",
      apelido: "",
      email,
      telefone: "",
      password: "",
      login_google: true
   }

   //// CRIAR USUARIO
   await api.post('/add-new-usuario', form);
   
   setTimeout(async () => {
      //// LOGIN
      const user = { email }
      const login = await api.post('/login-google', user);

      const newDados = {
         user: login.data,
         googleData: dados.googleData,
         pageAdmin: dados.pageAdmin,
      }

      //// PROCESSA LOGIN
      processLogin(props, newDados);
   }, 1000)
};

export default createLogin;
