import React from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import "moment/locale/pt-br";

import api from "@/services/api";
import createLogin from "./createLogin";
import processLogin from "./processLogin";

const GoogleButton = (props) => {
  const dispatch = useDispatch();

  const normalizeLoginV2 = (data) => ({
    token: data?.token,
    user: {
      admin: data?.admin,
      apelido: data?.apelido,
      colecao_filtro: data?.configColecaoFiltro,
      config_auto_login: data?.configAutoLogin,
      config_colecao_filtro: data?.configColecaoFiltro,
      config_colecao_tabela: data?.configColecaoTabela,
      config_mail: data?.configMail,
      config_reimpressao: data?.configReimpressao,
      config_show_msg: data?.configShowMsg,
      email: data?.email,
      id: data?.id,
      imgur_link: data?.avatar,
      nome: data?.nome,
      share_id: data?.shareId,
      sobrenome: data?.sobrenome,
    },
  });

  const handleCallbackResponse = async (googleData) => {
    const login = await api.post("v2/login-google", googleData);
    const loginData = normalizeLoginV2(login.data);

    const dados = {
      user: loginData,
      googleData,
      expires: moment(),
    };

    //// CHECK USER CACHE
    await dispatch({ type: "@SET_USER_CACHE", response: dados });

    loginData.user.id === 0
      ? createLogin({ dispatch }, dados)
      : processLogin({ dispatch }, dados);

    dispatch({ type: "@SET_MODAL", response: "@RESET_MODAL" });
  };

  const handleResizeGoogle = () => {
    setTimeout(() => {
      const el = document.getElementById("signInGoogle");
      const iframe = el.querySelector("div iframe");
      iframe.style.height = "60px";
      iframe.style.width = "350px";
    }, 1100);
  };

  // Login v2
  React.useEffect(() => {
    const client_id = import.meta.env.VITE_APP_GOOGLE_ID;

    /* global google */
    /* @ts-ignore */
    google?.accounts?.id?.initialize({
      client_id,
      callback: handleCallbackResponse,
    });

    /* global google */
    /* @ts-ignore */
    google?.accounts?.id?.renderButton(
      document.getElementById("signInGoogle"),
      {
        // theme: "outline",
        theme: "filled_blue",
        size: "large",
      }
    );

    handleResizeGoogle();
  }, []);

  return (
    <div className="w-full px-5">
      <div id="signInGoogle" className="mt-6 mb-1 w-full h-[60px]" />
    </div>
  );
};

export default GoogleButton;
