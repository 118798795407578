const processListaCapasCatalogo = (store_catalogo) => {
  const lista_capas = [];
  
  store_catalogo.forEach(manga => {
    const { id, nome, subtitulo, imgur_link, id_categoria, id_editora, id_faixa_etaria, id_status, 
            id_formato, Editora, Categoria, FaixaEtaria, Status, Formato, Genero, Roteiro_1, 
            Roteiro_2, Arte_1, Arte_2, User_add, User_edt } = manga;

    lista_capas.push({
      id,
      id_volume: 1,
      publicacao: "",
      Volume: {
        id: 1,
        id_manga: id,
        numero: 1,
        preco: "",
        paginas: "",
        publicacao: "",
        imgur_link,
        Manga: {
          id,
          nome,
          subtitulo,
          id_categoria,
          id_editora,
          id_faixa_etaria,
          id_status,
          id_formato,
          Editora, 
          Categoria, 
          FaixaEtaria, 
          Status, 
          Formato, 
          Genero, 
          Roteiro_1, 
          Roteiro_2, 
          Arte_1, 
          Arte_2,
        },
        User_add,
        User_edt,
      }
    });
    
  });
  
  return lista_capas;
};

export default processListaCapasCatalogo;
