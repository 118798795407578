import React, { memo, useState } from 'react';
import * as R from 'reactstrap';
import * as C from '@/components';
import './style.css';

const RenderCard = (props) => {
  const { 
    comentario,
    newInteracao,
    background,
    fadeIn,
  } = props;
 
  const [isEditable, setIsEditable] = useState(false);
  const [textEditable, setTextEditable] = useState(comentario.texto);

  function NewlineText({ text }) {
    const newText = text.split('\n').map(str => <p className="p-0 m-0 mb-1">{str}</p>);
    return newText;
  }

  return (
    <>  
      {
        !comentario.msg 
          ? <>
              <R.Card 
                style={{ background }}
                className={`border-none text-muted mb-3 ${ fadeIn ? `fadeIn`: ``}`}
              >
                <C.CardComentarioHeader 
                  {...props}
                  resposta 
                  comentario={comentario} 
                  openEditar={() => setIsEditable(true)}
                >
                </C.CardComentarioHeader>
                <R.CardBody className="border-none">
                  {
                    isEditable
                      ? <R.Input  
                          type="textarea" 
                          className="resposta-input-area"
                          value={textEditable}
                          onChange={({target}) => setTextEditable(target.value)}
                        />
                      : <NewlineText text={textEditable} />
                  }
                </R.CardBody>
                <C.CardComentarioFooter 
                  {...props} 
                  comentario={comentario}
                  openEditar={isEditable}
                  textEditar={textEditable}
                  closeEditar={() => setIsEditable(false)}
                  newInteracao={ newInteracao ? newInteracao : null } 
                >
                </C.CardComentarioFooter>
              </R.Card>
            </>
          : null
      }
    </>
  )

}

export default memo(RenderCard);
