import React, { memo, useEffect } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { loadAllMangas } from '@/functions';
import './navbar.less';

const Search = (props) => {
  
  useEffect(() => {
    loadAllMangas(props);
    // eslint-disable-next-line
  }, []);

  return (
    <div 
      style={{ height: 65, paddingTop: 18 }}
      className="position-relative cursor"
      onClick={() => props.dispatch({ type:'@SET_MODAL', response: '@MODAL_SEARCH'  })}
    >
      <div className="flex-container flex-justify-space-between">
        <div className="pl-3 pr-3">
          <RiSearchLine 
            size={28} 
            className="text-light mr-1"
          />
          <span className="ml-3 text-muted hide-xs">BUSCA DE MANGÁS</span>
        </div>
      </div>
    </div>
  )
}


export default memo(Search);
