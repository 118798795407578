import React, { memo, useCallback } from 'react';
import { navigateTo } from '@/functions';
import empty from '@/assets/img/avatar.jpg';

const GridEditoras = (props) => {

  const { editoras } = props;

  const Image = useCallback(({height, src, alt, link}) => (
      <img  onClick={() => navigateTo(props, link, 5)}
            style={{ height }} src={src} alt={alt} title={alt}
            loading="lazy" className="cursor mr-5 mb-4"
      />
  ), [props]);

  return (
    <>
      <div className="flex-container flex-justify-left pl-4 pt-3 mb-4">
        <figure style={{ height:100 }}  className="flex-container pl-3 flex-justify-left">
        {
          !!editoras && editoras.map(editora => {
            const { id, nome, imgur_link } = editora;
            return(
              <Image key={`editora-${id}`} height="80%" src={!!imgur_link ? imgur_link : empty}
                     alt={`Editora ${nome}`} link={`/catalogo/${id}/${nome}`} />
            )
          })
        }
        </figure>  
      </div>
    </>
  )
}

export default memo(GridEditoras);
