import React, { memo, useState, useEffect, useCallback } from 'react';
import { UnmountClosed } from 'react-collapse';
import { FaSpinner } from 'react-icons/fa';
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';
import * as F from '@/functions';
import RenderCard from './renderCard';
import './style.css';

const CardComentario = (props) => {
  const { 
    comentario,
    store_interacoes,
  } = props;
 
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalInteracoes, setTotalInteracoes] = useState(comentario.total_interacoes);

  const getInteracoes = useCallback((isOpened) => {
    F.loadInteracoes(props);
    setIsOpened(!isOpened);
    // eslint-disable-next-line
  }, [])

  const newInteracao = useCallback(() => {
    setTotalInteracoes(totalInteracoes + 1);
  }, [totalInteracoes]);

  useEffect(() => {
    if (store_interacoes.length > 0) {
      setTimeout(() => setIsLoading(false), 750); 
    }
  }, [store_interacoes]);

  return (
    <>  
      <RenderCard 
        {...props}
        background={'#0E1520'} 
        comentario={comentario}
        newInteracao={() => newInteracao()} 
      />
      {/*** RESPOSTAS ***/}
      <div style={{ height: 'auto' }} className="pb-2 pr-3 text-right">
           {
            totalInteracoes > 0
              ? isOpened
                ? <div className="noselect cursor" onClick={() => getInteracoes(isOpened)}>
                    <span className="text-muted mr-3 ">Ocultar</span>
                    <IoMdArrowDropup size={28} className="text-orange" />
                  </div>
                : <div className="noselect cursor" onClick={() => getInteracoes(isOpened)}>
                    <span className="text-muted mr-3">Exibir {totalInteracoes} interações</span>
                    <IoMdArrowDropdown size={28} className="text-orange" />
                  </div>
              : null 
           } 
      </div>
      {/*** INTERACOES ***/}
      <UnmountClosed isOpened={isOpened}>
        <div className="respostas-container pb-3">
          {
            isLoading
            ? <>
                <FaSpinner size={22} className="icon-spin mr-2" />
                carregando...
              </>
            : <>
                {
                  store_interacoes.map(item => {
                    return (
                      <RenderCard 
                        {...props}
                        fadeIn 
                        comentario={item} 
                        background={'#101820'} 
                      />
                    )             
                  })
                }
              </>
          }
        </div>
      </UnmountClosed>
    </>
  )
}

export default memo(CardComentario);
