import React, { memo } from 'react';
import { Button } from 'reactstrap';

const Paginas = (props) => {
  ////// PROPS
  const {
    modal,
    colecao,
    comprado,
    addManga,
    delManga,
  } = props; 
  
  const { active: isColecao } = colecao;
  const { capaModal } = modal;

  return(
    <>
      {
        isColecao
          ? <div className="flex-container flex-justify-center">
            {
              comprado
                ? <Button style={ capaModal ? { width: 125 } : { width: 160 }} 
                          className={`btn btn-sm btn-danger ${capaModal ? `ml-2` : ``}`}
                          onClick={delManga}
                  >
                    REMOVER
                  </Button>
                : <Button style={ capaModal ? { width: 125 } : { width: 160 }} 
                          className={`btn btn-sm btn-info ${capaModal ? `ml-2` : ``}`}
                          onClick={addManga}
                  >
                    + COLEÇÃO
                  </Button>
            }
            </div>
        : null
      }
    </>
  )
}

export default memo(Paginas);
