import React, { memo, useEffect, useCallback, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import * as R from 'reactstrap';
import { formatMonthToString } from '@/functions';

const Header = (props) => {
  const { comentario } = props;

  const [starSelected_1, set_starSelected_1] = useState(false);
  const [starSelected_2, set_starSelected_2] = useState(false);
  const [starSelected_3, set_starSelected_3] = useState(false);
  const [starSelected_4, set_starSelected_4] = useState(false);
  const [starSelected_5, set_starSelected_5] = useState(false);

  const Star = useCallback(({ id, selected }) => (
    <FaStar key={`nota-id-${id}`}
            size={20}
            className={selected ? "text-orange" : "text-muted"  }
    />
  ), []); 

  const starSelect = useCallback((id) => {
    switch (id) {
      case 1:
        set_starSelected_1(true);
        set_starSelected_2(false);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 2:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(false);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 3:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(false);
        set_starSelected_5(false);
      break;
      case 4:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(false);
      break;
      case 5:
        set_starSelected_1(true);
        set_starSelected_2(true);
        set_starSelected_3(true);
        set_starSelected_4(true);
        set_starSelected_5(true);
      break;
      default:
    }
  },[]);

  useEffect(() => {
    starSelect(comentario?.nota);
  },[comentario, starSelect]);

  return (         
    <R.CardHeader className="p-0 border-none fadeIn pr-3 flex-container flex-justify-space-between">
      <div className="flex-container flex-justify-space-between">
        
        <div style={{ width: 45, height: 90 }} 
              className={`card-comentario-header-recomendacao ${comentario?.recomendacao ? `bg-info mr-2` : `bg-danger mr-2`}`} >
          {
            comentario?.recomendacao 
              ? <AiFillLike size={28} className='text-white' />
              : <AiFillDislike size={28} className='text-white' />
          }
        </div>
        <p className="h4 pl-3 m t-2 text-info">
          {`Você analisou esse Mangá em ${ comentario?.created_at.substring(8,10)} de 
          ${ formatMonthToString(comentario?.created_at.substring(5,7)) } 
          de ${ comentario?.created_at.substring(0,4) }`}
        </p>
      </div>

      <div className="flex-container">
        <div>
          <p className="m-0 text-right">Sua Nota</p>
          <div>              
            <Star id={1} selected={starSelected_1} />
            <Star id={2} selected={starSelected_2} />
            <Star id={3} selected={starSelected_3} />
            <Star id={4} selected={starSelected_4} />
            <Star id={5} selected={starSelected_5} />
          </div>
        </div>
      </div>
    </R.CardHeader>
  )
}

export default memo(Header);
