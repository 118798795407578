import React, { memo } from 'react';
import * as R from "reactstrap";
import * as C from '@/components';
import * as I from 'react-icons/fa'

const Row = ({ icon, label }) => (
  <R.Col sm={12} className="p-3 pl-5 text-muted flex-container flex-justify-left">
    { icon } 
    <span className="h5 pl-4 m-0">{ label }</span>
  </R.Col>
);

const NextUpdates = () => (
  <>
    <C.TitleWeb titulo="Próximas atualizações" subtitulo="Saiba o que está por vir no portal" />  
    <R.Row className="pl-4 pr-4 m-0 pb-5">
      <Row icon={<I.FaStar size={22} />} label="Sistema de notas" />
      <Row icon={<I.FaBookOpen size={22} />} label="Lista de leituras" />
      <Row icon={<I.FaUserAlt size={22} />} label="Página de Perfil" />
      <Row icon={<I.FaCommentAlt size={22} />} label="Comentários" />
      <Row icon={<I.FaBook size={22} />} label="Coleção pública/privada" />
    </R.Row>
  </>
)

export default memo(NextUpdates);
