import React from 'react';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { AppContainer } from '@/components';
import { loadAllReviews } from '@/functions';

import mapState from './mapstate';

const Comentarios = lazy(() => import('@/components/comentarios'));

const Reviews = (props) => {
  const handleReviews = React.useCallback(() => {
    loadAllReviews(props);
  }, [props]);

  React.useEffect(() => {
    handleReviews();
  }, [handleReviews]);

  return (
    <>
      <AppContainer {...props}>
        <Helmet>
          <title>Reviews - Meus Mangás</title>
        </Helmet>
        <Suspense fallback="">

            <Comentarios {...props} />

        </Suspense>
      </AppContainer>
    </>
  )
}

export default React.memo(connect(() => (mapState))(Reviews));
