import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Row, Col } from "reactstrap";
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import AddAll from '@/components/title/addAll';
import { loadColecaoGroup } from '@/functions';
import mapState from './mapstate';
import './style.css';

const customModal = (props) => {
  const dispatch = useDispatch();

  const { 
    dados,
    isLogin,
    windowSize,
    store_user,
    location,
    match
  } = props;

  const closeModal = () => {
    const locationPathnameArr = location.pathname.split('/');
    const shareId = locationPathnameArr[locationPathnameArr.length-1];
    if ( shareId === store_user?.share_id && match?.path === '/colecao/:share_id') {
      loadColecaoGroup(dispatch, store_user.id);
    }
    dispatch({ type:'@RESET_COMENTARIOS' });
    dispatch({ type:'@SET_MODAL', response: '@RESET_MODAL' });
  };

  const HeaderTitle = () => (
    <>
      <div className="h4">{ dados.title }</div>
      <div className="h6 text-muted mt-2">{ dados.subtitle }</div>
    </>
  );

  const BtnClose = () => (
    <MdClose size={38} className="text-muted cursor" onClick={() => closeModal()} />
  )

  return(
    <>
      <Modal 
        backdrop={false}
        isOpen={dados.isOpen} 
        className="pr-0 custom-modal"
        style={dados.width ? { width: dados.width } : null}
      >
        <Row className="p-0 m-0">
          {
            windowSize.size === 'XS'
              ? <>
                  <Col xs={10} className="pb-2">
                    <HeaderTitle />
                  </Col>
                  <Col xs={2} className="pb-2 text-right">
                    <BtnClose />
                  </Col>
                </>
              : <>
                  <Col xs={12} sm={6} md={7} lg={8} className="pb-2">
                    <HeaderTitle />
                  </Col>
                </> 
          }
          
          <Col sm={5} md={4} lg={3} className="pt-1 pb-1 text-left text-sm-right">
            {
              dados.addAll && isLogin ? 
                  <AddAll addAll={true} {...props} />
              : null
            }
          </Col>

          {
            windowSize.size !== 'XS'              
              ? <Col sm={1} className="pb-2 text-right">
                  <BtnClose />
                </Col>
              : null
          }
        </Row>
        
        <div className="h6 text-muted m-0 p-0 mt-3"
              style={{ maxHeight: dados.height, overflowY: dados.scroll ? 'scroll' : 'hidden', overflowX: 'hidden' }}>
          { dados.body }
        </div>    

        <div onClick={() => closeModal()} className="custom-modal-backdrop" />
      </Modal>
    </>
  )
}

export default memo(connect(() => (mapState))(customModal));
