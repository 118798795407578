const processTipoManga = tipo => {
  switch (parseInt(tipo, 10)) {
    case 1: return 'Mangá'
    case 2: return 'Novel'
    case 3: return 'Databook'
    case 4: return 'Artbook'
    default:
  }
}

export default processTipoManga;