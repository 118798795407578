import React, { memo, useState, useEffect } from 'react';
import { FaSpinner } from "react-icons/fa";
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { CardResposta } from '@/components';
import mapState from './mapstate';

const ModalRespostas = (props) => {
  const { store_respostas } = props;
  const [isLoading, set_isLoading] = useState(true);
  const [isError, set_isError] = useState(true);

  useEffect(() => {
    if (store_respostas.length > 0) {
      store_respostas[0]?.id > 0 
        ? set_isError(false)
        : set_isError(true);

      setTimeout(() => set_isLoading(false), 1000);
    }
  }, [store_respostas])

  return (
    <>
      {
        isLoading
          ? <Col xs={12} className="flex-container flex-justify-center p-4">
              <FaSpinner size={22} className="icon-spin mr-2" />
              carregando...
            </Col> 
          : isError
              ? <Col xs={12} className="text-center h5 text-info">Nada encontrado.</Col>
              : <Col xs={12} className="pl-2 pt-2 pr-3 pb-2">
                  {     
                    store_respostas.map(item => (
                      <CardResposta {...props} comentario={item}/>
                    ))              
                  }
                </Col>   
      }
    </>
  )

}

export default memo(connect( () => (mapState))(ModalRespostas));
