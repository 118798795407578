import React from 'react';
import empty from '@/assets/capas/empty.jpg';
import { processInfoLimit, formatPublicacao, processCardPosition } from '@/functions';
import { CapaContainer } from '@/components';

const processInfoArray = (props) => {
  const _temp = [];
  let counterLinha = 1;
  const totalLinha = processInfoLimit(props.windowSize);
  
  props.store_volumes.forEach(async item => {
    const { id: id_volume, id_manga, imgur_link, Manga, numero, publicacao, paginas, preco, amazon_link, colecao } = item;
    const { id_editora, nome: titulo, subtitulo, original } = Manga;

    let capa;
    !!imgur_link ? capa = imgur_link : capa = empty;

    _temp.push(
      <CapaContainer {...props} 
        key={`capa-container-${id_manga}-${id_volume}-${numero}`}
        dados={{
          titulo,
          subtitulo,
          original,
          numero,
          publicacao: formatPublicacao(publicacao),
          paginas, 
          preco: preco,
          editora: false, 
          status: false,
          id_manga, 
          id_volume,
          id_editora,
          comprar: true,
          amazon_link,
          capa,
        }}
        colecao={{
          active: true,
          total: false,
          comprado: colecao > 0 ? true : false,
        }}
        mouseHover={{
          active: true,
          totalLinha: 0,
          cardPosition: processCardPosition(counterLinha, totalLinha),
          menuBasic: false,
        }}
        modal={{
          active: true,                      
          capaModal: false,
        }}
        noClick={{ 
          active: false 
        }}  
      />
    );        

    counterLinha++;
    if (counterLinha > totalLinha) { counterLinha = 1; }
  });
  
  return _temp;
};

export default processInfoArray;
