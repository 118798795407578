import { AxiosResponse } from 'axios';
import { Props, LoadVolumes } from '@/types';
import api from '@/services/api';
import { ShowMsg } from '@/components';

type response = AxiosResponse<{ id: number; msg: string; }>;

const newColecaoAll = async (props: Props, volumes: LoadVolumes[] = [] ) => { 
  const { dispatch, store_user } = props;
  const {id: id_user, /*token: auth*/} = store_user;

  if(id_user !== 9999) {
    const id_manga = volumes.length ? volumes[0].id_manga : undefined;
  
    if(id_manga) {
      api
        .get(`/add-all-colecao/${id_user}/${id_manga}`)
        .then((result: response) => {
          dispatch({ 
            type: '@PUBLIC_COLECAO_BTN_ALL_ACTION', 
            response: { add: true, remove: false, id_manga }
          });
          ShowMsg(
            'Sucesso!',
            result.data.msg,
            'success',
          );
        })
        .catch(error => { ShowMsg(
          'Ops!',
          error.response.data.error,
          'error',
          3000,
        );
      })
      ;
    }
    /** TODO: é possível dar erro no id_manga ? */ 
  }
  else { dispatch({ type: '@SET_MODAL', response: '@USER_LOGIN' }); }
}

export default newColecaoAll;
