import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TitleWeb, GridCapas, CapaContainer } from '@/components';
import { loadRank, processRankArray } from '@/functions';
import { Props, ISistema, IRank } from '@/types';

const Rank = (props: Props): JSX.Element => {
  const dispatch = useDispatch();

  const windowSize = useSelector(({ sistema }: ISistema) => sistema.windowSize);
  const store_rank = useSelector(({ rank }: IRank) => rank.store_rank);

  const [lista_recents, set_lista_recents] = useState<typeof CapaContainer[]>([]);

  /// FIRST LOAD
  useEffect(() => {
    loadRank({ dispatch }); 
    // eslint-disable-next-line
  }, [dispatch]);

  /// RANK
  useEffect(() => { 
    set_lista_recents(processRankArray({ windowSize, store_rank, props }));
    // eslint-disable-next-line
  },[store_rank, windowSize, props]);

  return (
    <>
      <TitleWeb
        {...props}
        titulo="Rank"
        subtitulo="Os mais votados"
      />
      <GridCapas 
        {...props} 
        customKey="rank"
        capas={lista_recents}
      />
    </>
  )
}

export default memo(Rank);
