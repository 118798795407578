import React, { memo } from 'react';
import Pix from '@/assets/img/PIX_CODE.jpg';

const ModalDonation = () => (
  <div className="text-center pb-4 pt-1">
    <img 
      src={Pix} 
      alt="Logo Meus Mangás" 
      width={300} 
    />

    <p className="h5 mt-5">
      O Meus Mangás é <span className="text-orange">totalmente gratuito</span>, mas existem custos 
      para manter o servidor e a programação de novos recursos.
    </p>

    <p className="h5">Se você puder, faça uma doação para o nosso trabalho continuar com excelência.</p>
  </div>
)

export default memo(ModalDonation);
