import api from '@/services/api';
import { ShowMsg } from '@/components';
import { saveCache } from '@/functions';

const editUserSettings = async (props, settings) => {
  const auth = props.store_user.token;
  
  const {
    config_reimpressao,
    config_show_msg,
    config_auto_login,
    config_mail,
    config_colecao_tabela,
    config_colecao_filtro,
    colecao_filtro,
  } = settings;

  /// SAVE CACHE
  const { user: cache_user } = props.store_cache;
  const { token, user } = cache_user;

  const new_user = {
    ...user,
    config_reimpressao,
    config_show_msg,
    config_auto_login,
    config_mail,
    config_colecao_tabela,
    config_colecao_filtro,
    colecao_filtro,
  }

  const new_cache = {
    ...props.store_cache,
    user: {
      token,
      user: new_user,
    }
  }

  saveCache(config_auto_login, new_cache);

  /// DISPATCH 
  const formUser = {
    ...props.store_user,
    config_reimpressao,
    config_show_msg,
    config_auto_login,
    config_mail,
    config_colecao_tabela,
    config_colecao_filtro,
    colecao_filtro,
  }

  props.dispatch({ type: '@SET_USER', response: formUser });

  /// EDIT USER
  await api.put('/edt-usuario', settings, auth);  
  ShowMsg(
    'Settings',
    'Configurações atualizadas com sucesso.',
    'success',
    1500,
    true,
  );
}

export default editUserSettings;
