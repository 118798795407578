import React, { memo, useEffect, useState, useCallback, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TitleWeb, AmazonContainer } from "@/components";
import * as R from "reactstrap";
import { loadAmazonOffers, processAmazonLimit } from "@/functions";
import { FaSpinner } from "react-icons/fa";
import "./style.css";

const OfertasAmazon = (props) => {
  const { match } = props;

  const dispatch = useDispatch();
  const windowSize = useSelector((state) => state.sistema.windowSize);
  const store_volumes = useSelector((state) => state.manga.store_volumes);
  const store_amazon_offers = useSelector(
    (state) => state.amazon.store_amazon_offers
  );

  const page = match?.path.replace("/", "");
  const [isLoad, set_isLoad] = useState(false);
  const [limit, set_limit] = useState(0);
  const [amazon, set_amazon] = useState([]);

  const [searchUrl, set_searchUrl] = useState(0);
  const [totalItens, set_totalItens] = useState(0);
  const [isLoading, set_isLoading] = useState(true);

  const getOffers = useCallback(() => {
    let key = "";
    let editora = "";

    switch (page) {
      /// HOME
      case "":
        key = "Mangás Panini JBC";
        break;
      /// INFO
      case "info/:id_manga/:titulo":
        if (store_volumes.length === 0) {
          return;
        }

        const { Manga } = store_volumes[0];
        const { nome, Editora } = Manga;

        key = nome;
        editora = Editora.nome;

        //set_editora(Editora.nome);
        //getOffers(nome, Editora.nome);

        break;
      default:
    }

    const form = {
      Keywords: key,
      Editora: editora,
      Filtro: "Books",
      Sort: "Relevance",
      Page: 1,
    };

    dispatch({ type: "@RESET_AMAZON_OFFERS" });
    loadAmazonOffers(dispatch, form);
  }, [dispatch, page, store_volumes]);

  const resetLoading = useCallback(() => {
    set_isLoading(false);
    getOffers();
  }, [getOffers]);

  const renderOffers = useCallback((store_amazon_offers) => {
    set_isLoading(false);
    set_searchUrl(store_amazon_offers.url);
    set_totalItens(store_amazon_offers.count);
    set_amazon(store_amazon_offers);
    setTimeout(() => set_isLoad(true), 250);
  }, []);

  const handleComprarAmazon = useCallback((link, descricao) => {
    // const data = {
    //   plataforma: 1,
    //   location: location.pathname,
    //   id_manga: 0,
    //   descricao,
    //   id_sessao,
    //   id_user: store_user.id,
    // }

    // F.getLogCompras(data);
    window.open(link, "_blank");
  }, []);

  ////// LOADING OFFERS
  useEffect(() => {
    // const cacheAmazon = JSON.parse(localStorage.getItem("AMAZON_OFFERS"));
    // !cacheAmazon ? getOffers() : renderOffers(cacheAmazon);
    getOffers();
  }, []);

  ////// LIMIT OFFERS
  useEffect(() => {
    set_limit(processAmazonLimit(windowSize));
  }, [windowSize]);

  ////// UPDATE STATE
  useEffect(() => {
    ////// ERROR
    if (store_amazon_offers.Code === "NoResults") {
      getOffers();
    }

    ////// SUCCESS
    if (store_amazon_offers.count) {
      renderOffers(store_amazon_offers);
    }
  }, [store_amazon_offers, getOffers, renderOffers]);

  ////// TIMEOUT
  useEffect(() => {
    if (isLoading) {
      const timerId = setInterval(() => resetLoading(), 6000);
      return () => clearInterval(timerId);
    }
    // eslint-disable-next-line
  }, [isLoading, resetLoading]);

  return (
    <>
      <div className="pt-3">
        <TitleWeb
          {...props}
          titulo="Ofertas Amazon"
          subtitulo="Promoções recomendadas para você"
        />
        <R.Row className="pl-3 pr-3 m-0">
          <R.Col
            xs={12}
            style={{ height: 310 }}
            className={`pl-4 flex-container ${
              amazon.itens ? `overflow-hidden` : `flex-justify-center`
            }`}
          >
            {amazon.itens ? (
              // eslint-disable-next-line
              amazon?.itens?.map((item, index) => {
                if (index < limit) {
                  return (
                    <Fragment key={item?.ASIN}>
                      <AmazonContainer
                        ASIN={item?.ASIN}
                        onClick={() =>
                          handleComprarAmazon(
                            item?.DetailPageURL,
                            item?.ItemInfo.Title.DisplayValue
                          )
                        }
                        isLoad={isLoad}
                        SRC={item?.Images.Primary.Medium.URL}
                        PriceDisplayAmount={
                          item?.Offers?.Listings[0]?.Price.DisplayAmount
                        }
                        SavingsPercentage={
                          item?.Offers?.Listings[0]?.Price.Savings?.Percentage
                        }
                        TitleDisplayValue={item?.ItemInfo.Title.DisplayValue}
                      />
                    </Fragment>
                  );
                }
              })
            ) : (
              <span className="h6 text-muted">
                <FaSpinner size={22} className="icon-spin mr-2" />
                carregando...
              </span>
            )}
          </R.Col>

          <R.Col
            xs={12}
            style={{ height: 30 }}
            className="p-0 m-0 pt-3 text-link cursor text-right"
          >
            {amazon.itens ? (
              <span
                className="cursor tag-ofertas-amazon"
                onClick={() => window.open(searchUrl, "_blank")}
              >
                {totalItens} OFERTAS ENCONTRADAS
              </span>
            ) : null}
          </R.Col>
        </R.Row>
      </div>
    </>
  );
};

export default memo(OfertasAmazon);
