import React, { memo, useEffect, useState, useCallback } from 'react';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import * as R from 'reactstrap';
// import * as C from 'components';
import * as F from '@/functions';

const Buttons = (props) => {
  const { comentario, store_user, clickResponder, isOpened, isAbleToResponse } = props;
  const { user_like, user_dislike } = comentario;
  const RCI = F.checkRCI(comentario);

  const [flagLike, set_flagLike] = useState(false);
  const [flagDisLike, set_flagDisLike] = useState(false);
  const [likes, set_likes] = useState(comentario.like);
  const [dislikes, set_dislikes] = useState(comentario.dislike);

  useEffect(() => {
    if (user_like) { set_flagLike(true) }
    if (user_dislike) { set_flagDisLike(true) }
  }, [user_like, user_dislike]);

  const ButtonResponder = useCallback(({onClick}) => (
    <R.Button style={{ width: 120 }}
        className={`btn btn-sm border-none btn-info mr-3 ${isOpened ? 'btn-muted' : 'btn-info'}`}
        onClick={onClick}
    >
      {
        isOpened ? 'FECHAR' : 'RESPONDER' 
      }
    </R.Button>
  ), [isOpened]);
  
  const ButtonLike = useCallback(({ onClick }) => (
    <div className="cursor flex-container flex-justify-center noselect mr-2" onClick={onClick} >
      <AiFillLike size={24} className={`mr-2 ${ flagLike ? `text-info` : ``}`} />
      <span className={`h6 p-0 m-0 ${ flagLike ? `text-info` : ``}`}>
        { likes }
      </span>
    </div>
  ), [likes, flagLike]);
  
  const ButtonDislike = useCallback(({ onClick }) => (
    <div className="cursor flex-container flex-justify-center noselect ml-2" onClick={onClick} >
      <AiFillDislike size={24} className={`mr-2 ${ flagDisLike ? `text-info` : ``}`} />
      <span className={`h6 p-0 m-0 ${ flagDisLike ? `text-info` : ``}`}>
        { dislikes }
      </span>
    </div>
  ), [dislikes, flagDisLike]);

  const handleLike = useCallback((action) => {   
    var like = likes;
    var dislike = dislikes;
    
    if(action === 'like' && !flagLike) {
      like++;
      set_likes(likes + 1)
      set_flagLike(true)
      set_flagDisLike(false)
      if (flagDisLike) { 
        set_dislikes(dislikes - 1);
        dislike--;
      }
    }

    if(action === 'dislikes' && !flagDisLike) {
      dislike++;
      set_dislikes(dislikes + 1)
      set_flagLike(false)
      set_flagDisLike(true)
      if (flagLike) { 
        set_likes(likes - 1);
        like--;
      }
    }

    const LK = {
      id: comentario.id,
      like,
      dislike,
      id_user: store_user.id,
    }

    switch (RCI) {
      case 'REVIEW': F.newLikeDislikeReview(props, LK); break;
      case 'COMENTARIO': F.newLikeDislikeResposta(props, LK); break;
      case 'INTERACAO': F.newLikeDislikeInteracao(props, LK); break;
      default:
    }
    // eslint-disable-next-line
  },[flagLike, flagDisLike, likes, dislikes, comentario, RCI])

  return (
    <>
      <div className="text-muted flex-container">
        {
          isAbleToResponse
            ? <ButtonResponder onClick={clickResponder} /> 
            : null
        }
        <ButtonLike onClick={() => handleLike('like')} /> 
        
        <ButtonDislike onClick={() => handleLike('dislikes')} /> 
      </div>
    </>
  )

}

export default memo(Buttons);
