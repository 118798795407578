import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as C from '@/components';
import * as F from '@/functions';
import mapState from './mapstate';

const Recomendacoes = (props) => {
  const {
    windowSize,
    store_volumes,
    store_recomendacoes,
  } = props;

  const [lista_capas, set_lista_capas] = useState([]);

  useEffect(() => {
    F.loadRecomendacoes(props, store_volumes);
    // eslint-disable-next-line
  },[store_volumes])

  useEffect(() => {
    set_lista_capas(F.processRecomendacoesArray(props)); 
    // eslint-disable-next-line
  },[store_recomendacoes, windowSize])

  return (
    <>
      <C.TitleWeb {...props} titulo="Recomendações" subtitulo="Sugestões de mangás para você" />

      <C.GridCapas 
        {...props}
        height={350} 
        capas={lista_capas} 
        customKey="recomendacoes" 
      />
    </>
  )
}

export default memo(connect(() => (mapState))(Recomendacoes));
