import { SistemaStore, ReducerType } from '@/types';

export const INITIAL_STATE: SistemaStore = {  
  loading: true,
  switchChecked: false,
  
  colecao_action: { add: false, remove: false, id_volume: 0 },
  colecao_btnAll_action: { 
    add: false, 
    remove: false, 
    id_manga: 0,
    quantidade: 0,
    colecao_edicao: false,
  },
  
  openModal: '@RESET_MODAL',

  cardHover_ID: 0,
  windowSize: { size: '', width: 0, height: 0, },
}
 
export default function sidebar_reducer(state = INITIAL_STATE, action: ReducerType) {
  switch(action.type) {  
    case '@PUBLIC_LOADING_TRUE':  return { ...state,  loading: true };
    case '@PUBLIC_LOADING_FALSE': return { ...state,  loading: false };

    case '@PUBLIC_COLECAO_ACTION': return { ...state, colecao_action: action.response };
    case '@PUBLIC_COLECAO_BTN_ALL_ACTION': return { 
      ...state, 
      colecao_btnAll_action: { ...state.colecao_btnAll_action, ...action.response } 
    };

    case '@PUBLIC_SWITCH_CHECKED_TRUE':  return { ...state,  switchChecked: true };
    case '@PUBLIC_SWITCH_CHECKED_FALSE': return { ...state,  switchChecked: false };

    case '@SET_MODAL':  return { ...state,  openModal: action.response };    

    case '@SET_PUBLIC_CARDHOVER_ID': return { ...state,  cardHover_ID: action.payload };

    case '@SET_PUBLIC_WINDOW_SIZE': return { ...state,  windowSize: action.payload };
  
    default: return state;
  }
}
