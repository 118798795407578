import React, { memo, useCallback } from 'react';
import * as IO from "react-icons/io";
import * as RI from "react-icons/ri";
import * as FA from "react-icons/fa";
import * as BI from "react-icons/bi";
// import * as AI from "react-icons/ai";
// import * as MD from "react-icons/md";
import RenderLI from './renderLI';
import { processAmazonLink } from '@/functions';

const RenderInfo = ({ dados, history, dispatch, store_user }) => {
  
  const handleComprarAmazon = useCallback((link) => {
    // const data = {
    //   plataforma: 1,
    //   location: location.pathname,
    //   id_manga: dados.id_manga,
    //   descricao: dados.titulo,
    //   id_sessao,
    //   id_user: store_user.id,
    // }
    // getLogCompras(data);
    window.open(link, '_blank');
  }, []);
  
  const handleRedirect = useCallback((link) => {
    dispatch({ type: '@PUBLIC_LOADING_TRUE' });
    history.push(`${link}/comentarios`);
  }, [dispatch, history])

  return (
    <div className="public-capa-hover-info">

      <ul className="text-muted">

        {
          !dados.menuBasic && store_user.id !== 9999
            ? dados.flag_comprado 
                ? <RenderLI 
                    text="Remover da coleção"
                    onClick={dados.delManga}
                    icon={<FA.FaMinus className="mr-3 text-info" size={dados.iconSize} />} 
                  />
                : <RenderLI 
                    text="Adicionar na coleção"
                    onClick={dados.addManga}
                    icon={<FA.FaPlus className="mr-3 text-info" size={dados.iconSize} />} 
                  />
            : null
        }

        {/*
          !dados.menuBasic
            ? dados.flag_lido 
              ? <RenderLI
                  text="Marcar como não lido"
                  onClick={dados.handleLido}
                  icon={<FA.FaEyeSlash className="mr-3 text-info" size={dados.iconSize} />} 
                />
              : <RenderLI
                  text="Marcar como lido"
                  onClick={dados.handleLido}
                  icon={<FA.FaEye className="mr-3 text-info" size={dados.iconSize} />} 
                />
            : null
        */}

        {/*
          !dados.menuBasic
            ? dados.flag_compras 
              ? <RenderLI
                  text="Remover Compras"
                  onClick={dados.handleCompras}
                  icon={<MD.MdRemoveShoppingCart className="mr-3 text-info" size={dados.iconSize} />} 
                />
              : <RenderLI
                  text="Adicionar Compras"
                  onClick={dados.handleCompras}
                  icon={<MD.MdShoppingCart className="mr-3 text-info" size={dados.iconSize} />} 
                />
          : null
        */}
        
        {/*
          !dados.menuBasic
            ? dados.flag_favorito 
              ? <RenderLI
                  text="Remover favorito"
                  onClick={dados.handleFavorito}
                  icon={<AI.AiOutlineHeart className="mr-3 text-danger" size={dados.iconSize} />} 
                />
              : <RenderLI
                  text="Marcar favorito"
                  onClick={dados.handleFavorito}
                  icon={<AI.AiFillHeart className="mr-3 text-danger" size={dados.iconSize} />} 
                />
          : null
        */}
        
        <RenderLI
          text="Comprar"
          onClick={() => handleComprarAmazon(processAmazonLink(dados))}
          icon={<RI.RiAmazonFill className="mr-3 text-orange" size={dados.iconSize} />} 
        />
            
        <RenderLI
          text="Comentários / Reviews"
          onClick={() => handleRedirect(dados.url_info)}
          icon={<IO.IoIosChatboxes className="mr-3 text-white" size={dados.iconSize} />} 
        />
        
        {
          store_user.id !== 9999 ?
          <RenderLI
              text="Ver coleção"
              onClick={ !dados.noClick ? (dados.modal ? dados.link_modal : dados.link_info) : null }
              icon={<BI.BiBookAlt className="mr-3 text-white" size={dados.iconSize} />} 
            />
          : null
        }
        
        <RenderLI
          text="Informações adicionais"
          onClick={() => window.open(dados.url_info, '_blank')}
          icon={<IO.IoIosShareAlt className="mr-3 text-white" size={dados.iconSize} />} 
        />

      </ul>
    </div>
  )
}

export default memo(RenderInfo);
