import api from '@/services/api';
import { ShowMsg } from '@/components';

const newDrop = async (props, checked) => {
  const {
    store_colecao_modal,
    store_user,
  } = props;

  const {
    id_drop,
    id_manga,
  } = store_colecao_modal;

  const form = {
    id: id_drop,
    id_manga,
    id_user: store_user.id,
    drop: checked,
  }

  const auth = store_user.token;
  await api.post('/drop-colecao', form, auth)
            .then( null, error => ShowMsg({
              title: 'Ops!',
              message: error.response.data.error,
              type: 'error',
              tempo: 3000,
            }));
}

export default newDrop;
