import React from 'react';
import empty from '@/assets/capas/empty.jpg';
import { processMangasLimit, processCardPosition } from '@/functions';
import { CapaContainer } from '@/components';
import { LoadAllMangasRecents } from '@/types';
import { TProcessRecentesArray } from './processRecentesArray.type';

const processRecentesArray = ({ 
  store_all_mangas_recents, 
  windowSize,
  props
}: TProcessRecentesArray): typeof CapaContainer[] => {
  
  const _temp: typeof CapaContainer[] = [];
  let counter: number = 0;
  const limit: number = processMangasLimit(windowSize);

  store_all_mangas_recents.forEach(async (item: LoadAllMangasRecents) => {
   
    const { id: id_volume, id_manga, Manga, imgur_link } = item;
    const { nome: titulo, subtitulo, original, id_editora } = Manga;

    if(counter < limit) {
      _temp.push(
        <CapaContainer { ...props }
          key={`capa-container-${id_manga}-${id_volume}`}
          dados={{
            titulo,
            subtitulo,
            original,
            numero: false,
            publicacao: false,
            paginas: false, 
            preco: false,
            editora: false, 
            status: false,
            id_manga, 
            id_volume: 0,
            id_editora,
            comprar: false,
            amazon_link: '',
            capa:!!imgur_link ? imgur_link : empty,
          }}
          colecao={{
            active: false,
            total: 0,
          }}
          mouseHover={{
            active: true,
            totalLinha: 0,
            cardPosition: processCardPosition(counter + 1, limit),
            menuBasic: true,
          }}
          modal={{
            active: true,
            capaModal: false,
          }}
          noClick={{ 
            active: false 
          }}
        />
      );     
    }   
    counter++;  
  });

  return _temp;
};

export default processRecentesArray;
