import React, { memo, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as R from 'reactstrap';
import * as C from '@/components';
import './style.css';
import mapState from './mapstate';

const Comentarios = (props) => {
  const { store_review, match } = props;

  const [isLoading, set_isLoading] = useState(true);
  const [coluna01, set_coluna01] = useState([]);
  const [coluna02, set_coluna02] = useState([]);
  
  const RenderComentarios = useCallback(() => (
    <R.Row className="p-3">
      <R.Col className="comentarios-column-left" xs={12} lg={6}>
        { coluna01 }
      </R.Col>
      <R.Col className="comentarios-column-right" xs={12} lg={6}>
        { coluna02 }
      </R.Col>
    </R.Row>
  ), [coluna01, coluna02]);

  /// RENDER REVIEW
  useEffect(() => {
    if (store_review.length > 0) {
      const _coluna01 = [];
      const _coluna02 = [];

      store_review.forEach((item, index) => {
        if (! item.msg) {
          index % 2 === 0
          ? _coluna01.push(<C.CardComentario {...props} key={item.id} comentario={item} />)
          : _coluna02.push(<C.CardComentario {...props} key={item.id} comentario={item} />)
        }
      })
  
      set_coluna01(_coluna01);
      set_coluna02(_coluna02);
      
      set_isLoading(false);
    }
    // eslint-disable-next-line
  }, [store_review]) ;

  useEffect(() => {
    if (match.params.comentarios) {
      setTimeout(() => {
        const container = document.getElementById('review-comentarios');
        container.scrollIntoView({ behavior :'smooth'});
      }, 300);
    }
  },[match])

  return (
    <>
      <C.TitleWeb 
        id="review-comentarios" {...props} 
        titulo='Reviews e Comentários' 
        subtitulo={''} height={45} 
        url='/reviews'
      />
      {
        isLoading 
          ? <> Carregando... </>
          : <RenderComentarios />
      }
    </>
  )
}

export default memo(connect(() => (mapState))(Comentarios));
