import Kaneda from "@/assets/img/kaneda-ytb.png";
import "./style.css";

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="w-full h-[290px] md:w-[808px] md:h-auto p-3">
        <iframe
          className="md:w-[500px] md:h-[280px]"
          src={`https://www.youtube.com/embed/Wzn9_Vwnz50`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <img src={Kaneda} alt="" className="hidden md:block" />
    </div>
  );
};

export default Banner;
