import React, { memo } from 'react';
import * as R from 'reactstrap';
import './style.less';
import './responsive.css';
import Header from './header';
import Body from './body';
import Footer from './footer';

const ReviewCard = (props) => (
  <R.Row className="pl-1 pr-1 mb-4">
    <R.Col className="pl-5 pr-5">

      <R.Card className="border-none fadeIn text-muted" style={{ maxWidth: 900, background: '#101820' }}>
        <Header {...props} />
        <Body {...props} />
        <Footer {...props} />
      </R.Card>

    </R.Col>
  </R.Row>
)

export default memo(ReviewCard);
