import api from '@/services/api';
import { ShowMsg } from '@/components';
import { loadInteracoes, loadRespostas } from '@/functions';

const deleteInteracao = async (props, id) => {
  const { store_user, id_review } = props;
  const auth = store_user.token;

  const form = {
    id,
    id_user_edt: store_user.id,
  }

  await api.post('/del-interacao/', form, auth);
  await loadRespostas(props, id_review);
  await loadInteracoes(props);
  ShowMsg(
    'Sucesso!',
    'Interação deletado com sucesso.',
    'info',
    2500,
  );   
}

export default deleteInteracao;
