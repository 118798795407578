import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GridUsuarios, TitleWeb } from '@/components';
import { loadAllUsers, processUsersLimit } from '@/functions';
import { Props, ISistema, IManga, IAuth } from '@/types';

const Usuario = (props: Props): JSX.Element => {
  const dispatch = useDispatch();

  const windowSize = useSelector(({ sistema }: ISistema) => sistema.windowSize);
  const store_user = useSelector(({ auth }: IAuth) => auth.store_user);
  const store_usuarios = useSelector(({ manga }: IManga) => manga.store_usuarios);
  
  const [limit_users, set_limit_users] = useState<number>(10);

  /// FIRST LOAD
  useEffect(() => {
    loadAllUsers({ store_user, dispatch }); 
    // eslint-disable-next-line
  }, []);

  /// LIMIT USER
  useEffect(() => { 
    set_limit_users(processUsersLimit(windowSize));
  },[windowSize]); 

  return (
    <>
        <TitleWeb
          {...props}
          titulo="usuários"
          subtitulo="Cadastrados recentemente" 
        />
        <GridUsuarios 
          {...props} 
          users={store_usuarios} 
          limit={limit_users} 
        />
    </>
  )
}

export default memo(Usuario);
