  const processAmazonLimit = (windowSize) => {
    let limit = 1   
    if(windowSize.width >= 350)  {limit = 2}
    if(windowSize.width >= 440)  {limit = 3}
    if(windowSize.width >= 600)  {limit = 4}
    if(windowSize.width >= 720)  {limit = 5}
    if(windowSize.width >= 910)  {limit = 6}
    if(windowSize.width >= 1110) {limit = 7}
    if(windowSize.width >= 1335) {limit = 8}
    if(windowSize.width >= 1485) {limit = 9}
    if(windowSize.width >= 1640) {limit = 10}
    return limit;
  };

  export default processAmazonLimit;
