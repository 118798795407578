import { loadRespostas } from '@/functions';

const modalRespostas = async (props) => {
  const { dispatch, comentario } = props;
  dispatch({ type: '@RESET_VOLUMES_MODAL' });
  dispatch({ type: '@SET_ID_REVIEW', payload: comentario.id });
  dispatch({ type: '@SET_MODAL', response: '@MODAL_RESPOSTAS' });

  await loadRespostas(props, comentario.id);
}

export default modalRespostas;
