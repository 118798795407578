import React, { memo } from 'react';
import { GridCapas } from '@/components';
import store from '@/store';

const ModalListaVolumes = (props) => (
  <>
    <GridCapas {...props} capas={store.getState().manga.store_volumes_modal_elements}  customKey="modal-colecao" />           
  </> 
)

export default memo(ModalListaVolumes);
