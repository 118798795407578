import { AuthStore, ReducerType } from '@/types';
import * as i from './initials'

const INITIAL_STATE: AuthStore = {
  store_user: i.INITIAL_STORE_USER,
  store_colecao_user: i.INITIAL_COLECAO_USER,
  store_cache: i.INITIAL_STORE_CACHE,
  isLogin: false,
  isFirstLoad: true,
  id_sessao: 0,
}

export default function auth_reducer(state = INITIAL_STATE, action: ReducerType) {
  switch(action.type) {  
    case '@SET_LOGIN_TRUE':  return { ...state,  isLogin: true };
    case '@SET_LOGIN_FALSE':  return { ...state,  isLogin: false }; 

    case '@SET_FIRSTLOAD_TRUE':  return { ...state,  isFirstLoad: true };
    case '@SET_FIRSTLOAD_FALSE':  return { ...state,  isFirstLoad: false };
    case '@SET_ID_SESSAO':  return { ...state,  id_sessao: action.response };

    case '@SET_USER':  return { ...state,  store_user: action.response };
    case '@SET_USER_CACHE':  return { ...state,  store_cache: action.response };

    case '@SET_COLECAO_USER':  return { ...state,  store_colecao_user: action.response };
    case '@RESET_COLECAO_USER':  return { ...state,  store_colecao_user: i.INITIAL_COLECAO_USER };
   
    case '@RESET_USER':  return state = INITIAL_STATE;
    default: return state;
  }
}
