import api from '../../../services/api';

const loadAmazonOffers = async (dispatch, form) => {
  dispatch({ type: '@RESET_AMAZON_OFFERS' });
  const response = await api.post('/amazon-offers', form);
  const lista = response.data;
  // localStorage.setItem('AMAZON_OFFERS', JSON.stringify(lista))
  dispatch({ type: '@SET_AMAZON_OFFERS', response: lista });
}

export default loadAmazonOffers;