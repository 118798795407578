import api from '../../../services/api';
import { TDispatch } from '@/types';

type TLoadAllUsers = {
   store_user: any,
   dispatch: (p: TDispatch) => void
}

const loadAllUsers = async ({ store_user, dispatch }: TLoadAllUsers): Promise<void> => {
   const auth = store_user.token;
   const response = await api.post('/list-usuarios', auth);  
   dispatch({ type: '@SET_USUARIOS', response: response.data }) 
}

export default loadAllUsers;