import React, { memo, useState, useCallback, useEffect } from 'react';
import { UnmountClosed } from 'react-collapse';
import * as R from 'reactstrap';
import * as C from '@/components';
import * as F from '@/functions';
import Buttons from './buttons';
import './style.css';
import Avatar from '@/assets/img/avatar.jpg';

const CardComentarioFooter = (props) => {
  const {
    dispatch,
    comentario,
    resposta,
    store_user,
    openEditar,
    isLogin,
    newInteracao,
    textEditar,
    closeEditar,
  } = props;

  const [isEditable, setIsEditable] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [text_user, setText_user] = useState('');
  const [totalRespostas, setTotalRespostas] = useState(comentario.total_respostas);

  const sendPublicar = useCallback(() => {
    setText_user('');
    setIsOpened(false);
    setTotalRespostas(totalRespostas + 1);
    F.newResposta(props, comentario, text_user);
  }, [props, comentario, text_user, totalRespostas]);

  const senEditar = useCallback(() => {
    F.editResposta(props, comentario, textEditar);
    closeEditar();
  }, [props, comentario, textEditar, closeEditar]);

  const handleIsAbletoResponse = useCallback(() => {
    
    let ableToResponse;
    
    comentario.id_resposta === undefined 
      ? ableToResponse = true 
      : ableToResponse = false;

    if (comentario.delete_flag) {
      ableToResponse = false;
    }

    return ableToResponse;
  }, [comentario])

  const handleIsEditable = useCallback(() => {
    setIsEditable(false);
    closeEditar();
    dispatch({ type: '@EDITAR_RESPOSTA_ID', payload: 0 });
    dispatch({ type: '@FLAG_EDITAR_RESPOSTA_FALSE' });
  }, [dispatch, closeEditar])

  useEffect(() => {
    setIsEditable(openEditar)
  }, [openEditar])

  return (
    <>
      <R.CardFooter className="border-none pt-0">
        <R.Row style={{ paddingLeft: 12 }} className="pr-3 pb-2">
          {/*** BUTTONS ***/}
          { isEditable 
            ? 
              <>
                  <R.Col id="001" xs={12} className="text-info flex-container flex-justify-right p-0">
                    <R.Button style={{ width: 120 }}
                        className="btn btn-sm border-none btn-muted mr-3"
                        onClick={() => handleIsEditable()}          
                    >
                      FECHAR
                    </R.Button>
                    <R.Button style={{ width: 120 }}
                        className="btn btn-sm border-none btn-success"
                        onClick={() => senEditar()}
                    >
                      PUBLICAR
                    </R.Button>
                  </R.Col>
              </>
            :
              <>
                <R.Col xs={12} className="text-info flex-container flex-justify-space-between p-1">
                  <Buttons {...props} 
                    isOpened={isOpened} 
                    clickResponder={() => isLogin ? setIsOpened(!isOpened) : null } 
                    isAbleToResponse={handleIsAbletoResponse()}
                  />
                  {
                    resposta
                      ? <u className="h6 cursor noselect" 
                          onClick={ totalRespostas > 0 ? () => F.modalRespostas(props) : null }>
                          { totalRespostas } RESPOSTAS
                        </u>
                      : null
                  }
                </R.Col>
              </>
            }
        </R.Row>
      </R.CardFooter>
      <UnmountClosed isOpened={isOpened}>
        <div className="fadeIn resposta-container p-3">
          
          <C.AvatarContainer src={ isLogin ? store_user.imagem : Avatar }/>

          <R.Input
            className="resposta-input mr-3"
            type="textarea"   
            value={text_user}
            onChange={({target}) => setText_user(target.value)}
          />

          <R.Button // style={{ width: 120 }}              
              className="btn btn-sm border-none btn-info"
              onClick={() => sendPublicar()}
              onClickCapture={newInteracao}
          >
            PUBLICAR
          </R.Button>
          
        </div>
      </UnmountClosed>
    </>
  )

}

export default memo(CardComentarioFooter);
