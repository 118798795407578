import React from 'react';
import empty from '@/assets/capas/empty.jpg';
import { CapaContainer } from '@/components';
import { processCardPosition } from '@/functions';

const processCheckListArray = (props, dados) => { 
  const _temp = [];
  let counter = 0;
  let counterLinha = 1;

  const totalLinha = dados.mouseHover.totalLinha;
  const {
    checklistLista,
    limit,
  } = dados.checklist;

  if (checklistLista === undefined || checklistLista.length === 0 || checklistLista[0].id === 0) {
    return []
  }

  checklistLista.forEach(item => {
    const { Volume, id_volume } = item;
    const { numero, imgur_link, amazon_link, id_manga, Manga, preco, paginas, publicacao, colecao, reimpressao_flag } = Volume;
    const { nome: titulo, subtitulo, original, Editora, Status, id_editora } = Manga;

    let exibeNumero;
    dados.visibility.numero
      ? exibeNumero = numero
      : exibeNumero = false;

    let exibePublicacao;
    dados.visibility.publicacao
      ? exibePublicacao = publicacao
      : exibePublicacao = false;

    let exibePaginas;
    dados.visibility.pagina_preco
      ? exibePaginas = paginas
      : exibePaginas = false;

    let exibePreco;
    dados.visibility.pagina_preco
      ? exibePreco = preco
      : exibePreco = false;

    let capa;
    !!imgur_link ? capa = imgur_link : capa = empty;

    if (counter < limit || limit === 0) {
        _temp.push( <CapaContainer {...props} 
                      key={`capa-container-${id_manga}-${id_volume}`}
                      dados={{
                        titulo,
                        subtitulo,
                        original,
                        numero: exibeNumero,
                        publicacao: exibePublicacao,
                        paginas: exibePaginas, 
                        preco: exibePreco,
                        editora: Editora.nome, 
                        status: Status.nome,
                        id_manga, 
                        id_volume,
                        id_editora,
                        comprar: dados.visibility.comprar,
                        amazon_link,
                        capa,
                        reimpressao_flag,
                      }}
                      colecao={{
                        active: dados.colecao.active,
                        comprado: colecao > 0 ? true : false,
                        total: dados.colecao.total,
                      }}
                      mouseHover={{
                        active: dados.mouseHover.active,
                        totalLinha: dados.mouseHover.totalLinha,
                        menuBasic: dados.mouseHover.menuBasic,                      
                        cardPosition: processCardPosition(counterLinha, totalLinha),
                      }}
                      modal={dados.modal}
                      noClick={dados.noClick}
                    />);
    }
      
    counter++;
    counterLinha++;
    if (counterLinha > totalLinha) { counterLinha = 1; }
  });

  return _temp;
};

export default processCheckListArray;
