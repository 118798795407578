import React from 'react';
import ReactDOM from "react-dom/client";
import { App } from './pages';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

if (container.hasChildNodes()) {
  root.hydrate(<App />);
} else {
  root.render(<App />);
}
