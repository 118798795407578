import { MangaStore, ReducerType } from '@/types';

export const INITIAL_STATE: MangaStore = {  
  store_usuarios: [],
  store_colecao_group: [],
  store_colecao_editoras: [],
  
  store_catalogo: [],
  store_catalogo_filtro: [],
  store_catalogo_tags: [],

  store_checklist: [],
  store_checklist_group: [],
  store_volumes: [],

  store_volumes_modal: [],
  store_volumes_modal_nome: '',
  store_volumes_modal_elements: [],

  store_colecao_modal: null,
  
  store_recomendacoes: [],
  store_nota: { 
    nota: 0,
    nota_user: 0,
    nota_manga: 0,
    total: 0,
    total_votos: 0,
  },

  store_all_mangas: [],
  store_all_mangas_recents: [],
}
 
 export default function manga_reducer(state = INITIAL_STATE, action: ReducerType) {
  switch(action.type) {  
    case '@RESET_MANGAS': return state = INITIAL_STATE;

    case '@SET_USUARIOS': return { ...state,  store_usuarios: action.response };
    
    case '@SET_COLECAO_GROUP': return { ...state,  store_colecao_group: action.response };
    case '@SET_COLECAO_EDITORAS': return { ...state,  store_colecao_editoras: action.response };

    case '@SET_CATALOGO': return { ...state,  store_catalogo: action.response };
    case '@SET_CATALOGO_FILTRO': return { ...state,  store_catalogo_filtro: action.response };
    case '@SET_CATALOGO_TAGS': return { ...state,  store_catalogo_tags: action.response };

    case '@SET_CHECKLIST': return { ...state,  store_checklist: action.response };
    case '@SET_CHECKLIST_GROUP': return { ...state,  store_checklist_group: action.response };

    case '@SET_VOLUMES': return { ...state,  store_volumes: action.response };

    case '@SET_ALL_MANGAS': return { ...state,  store_all_mangas: action.response };
    case '@SET_ALL_MANGAS_RECENTS': return { ...state,  store_all_mangas_recents: action.response };

    case '@SET_VOLUMES_MODAL': return { ...state, store_volumes_modal: action.payload.volumes, 
                                                  store_volumes_modal_nome: action.payload.manga,
                                                  store_volumes_modal_elements: action.payload.lista_volumes
                                      };        
    
    case '@RESET_VOLUMES_MODAL': return { ...state, store_volumes_modal: [],
                                                    store_volumes_modal_nome: '',                                              
                                                    store_volumes_modal_elements: [],
                                        };

    case '@SET_COLECAO_MODAL': return { ...state, store_colecao_modal: action.response };
    case '@RESET_COLECAO_MODAL': return { ...state, store_colecao_modal: [] }

    case '@RESET_CHECKLIST': return { ...state,  store_checklist: [], store_checklist_group: [] };
    case '@RESET_VOLUMES': return { ...state,  store_volumes: [] };
    case '@RESET_CATALOGO': return { ...state,  store_catalogo: [] };
    case '@RESET_CATALOGO_TAGS': return { ...state,  store_catalogo_tags: [] };

    case '@RESET_RECOMENDACOES': return { ...state,  store_recomendacoes: [] };
    case '@SET_RECOMENDACOES': return { ...state,  store_recomendacoes: action.response };

    case '@RESET_NOTAS': return { ...state,  store_nota: { nota_manga: 0, nota_user: 0, total_votos: 0 } };
    case '@SET_NOTAS': return { ...state,  store_nota: action.response };

    default: return state;
  }
}
