const catalogoViewContainer = (id, wasHide) => {
  const ArrowUp = document.getElementById(`${id}-up`).classList;
  const ArrowDown = document.getElementById(`${id}-down`).classList;

  const element = document.getElementById(id).classList;
  
  if(wasHide) {
    element.remove('hide');
    ArrowUp.remove('hide');
    ArrowDown.add('hide');
  }
};

export default catalogoViewContainer;
