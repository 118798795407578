import React, { memo, useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { Nota } from '@/components';
import { newDrop } from '@/functions';
import Titulo from './titulo';
import './style.css';

const Header = (props) => {
  const { 
    dispatch,
    store_nota,
    store_volumes_modal_elements,
    store_colecao_modal,
    colecao_action,
    colecao_btnAll_action,
    windowSize,
  } = props;

  const {
    id: id_manga
  } = store_nota;

  const [ncolecao, set_Ncolecao] = useState(0);
  const [ntotal, set_Ntotal] = useState(0);
  const [status, set_Status] = useState('');
  const [drop, set_Drop] = useState(false);
  const [tagStatus, set_TagStatus] = useState(0);
  const [capasVariantes, set_CapasVariantes] = useState([]);

  const TagTitulo = useCallback(({ children }) => (
    <p className="m-0">
      <span className="text-info text-center noselect">{ children }</span>
    </p>
  ), [])

  const RenderNota = useCallback(() => (
    <div style={{ width: 340, height: 100 }}>
      <Nota {...props} id_manga={id_manga} modal checked={drop} />
    </div>
  ), [props, id_manga, drop]);

  const RenderColecao = useCallback(({ position, ml }) => (
    <div style={{ width: 150, height: 100 }} className={`flex-container-top pr-4 pl-3 ml-${ml} flex-justify-${position}`}>
      <div className="pt-1">
        <TagTitulo>COLEÇÃO</TagTitulo>
        <p className="h1 p-0 ml-1 text-orange">
          { ncolecao } <span className="h4 text-muted"> / { ntotal }</span>
        </p>
      </div>
    </div>
  ), [ncolecao, ntotal]);

  const HandleDrop = useCallback((checked) => {
    set_Drop(checked);
    newDrop(props, checked);
  }, [props]);

  const RenderDrop = useCallback(({ store_user, store_colecao_user }) => {
    const { id: id_user } = store_user;
    const { id: id_user_colecao } = store_colecao_user;
    const fraseLabel = !drop ? `Dropar coleção?` : 'Coleção dropada!'

    return( 
      status !== 'Completo' && ncolecao !== 0 && id_user === id_user_colecao
      ? <div className='d-flex mt-3 justify-content-around'>
          <input 
            type="checkbox"
            id="drop-colecao"
            name="drop-colecao"
            className="modal-colecao-editar-input" 
            checked={drop}
            onChange={({target}) => HandleDrop(target.checked)}                  
          />
          <label 
            htmlFor="drop-colecao" 
            className="lead text-orange modal-colecao-editar-label"
          >{fraseLabel}</label>
        </div> 
      : null 
    )
  }, [status, ncolecao, drop, HandleDrop ]);

  const get_CapasVariantes = useCallback(() => {
    const todosVolumes = [...store_volumes_modal_elements].map((volume) => {
      const { props } = volume;
      const { dados, colecao } = props;
      const { comprado } = colecao;
      const { id_volume, numero } = dados;
      return { id_volume, numero, comprado };
    });
    const variantes = [...todosVolumes].filter(volume_var => {
      const iqualMasDiferente = todosVolumes.find(volume => {
        return volume_var.numero === volume.numero && volume_var.id_volume !== volume.id_volume
      });
      return iqualMasDiferente
    });
    set_CapasVariantes(variantes);
  }, [store_volumes_modal_elements]);

  const update_CapasVariantes = useCallback((id_volume, action) => {
    const newArr_capasVariantes = [...capasVariantes];
    newArr_capasVariantes.forEach(volume => {
      if (volume.id_volume === id_volume) {
        volume.comprado = action;
      }
    });
    set_CapasVariantes(newArr_capasVariantes);
  }, [capasVariantes]);

  //AO INICIAR O MODAL
  useEffect(() => {
    if (store_volumes_modal_elements.length > 0 ) {
      const {
        colecao,
        total,
        status,
      } = store_colecao_modal;

      set_Drop(status === 'Drop');
      set_Status(status);
      set_Ncolecao(colecao);
      set_Ntotal(total);
      get_CapasVariantes();
    }
  }, [store_volumes_modal_elements, store_colecao_modal, set_Status, set_Ncolecao, set_Ntotal, get_CapasVariantes])

  //ADD E REMOVE DE COLECAO
  useEffect(() => {
    const response = { add: false, remove: false, id_volume: null };
    const { add, remove, id_volume } = colecao_action;
    let colecao = Number(ncolecao);
    let numeroVariante = undefined;
    let variante_comprada = false;

    if (id_volume && (add || remove)) {
      numeroVariante = capasVariantes.find(volume => volume.id_volume === id_volume)?.numero;
      if (numeroVariante) {
        const osVariantes = capasVariantes.filter(volume => volume.numero === numeroVariante && volume.id_volume !== id_volume);
        variante_comprada = osVariantes.some(volume => volume.comprado);
        update_CapasVariantes(id_volume, add);
      }
    }

    if(add) {
      if(ncolecao < ntotal && !variante_comprada) { 
        setTimeout(() => { set_Ncolecao(++colecao); }, 100);
      }
      dispatch({ type: '@PUBLIC_COLECAO_ACTION', response })
    }

    if(remove) {
      if(!variante_comprada) {
        setTimeout(() => { set_Ncolecao(--colecao); }, 100); 
      }
      dispatch({ type: '@PUBLIC_COLECAO_ACTION', response })
    }

    /** PS: setTimeOut
     * ele está ai pra dar tempo do dispatch executar corretamente
     * sem ele ficar atualizando o numero da coleção 
     * de forma errada */
  }, [
    dispatch,
    colecao_action,
    ncolecao, ntotal, 
    drop, HandleDrop, 
    capasVariantes, update_CapasVariantes
  ]);

  //ADD OU REMOVER TODOS DA COLECAO
  useEffect(() => {
    const response = { colecao_edicao: true };
    const { add, remove, colecao_edicao  } = colecao_btnAll_action;

    if(!colecao_edicao) {
      if(add) {
        set_Ncolecao(ntotal);
        dispatch({ type: '@PUBLIC_COLECAO_BTN_ALL_ACTION', response })
      }
  
      if(remove) {
        set_Ncolecao(0);
        dispatch({ type: '@PUBLIC_COLECAO_BTN_ALL_ACTION', response })
      }
    }

  }, [dispatch, colecao_btnAll_action, ntotal]);

  //MUDA O STATUS
  useEffect(() => {
    let statusNomeEsperado = status;
    
    if(ncolecao < ntotal) {
      statusNomeEsperado = 'Incompleto';
      if(drop && !ncolecao){ HandleDrop(false); }
    }
    else {
      statusNomeEsperado = 'Completo';
      if(drop){ HandleDrop(false); }
    }

    if(drop && statusNomeEsperado === 'Incompleto' && ncolecao) {
      statusNomeEsperado = 'Drop';
    }

    const typeBgStatus = {
      Completo: 'bg-success',
      Incompleto: 'bg-danger',
      Drop: 'bg-muted'
    }[statusNomeEsperado] || '';
    set_TagStatus(
      <span className={`${typeBgStatus} text-white rounded ml-3 pt-1 pb-1 pl-3 pr-3`}
      >{statusNomeEsperado}</span>
    );

    //SÓ CONTINUO SE HOUVER MUDANÇA
    if (status !== statusNomeEsperado) {
      set_Status(statusNomeEsperado);
    }
  }, [status, ncolecao, ntotal, drop, HandleDrop]);

  const RenderContent = useCallback(() => {
    switch (windowSize.size) {
      case 'XS':
        return (
          <>
            <Col xs={12} className="flex-container-top flex-justify-center pb-1 pr-0 pl-0">
              <RenderNota {...props} />
            </Col>
        
            <Col xs={5} className="flex-container-top flex-justify-right pt-3 pb-1 pr-0">
              <RenderColecao {...props} position='right' />
            </Col>
            
            <Col xs={7} className="flex-container-top flex-justify-left pt-3 pb-1">
              <div style={{ paddingTop: 4 }}>
                <TagTitulo> STATUS { tagStatus } </TagTitulo>
                <RenderDrop {...props} />
              </div>
            </Col>
          </>
        )
      case 'SM':
        return (
          <>
            <Col sm={12} className="flex-container-top flex-justify-center pt-1 pb-1">
              <RenderNota {...props} />
              <RenderColecao {...props} position='left' ml={4} />
            </Col>

            <Col sm={6} className="flex-container-top flex-justify-right pt-3 pb-4 pr-4">
              <TagTitulo> STATUS { tagStatus } </TagTitulo>
            </Col>

            <Col sm={6}>
              <RenderDrop {...props} />
            </Col>
          </>
        )
      default:
        return (
          <>
            <Col md={5} className="flex-container-top flex-justify-left pt-1 pb-1">
              <RenderNota {...props} />
            </Col>
            
            <Col md={7} className="flex-container-top flex-justify-right pt-1 pb-1">
              <RenderColecao {...props} position='right' />
              <div style={{ width: 200, height: 100 }} className="pt-1">
                <TagTitulo> STATUS { tagStatus } </TagTitulo>
                <RenderDrop {...props} />
              </div>
            </Col>
          </>
        )
    }
  }, [props, windowSize, tagStatus]);

  return (
    <>
      <Titulo {...props}>EDITAR DADOS DA COLEÇÃO</Titulo>
      <Row className="p-0 m-0">
        <RenderContent {...props} />
      </Row>
    </>
  )
}

export default memo(Header);
