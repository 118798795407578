const initial_store_amazon_filtro = [
  {
    filtro_label: "sort",
    visible: true,
    itens: [
      {id: 1, nome: "Relevância", value: "Relevance", checked: true}, 
      {id: 2, nome: "Preço: Menor p/ maior", value: "Price:LowToHigh"}, 
      {id: 3, nome: "Preço: Maior p/ menor", value: "Price:HighToLow"}, 
      {id: 4, nome: "Os mais recentes", value: "NewestArrivals"},
      {id: 5, nome: "Os mais comentados", value: "AvgCustomerReviews"},
      {id: 6, nome: "Os mais votados", value: "Featured"},
    ],
    nome: "Ordenar por",
  },
  {
    filtro_label: "departamento",
    itens: [
      {id: 1, nome: "Tudo", value: "All"},
      {id: 2, nome: "Livros e Revistas", value: "Books", checked: true},
      {id: 3, nome: "Computadores", value: "Computers"},

      {id: 4, nome: "Eletrônicos", value: "Electronics"},
      {id: 5, nome: "Casa e Cozinha", value: "HomeAndKitchen"},
      {id: 6, nome: "Loja Kindle", value: "KindleStore"},

      {id: 7, nome: "Apps e Jogos", value: "MobileApps"},
      {id: 8, nome: "Papelaria", value: "OfficeProducts"},
      {id: 9, nome: "Ferramentas", value: "ToolsAndHomeImprovement"},
      {id: 10, nome: "Games", value: "VideoGames"},
    ],
    nome: "Departamento",
  },
  {
    filtro_label: "valorMin",
    itens: [
      {id: 1, nome: "R$ 5,00",  value: "500"},
      {id: 2, nome: "R$ 10,00", value: "1000"},
      {id: 3, nome: "R$ 15,00", value: "1500"},
      {id: 4, nome: "R$ 25,00", value: "2500"},
      {id: 5, nome: "R$ 50,00", value: "5000"},
    ],
    nome: "Valor mínimo",
  },
  {
    filtro_label: "descontoMin",
    itens: [
      {id: 1, nome: "5%",  value: "5"},
      {id: 1, nome: "10%", value: "10"},
      {id: 1, nome: "15%", value: "15"},
      {id: 1, nome: "25%", value: "25"},
      {id: 1, nome: "50%", value: "50"},
    ],
    nome: "Desconto mínimo",
  },
];

const initial_store_filtro_form = {
  DescontoMin: "",
  Editora: "",
  Filtro: "Books",
  Keywords: "Mangás",
  Page: 1,
  Sort: "Relevance",
  ValorMin: "",
}

export {
  initial_store_amazon_filtro,
  initial_store_filtro_form,
}