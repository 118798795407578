import React, { memo } from 'react';
import { FaSpinner } from "react-icons/fa";

const loadingIcon = () => (
  <>
    <div className="public-container-loading flex-container flex-justify-center text-orange">
      <FaSpinner size={22} className="icon-spin mr-2" />
      carregando...
    </div>
  </>
)

export default memo(loadingIcon);
