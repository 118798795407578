const getWindowSize = () => {    
  const payload= { size: '', width: 0, height: 0 };

  if (window.innerWidth <= 575.99) { 
    payload.size= 'XS';
  } else if (window.innerWidth <= 767.99) { 
    payload.size= 'SM';
  } else if (window.innerWidth <= 991.99) { 
    payload.size= 'MD';
  } else if (window.innerWidth <= 1199.99) { 
    payload.size= 'LG';
  } else {
    payload.size= 'XL';
  }

  payload.width= window.innerWidth;
  payload.height= window.innerHeight;

  return payload;
}

export default getWindowSize;