import React, { memo } from "react";
import { GoogleLogin } from "react-google-login";
import { Row, Col } from "reactstrap";
import { IoIosWarning } from "react-icons/io";
import { ShowMsg } from "@/components";
import { login } from "@/functions";
import "./style.css";

const CardCriarConta = (props) => (
  <>
    <div className="p-5">
      <Row>
        <Col sm={12} className="p-1 flex-container">
          <IoIosWarning size="7.5rem" className="text-orange" />

          <div className="pl-4">
            <p className="h2 text-white">Que tal criar uma conta?</p>
            <p className="h4 text-muted mt-3">
              Recurso exclusivo para clientes logados no sistema.
            </p>

            <p className="h6 text-muted mt-3">
              Para criar sua conta é fácil, basta
              {/* <GoogleLogin
                clientId={import.meta.env.VITE_APP_GOOGLE_ID}
                buttonText="ENTRAR"
                onSuccess={response => login(response, props)}
                onFailure={() => ShowMsg(
                  'Error',
                  'Falha na comunicação. Tente novamente.',
                  'error',
                  3000,
                  )
                }
                isSignedIn={false}
                className="bg-primary text-light"
                render={renderProps => (
                  <span className="text-orange cursor" onClick={renderProps.onClick}> clicar aqui </span>
                )}
              /> */}
              e deixar o resto conosco.
            </p>

            <p className="h6 text-muted mt-2">
              E o melhor de tudo! O acesso é totalmente{" "}
              <span className="text-orange">gratuito</span>.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  </>
);

export default memo(CardCriarConta);
