import api from '@/services/api';
import { ShowMsg } from '@/components';
import { checkRCI } from '@/functions';

const newResposta = async (props, comentario, textEditar) => {
  const { store_user } = props;
  const auth = store_user.token;
  const RCI = checkRCI(comentario);

  const form = {
    id: comentario.id,
    texto: textEditar
  }

  switch (RCI) {
    case 'COMENTARIO':
      await api.put('/edt-resposta', form, auth)
               .then( null, error => ShowMsg({ title: 'Ops!', message: error.response.data.error, type: 'error', tempo: 3000, }));
    break;
    case 'INTERACAO':
      await api.put('/edt-interacao', form, auth)
               .then( null, error => ShowMsg({ title: 'Ops!', message: error.response.data.error, type: 'error', tempo: 3000, }));
    break;
    default:
  }
  
  ShowMsg('Sucesso!', 'Resposta enviada com sucesso.', 'success', 2500);
}

export default newResposta;
