const mapState = state => ({
  isLogin: state.auth.isLogin,
  store_user: state.auth.store_user,
  windowSize: state.sistema.windowSize,
  
  store_catalogo: state.manga.store_catalogo,
  store_catalogo_tags: state.manga.store_catalogo_tags,  
  store_catalogo_filtro: state.manga.store_catalogo_filtro,  
});

export default mapState;
