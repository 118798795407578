import api from '../../../services/api';

const empty = [{
   id: 0,
   colecao: 0,
   editora: '',
   id_drop: 0,
   id_manga: 0,
   manga: 'Nada encontrado.',
   nota: 0,
   nota_user: 0,
   status: '',
   subtitulo: '',
   total: 0,
   total_votos: 0,
}];

const loadColecaoGroup = async (dispatch, id) => {
   dispatch({ type: '@RESET_COLECAO_USER' });
   dispatch({ type: '@SET_COLECAO_GROUP', response: empty });
   const resUser = await api.get(`/list-usuario/${id}`);
   const resColecao = await api.get(`/list-colecao-group/${id}`);
   const resEditoras = await api.get(`/list-colecao-editoras/${id}`);

   if (resColecao.data.length > 0 && resColecao.data[0].msg === undefined) {
      dispatch({ type: '@SET_COLECAO_GROUP', response: resColecao.data });
   }

   dispatch({ type: '@SET_COLECAO_USER', response: resUser.data });
   dispatch({ type: '@SET_COLECAO_EDITORAS', response: resEditoras.data });
   // return resColecao.data;
}

export default loadColecaoGroup;