import * as F from '@/functions';

const modalVolumes = (props, id_manga) => {
  const { dispatch } = props;
  dispatch({ type: '@RESET_VOLUMES_MODAL' });
  dispatch({ type: '@RESET_COLECAO_MODAL' })
  dispatch({ type: '@SET_MODAL', response: '@LOADING' });
  F.processModalVolumesArray(props, id_manga);
}

export default modalVolumes;
