import React, { memo, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as R from 'reactstrap';
import * as F from '@/functions';
import { SwitchButton } from '@/components';
import mapState from './mapstate';
import Header from './header';
import './styles.css';

const FormSettings = (props) => {
  const { store_user } = props;

  const [isChanged, set_isChanged] = useState(false);
  const [config_reimpressao, set_config_reimpressao] = useState(false);
  const [config_show_msg, set_config_show_msg] = useState(false);
  const [config_auto_login, set_config_auto_login] = useState(false);
  const [config_mail, set_config_mail] = useState(false);
  const [colecao_filtro, set_colecao_filtro] = useState('');
  const [config_colecao_tabela, set_config_colecao_tabela] = useState(false);
  const [config_colecao_filtro, set_config_colecao_filtro] = useState(false);
  
  const ConfigRow = useCallback(({ children, isSwitch }) => (
    <R.Row className="pt-3 m-0 pl-4 pr-4 pb-3">
      <R.Col xs={12} className="flex-container flex-justify-left pl-5">
        {
          isSwitch
            ? <div className='pl-3 ml-1 flex-container flex-justify-left'>
                { children }
              </div> 
            : children
        }
      </R.Col>
    </R.Row>
  ), []);

  const Text = useCallback(({ children, value }) => (
    <p className={`m-0 ml-4 ${value ? `text-info` : `text-muted`}`}>
      { children }
    </p>
  ), []);

  const Input = useCallback(({ checked, onChange }) => (
    <input
      className="config-checkbox" 
      type="checkbox"
      checked={checked}
      onChange={onChange}
    /> 
  ), []);

  const saveSettings = useCallback(() => {
    const { store_user } = props;
    /*
    const { user: cache_user } = store_cache
    const { token, user } = cache_user;

    const new_user = {
      ...user,
      config_reimpressao,
      config_show_msg,
      config_auto_login,
      config_mail,
      config_colecao_tabela,
      config_colecao_filtro,
      colecao_filtro,
    }

    const formUser = {
      ...store_user,
      config_reimpressao,
      config_show_msg,
      config_auto_login,
      config_mail,
      config_colecao_tabela,
      config_colecao_filtro,
      colecao_filtro,
    }

    dispatch({ type: '@SET_USER', response: formUser });

    const new_cache = {
      ...store_cache,
      user: {
        token,
        user: new_user,
      }
    }

    F.saveCache(config_auto_login, new_cache);
    */
    
    const saveSettings = {
      id: store_user.id,
      config_reimpressao,
      config_show_msg,
      config_auto_login,
      config_mail,
      config_colecao_tabela,
      config_colecao_filtro,
      colecao_filtro,
    }

    F.editUserSettings(props, saveSettings);
  }, [
    props,
    config_reimpressao,
    config_show_msg,
    config_auto_login,
    config_mail,
    config_colecao_tabela,
    config_colecao_filtro,
    colecao_filtro,
  ]);

  useEffect(() => {
    set_config_auto_login(store_user.config_auto_login);
    set_config_reimpressao(store_user.config_reimpressao);
    set_config_mail(store_user.config_mail);
    set_config_show_msg(store_user.config_show_msg);
    set_colecao_filtro(store_user.colecao_filtro);
    set_config_colecao_filtro(store_user.config_colecao_filtro);
    set_config_colecao_tabela(store_user.config_colecao_tabela);
  }, [store_user]);

  useEffect(() => {
    if (isChanged) {
      set_isChanged(false);
      saveSettings();
    }
  },[isChanged, saveSettings]);

  return (
    <>
      {/*** LOGIN ***/}
      <>
        <Header  titulo="Login" />
        <ConfigRow>
          <Input 
            checked={config_auto_login}
            onChange={({target}) => {
              set_isChanged(true);
              set_config_auto_login(target.checked);
            }} />
          <Text value={config_auto_login}>Login automático</Text>
        </ConfigRow>
      </>
      {/*** NOTIFICAÇÕES ***/}
      <>
        <Header  titulo="Notificações" />
        <ConfigRow>
          <Input 
            checked={config_mail}
            onChange={({target}) => {
              set_isChanged(true);
              set_config_mail(target.checked);
            }} />
          <Text value={config_mail}>Receber notificações por email</Text>
        </ConfigRow>
        <ConfigRow>
          <Input 
            checked={config_show_msg}
            onChange={({target}) => {
              set_isChanged(true);
              set_config_show_msg(target.checked);
            }} />
          <Text value={config_show_msg}>Exibir alertas de sistema ( Login, Adicionar Coleção, Erros, etc... )</Text>
        </ConfigRow>
      </>
      {/*** CHECKLIST ***/}
      <>
        <Header  titulo="Checklist" />
        <ConfigRow>
          <Input 
            checked={config_reimpressao}
            onChange={({target}) => {
              set_isChanged(true);
              config_reimpressao(target.checked);
            }} />
          <Text value={config_reimpressao}>Exibir tag Reimpressão</Text>
        </ConfigRow>
      </>
      {/*** COLEÇÃO ***/}
      <>
        <Header  titulo="Coleção" />
        <ConfigRow isSwitch>
            <Text>Exibição padrão</Text>
            <SwitchButton 
              isChecked={config_colecao_tabela}
              options={['Capas','Tabela']}
              left={5} 
              onChange={({target}) => {
                set_isChanged(true);
                set_config_colecao_tabela(target.checked);
              }}
            />
        </ConfigRow>
        <ConfigRow>
          <Input 
            checked={config_colecao_filtro}
            onChange={({target}) => {
              set_isChanged(true);
              set_config_colecao_filtro(target.checked);
            }} />
          <Text value={config_reimpressao}>Salvar filtro</Text>
        </ConfigRow>
      </>
    </>
  )
}

export default memo(connect( () => (mapState) )(FormSettings));
