import api from '@/services/api';
import { ShowMsg } from '@/components';
import processLogin from './processLogin';

const login = async (props, form) => {
   //// LOGIN
   const login = await api.post('/login', {
      email: form.email, 
      senha: form.password,
   });

   if (login?.data?.error) {
      ShowMsg(
        'Atenção!',
        login?.data?.error,
        'error',
        3000,
        true,
     );
     
     return false;
    }

   const newDados = {
      user: login.data,
      googleData: [],
      // pageAdmin: dados.pageAdmin,
   }
   
   setTimeout(async () => {
      //// PROCESSA LOGIN
      processLogin(props, newDados);
   }, 750);
}

export default login;
