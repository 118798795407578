import React, { memo } from 'react';

const Login = (props) => (
  <span 
    id="navbar-btn-login"
    onClick={() => props.dispatch({ type: '@SET_MODAL', response: '@MODAL_LOGIN' })}
    className="public-navbar-entrar noselect"
  >
    ENTRAR | CADASTRAR
  </span>
)

export default memo(Login);
