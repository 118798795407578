
  const processMangasLimit = (windowSize) => {
    let limit = 1   
    if(windowSize.width >= 630)  {limit = 2}
    if(windowSize.width >= 825)  {limit = 3}
    if(windowSize.width >= 985)  {limit = 4}
    if(windowSize.width >= 1295) {limit = 5}
    if(windowSize.width >= 1470) {limit = 6}
    if(windowSize.width >= 1650) {limit = 7}
    if(windowSize.width >= 1830) {limit = 8}
    if(windowSize.width >= 2010) {limit = 9}
    if(windowSize.width >= 2185) {limit = 10}
    if(windowSize.width >= 2365) {limit = 11}
    if(windowSize.width >= 2545) {limit = 12}
    if(windowSize.width >= 2720) {limit = 13}
    if(windowSize.width >= 2900) {limit = 14}
    if(windowSize.width >= 3080) {limit = 15}
    if(windowSize.width >= 3255) {limit = 16}
    if(windowSize.width >= 3435) {limit = 17}
    if(windowSize.width >= 3615) {limit = 18}
    if(windowSize.width >= 3795) {limit = 19}
    if(windowSize.width >= 3980) {limit = 20}
    if(windowSize.width >= 4155) {limit = 21}
    if(windowSize.width >= 4335) {limit = 22}
    if(windowSize.width >= 4505) {limit = 23}
    if(windowSize.width >= 4685) {limit = 24}
    if(windowSize.width >= 4865) {limit = 25}
    return limit;
  };

  export default processMangasLimit;
