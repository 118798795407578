import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetDefault = () => (
  <>
    <Helmet>
      <title>Meus Mangás</title>
      <meta name="title" content="Meus Mangás" />
      <meta name="description" content="O portal de mangás publicados no Brasil.
      Gerencie sua coleção gratuitamente e receba notificações das últimas novidades das editoras." />

      {/***  Open Graph / Facebook ***/}
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="http://meusmangas.com.br/"/>
      <meta property="og:title" content="Meus Mangás"/>
      <meta property="og:description" content="O portal de mangás publicados no Brasil.
      Gerencie sua coleção gratuitamente e receba notificações das últimas novidades das editoras."/>
      <meta property="og:image" content="https://i.imgur.com/xd0tjoG.jpeg"/>

      {/*** TWITTER ***/}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="http://meusmangas.com.br/"/>
      <meta property="twitter:title" content="Meus Mangás"/>
      <meta property="twitter:description" content="O portal de mangás publicados no Brasil.
      Gerencie sua coleção gratuitamente e receba notificações das últimas novidades das editoras."/>
      <meta property="twitter:image" content="https://i.imgur.com/xd0tjoG.jpeg"/>

      <meta charSet='utf-8' />
      <meta name='author' content='Danilo Rocha' /> 
      <meta name='creator' content='Danilo Rocha' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta http-equiv='Cache-Control' content='public' />
      <meta name='robots' content='follow' />
      <meta property='og:locale' content='pt-br' />
    </Helmet>
  </>
)

export default HelmetDefault;
