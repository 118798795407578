import React from 'react';
import { Row, Col, FormGroup, Input, Button } from "reactstrap";
import { FaUser } from "react-icons/fa";
import avatar from '../../assets/img/avatar.jpg';
import * as C from '../../components';
import './style.css';

const Form = () => (
  <>
    <Row className="p-0 m-0 pt-2 pb-1">
      {/*** AVATAR ***/}
      <div className="public-colecao-avatar flex-container flex-justify-center">
        
        <div className="public-perfil-container-avatar">
          <div className="public-perfil-avatar-box border-avatar flex-container flex-justify-center">
            {/*** AVATAR EMPTY ***/}
            <FaUser className="text-light text-muted" size={80} />
            <C.AvatarContainer src={avatar} size="lg" />
            
          </div>
          <Button style={{ width:'150px' }} className="mt-3 btn p-1 pl-3 pr-3 btn-info">Upload</Button>
        </div>
        
      </div>
      
      {/*** ESTATISTICAS ***/}
      <div className="public-perfil-stats">
        <>
          <Row className="p-0 m-0">
          <C.InfoContainer title="CADASTRO" info="15/12/2020 07:00 hrs" xs={12} /> 
              <C.InfoContainer title="ÚLTIMO LOGIN" info="15/12/2020 09:37 hrs" xs={12} />
              <C.InfoContainer title="TOTAL DE MANGÁS" info="50 títulos" xs={12} />
              <C.InfoContainer title="TOTAL DE VOLUMES" info="275 volumes adquiridos" xs={12} />
          </Row> 
        </>
      </div>
    </Row>

    <div className="public-perfil-form"> 
      <Row className="p-0 m-0">
                
          <Col xs={6}>
            <FormGroup>
              <Input placeholder="NOME" type="text" />
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup>
              <Input placeholder="SOBRENOME" type="text" />
            </FormGroup>
          </Col>

          <Col xs={12}>
            <FormGroup>
              <Input placeholder="APELIDO" type="text" />
            </FormGroup>
          </Col>

          <Col xs={12}>
            <FormGroup>
              <Input placeholder="EMAIL" type="text" />
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup>
              <Input placeholder="TELEFONE" type="text" />
            </FormGroup>
          </Col>

          <Col xs={6} className="text-right pt-2">
          <FormGroup>
            <Button className="btn p-1 pl-3 pr-3 btn-success">Salvar</Button>
          </FormGroup>
        </Col>
    
      </Row>
    </div>
  </>
)

export default Form;
