const processAmazonLink = (dados) => {
  const subtitulo = `${dados.subtitulo ? dados.subtitulo : ``}`;
  const numero = `${dados.numero ? `volume+${dados.numero}` : ``}`;
  const editora = `${dados.editora ? dados.editora : ``}`;

  const amazon_api = import.meta.env.VITE_APP_AMAZON;
  const amazon_params = import.meta.env.VITE_APP_AMAZON_PARAMS;
  const amazon_search = `${dados.titulo}+${subtitulo}+${numero}+${editora}`;

  const link_api = `${amazon_api}${amazon_search}${amazon_params}`;

  return dados.amazon_link ? dados.amazon_link : link_api;
};

export default processAmazonLink;
