import React, { memo } from 'react';
import './style.css';

const TagReimpressao = ({ flag }) => (
  flag
    ? <>
        <div className="tag-reimpressao">
          <p className="tag-reimpressao-label">REIMPRESSÃO</p>
        </div>
      </>
    : null
)

export default memo(TagReimpressao);
