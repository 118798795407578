import React from 'react';
import { Props } from '@/types';
import StepOne from './steps/stepOne';
import StepTwo from './steps/stepTwo';
import StepThree from './steps/stepThree';

const ModalLogin: React.FC<Props> = (props) => {  
  const [step, setStep] = React.useState<number>(1);

  return (
    <div className='text-center pl-3 pr-3 pt-0 pb-0'>
        {
          step === 1 ? 
            <StepOne 
              {...props}
              setStep={setStep}  
            /> : null
        }

        {
          step === 2 ?
          <StepTwo
            {...props}
            setStep={setStep}
          /> : null
        }
            
        {
          step === 3 ?
            <StepThree
              {...props}
              setStep={setStep}
            /> : null
        }
    </div>
  );
}

export default React.memo(ModalLogin);
