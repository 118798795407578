import api from '../../../services/api';

const loadRecomendacoes = async (props, store_volumes) => { 
  const { store_user, dispatch } = props;
  const auth = store_user.token; 
  dispatch({ type: '@RESET_RECOMENDACOES' });

  if (store_volumes[0] === undefined) { return }
  
  const { id, Genero, Roteiro_1, Roteiro_2, Arte_1, Arte_2, id_tag_recomendacao_1, id_tag_recomendacao_2 } = store_volumes[0].Manga;

  const generos = Genero;
  const id_manga = id;
  const id_genero = [];

  generos.forEach(item => {
    id_genero.push(item.id);
  });

  const form = {
    id_manga, 
    id_genero,
    id_roteiro_1: Roteiro_1.id,
    id_roteiro_2: Roteiro_2 ? Roteiro_2.id : 0,
    id_arte_1: Arte_1.id,
    id_arte_2: Arte_2 ? Arte_2.id : 0,
    id_tag_recomendacao_1: id_tag_recomendacao_1 ? id_tag_recomendacao_1 : 0,
    id_tag_recomendacao_2: id_tag_recomendacao_2 ? id_tag_recomendacao_2 : 0,
  };

  const recomendacoes = await api.post('/all-recomendacoes-genero', form, auth);
  dispatch({ type: '@SET_RECOMENDACOES', response: recomendacoes.data });
  // return stats;
}

export default loadRecomendacoes;
