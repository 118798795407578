import React, { memo, useCallback } from 'react';
import { Button } from 'reactstrap';
import { RiAmazonFill } from "react-icons/ri";
import { processAmazonLink } from '@/functions';

const LinkAmazon = (props) => {
  const { dados, modal } = props;
  const { comprar, amazon_link } = dados;
  const { capaModal } = modal;

  const handleComprarAmazon = useCallback((link) => {
    // const data = {
    //   plataforma: 1,
    //   location,
    //   id_manga,
    //   descricao: titulo,
    //   id_sessao,
    //   id_user: store_user.id,
    // }

    // getLogCompras(data);
    window.open(link, '_blank');
  }, []);

  const ButtonAmazon = useCallback(({ link, btn, color }) => (
    <div className="mt-2 mb-1">
      <Button style={ capaModal ? { width: 125 } : { width: 160 }}
        className={`btn-amazon btn btn-sm btn-${btn} text-${color} flex-container flex-justify-center`}
        onClick={link}>
          <RiAmazonFill size={22} className="mr-2" />
          COMPRAR
      </Button>
    </div>
  ), [capaModal]);

  return(
    <>
      {
        comprar
        ? 
          <ButtonAmazon 
            link={() => handleComprarAmazon(processAmazonLink(dados))} 
            btn={amazon_link ? `orange` : `orange-amazon-link`} 
            color="dark" 
          />
        : null
      }
    </>
  )
}

export default memo(LinkAmazon);
