
import { AuthStore, MangaStore, SistemaStore } from '@/types';

type stateType = {
  auth: AuthStore,
  manga: MangaStore,
  sistema: SistemaStore
};

const mapState = (state: stateType) => ({
  store_user: state.auth.store_user,
  
  store_volumes: state.manga.store_volumes,
  store_volumes_modal: state.manga.store_volumes_modal,
  
  colecao_action: state.sistema.colecao_action,
  colecao_btnAll_action: state.sistema.colecao_btnAll_action,
});

export default mapState;