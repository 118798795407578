import React from 'react';
import { connect } from 'react-redux';
import * as C from '../../components';
import FormPerfil from './form';
import mapState from './mapstate';
import './style.css';

const Perfil = (props) => {
  const { isLogin } = props;
  return (
    <>
      <C.AppContainer {...props}>

        <C.TitleWeb titulo="perfil" subtitulo="Atualize suas informações no site" />

        {
          isLogin
          ? <FormPerfil {...props} />
          : <C.CardCriarConta {...props} />
        }

      </C.AppContainer>
    </>
  )
}

export default connect( () => (mapState) )(Perfil);