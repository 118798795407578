import React, { memo, useState, useEffect, useCallback } from 'react';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { AppContainer } from '../../components';
import * as R from 'reactstrap';
import { 
  processListaCapasCatalogo, 
  processMangasLimit, 
  processCheckListArray,
  loadCatalogo
} from '../../functions';
import mapState from './mapstate';
import './style.css';

const TitleWeb = lazy(() => import('../../components/title'));
const GridCapas = lazy(() => import('../../components/grid-capas'));
const Tags = lazy(() => import('./tags'));
const Filter = lazy(() => import('./filter'));

const Catalogo = (props) => {
  const {
    dispatch,
    windowSize,
    store_catalogo,   
  } = props;

  const [lista_capas, set_lista_capas] = useState([]);

  const handleCatalogo = useCallback((store_catalogo) => {
    const lista = processListaCapasCatalogo(store_catalogo);
    const totalLinha = processMangasLimit(windowSize) - 1;
    
    const dados = {
      checklist: {
        checklistLista: lista,
        limit: 0,
      },    
      visibility: {
        pagina_preco: false,
        publicacao: false,
        numero: false,
        comprar: false,
      },      
      colecao: {
        active: false,
        total: 0,
      },        
      mouseHover: {
        menuBasic: true,
        active: true,
        totalLinha,
      },
      modal: {
        active: true,
        capaModal: false,
      },
      noClick: { 
        active: false 
      },
    };
    const checklist = processCheckListArray(props, dados);
    set_lista_capas(checklist);
  },[props, windowSize]);

  useEffect( () => {
    dispatch({ type: '@RESET_CATALOGO_TAGS' });
    loadCatalogo(props);
    // eslint-disable-next-line
  }, []);

  /// LISTA CAPAS
  useEffect(() => {
    store_catalogo.length <= 0
      ? set_lista_capas([])
      : handleCatalogo(store_catalogo);
  },[store_catalogo, handleCatalogo]);

  return (
    <>
      <AppContainer {...props}>

        <Helmet>
          <title>Catálogo - Meus Mangás</title>
        </Helmet>

        <Suspense fallback="">
          <R.Row className="m-0 flex-container flex-container-top">
          
          {
            windowSize.width >= 576
              ? <div className="public-catalogo-filtro">
                  {/*** FILTRO ***/}
                  <Filter {...props} />
                </div>
              : null
          }

          {/*** CONTENT ***/}
          <div className="public-catalogo-capas">

            <TitleWeb 
              {...props}
              titulo="Catálogo" 
              subtitulo="lista completa dos mangás publicados no Brasil" 
              image 
            />

            <Tags {...props} qtd={lista_capas.length}/>
            
            <GridCapas {...props} capas={lista_capas} customKey="catalogo" />

          </div>

        </R.Row>  
        </Suspense>
      </AppContainer>
    </>
  )
}

export default memo(connect( () => (mapState))(Catalogo));
