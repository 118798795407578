import React, { memo } from 'react';
import { Col } from "reactstrap";

type propTypes = {
  title: string,
  info: JSX.Element | string,
  xs?: number,
  sm?: number,
  md?: number,
  lg?: number,
  xl?: number
}

const InfoContainer = ({ title, info, xs, sm, md, lg, xl }: propTypes) => (      
  <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className="p-2">
    <span className="h5 text-orange mr-3">{ title }:</span>
    <span className="text-light">{ info }</span>
  </Col>
)

export default memo(InfoContainer);
