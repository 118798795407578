const processLinkYoutube = (link) => {
  let link_youtube = link;
  link_youtube = link_youtube.replace('https://', '');
  link_youtube = link_youtube.replace('www.', '');
  link_youtube = link_youtube.replace('youtube.com/', '');
  link_youtube = link_youtube.replace('youtu.be/', '');
  link_youtube = link_youtube.replace('watch?v=', '');
  
  if (link_youtube.includes('?t=')) {
    const newLink = link_youtube.split('?t=');
    link_youtube = newLink[0];
  }
  
  if (link_youtube.includes('&t=')) {
    const newLink = link_youtube.split('&t=');
    link_youtube = newLink[0];
  }
  
  return link_youtube;
} 

export default processLinkYoutube;
