import empty from '@/assets/capas/empty.jpg';
import { processMangasLimit, processCardPosition } from '@/functions';
import { CapaContainer } from '@/components';

const processRankArray = ({ store_rank, windowSize, props }) => {
  const limit = processMangasLimit(windowSize);

  const rank = [];
  let counter = 0;
  let counterLinha = 1;

  store_rank.forEach(item => {
    const { id: id_manga, nome: titulo, subtitulo, imgur_link } = item;

    let capa;
    !!imgur_link ? capa = imgur_link : capa = empty;

    if (counter < limit || limit === 0) {
      rank.push(<CapaContainer {...props}
                  key={`capa-container-${id_manga}`}
                  dados={{
                    titulo,
                    subtitulo,
                    original: '',
                    numero: false,
                    publicacao: false,
                    paginas: false, 
                    preco: false,
                    editora: false, 
                    status: false,
                    id_manga, 
                    id_volume: 0,
                    id_editora: 0,
                    comprar: false,
                    amazon_link: '',
                    capa,
                  }}
                  colecao={{
                    active: false,
                    total: 0
                  }}
                  mouseHover={{
                    active: true,
                    totalLinha: 0,
                    cardPosition: processCardPosition(counterLinha, limit),
                    menuBasic: true,
                  }}
                  modal={{
                    active: true,                      
                    capaModal: false,
                  }}
                  noClick={{ 
                    active: false 
                  }}                  
                  />);
    }
    counter++;
    counterLinha++;
    if (counterLinha > limit) { counterLinha = 1; }
  });

  return rank;
};

export default processRankArray;
