import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch, ConnectedProps } from "react-redux";
import moment from "moment";

import * as F from "@/functions";
import { TitleWeb, GridCapas, CapaContainer } from "@/components";
import { Props, ISistema, IAuth, IManga } from "@/types";

type tStateCapaContainer = ConnectedProps<typeof CapaContainer>[];

const Checklist = (props: Props): JSX.Element => {
  const dispatch = useDispatch();

  const windowSize = useSelector(({ sistema }: ISistema) => sistema.windowSize);
  const store_user = useSelector(({ auth }: IAuth) => auth.store_user);
  const store_checklist = useSelector(
    ({ manga }: IManga) => manga.store_checklist
  );

  moment.locale("pt-br");
  const ano_atual: string = moment().format("YYYY");
  const mes_atual: string = moment().format("MM");
  const dt_label: string = `${F.formatMonthToString(
    mes_atual
  )} de ${ano_atual}`;
  const data: string = `${ano_atual}${mes_atual}`;

  const [lista_capas, set_lista_capas] = useState<tStateCapaContainer>([]);

  /// FIRST LOAD
  useEffect(() => {
    F.loadChecklist({
      store_user,
      dispatch,
      publicacao: data,
      id_user: store_user.id,
      group: false,
    });
    // eslint-disable-next-line
  }, [data]);

  /// MANGAS CHECKLIST
  useEffect(() => {
    const limit = F.processMangasLimit(windowSize);

    const dados = {
      checklist: {
        checklistLista: store_checklist,
        limit,
      },
      visibility: {
        pagina_preco: false,
        publicacao: false,
        numero: true,
        comprar: true,
      },
      colecao: {
        active: true,
        total: 0,
      },
      mouseHover: {
        active: true,
        totalLinha: limit,
      },
      modal: {
        active: true,
        capaModal: false,
      },
      noClick: {
        active: false,
      },
    };

    const checklist = F.processCheckListArray(props, dados) as never;
    set_lista_capas(checklist);
  }, [props, store_checklist, windowSize]);

  return (
    <>
      <TitleWeb
        {...props}
        titulo="checklist"
        subtitulo={dt_label}
        url={`/checklist/${mes_atual}/${ano_atual}`}
      />
      <GridCapas
        {...props}
        listaCompleta
        customKey="checklist"
        capas={lista_capas}
        onClick={() => {
          dispatch({ type: "@RESET_CHECKLIST" });
          localStorage.removeItem("CHECKLIST_TITLE");
          F.navigateTo(
            { ...props, dispatch },
            `/checklist/${mes_atual}/${ano_atual}`,
            2
          );
        }}
      />
    </>
  );
};

export default memo(Checklist);
