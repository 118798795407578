import React, { useState, useEffect } from 'react';
import { CustomInput } from 'reactstrap';
import './style.css';

function ButtonSwitch({
  onChange, options, isChecked, top, left
}) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(isChecked);
  }, [isChecked]);

  return (
    <>
      <div className={`button-switch-container ${left ? `ml-${left}` : `ml-2`}`}>
        
        <span className={`button-switch-text noselect ${value ? `` : `text-info`}`}>
          { options[0] }
        </span>
        
        <CustomInput
          type="switch"
          id="projeto-switch-button"
          name="projeto-switch-button"
          checked={value}
          className={`noselect mt-${top} ml-2 mr-1`}
          onClick={({target}) => setValue(target.checked)}
          onChange={onChange}
        />

        <span className={`button-switch-text noselect ${value ? `text-info` : ``}`}>{ options[1] }</span>
      </div>
    </>
  );
}

export default ButtonSwitch;
