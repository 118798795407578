import api from '@/services/api';
import { TDispatch } from '@/types';

type TLoadChecklist = {
   store_user: any,
   dispatch: (p: TDispatch) => void,
   id_user: number,
   publicacao: string,
   group: boolean,
}

const loadChecklist = async ({ 
   store_user, 
   dispatch,
   id_user,
   publicacao, 
   group,
 }: TLoadChecklist): Promise<void> => {

   const auth = store_user.token;  
   const form = { id_user, publicacao, group };

   const response = await api.post('/list-checklist-2', form, auth);
   const lista = response.data;   
   
   form.group 
      ? dispatch({ type: '@SET_CHECKLIST_GROUP', response: lista })
      : dispatch({ type: '@SET_CHECKLIST', response: lista });
}

export default loadChecklist;