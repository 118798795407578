import { Props, IStore_volumes, IStore_user, TDispatch } from '@/types';
import api from '@/services/api';

const loadVolumes = async (
   dispatch: (p: TDispatch) => void, 
   id: number, 
   store_user: IStore_user
): Promise<IStore_volumes[]> => {
   const auth = store_user?.token;
   const volumes = { 
      id_manga: id, 
      id_user: store_user?.id,
      imgur_link: true,
   } 
   const response = await api.post('/list-volumes-2', volumes, auth);
   const lista: IStore_volumes[] = response.data;
   dispatch({ type: '@SET_VOLUMES', response: lista });
   dispatch({ type: '@PUBLIC_LOADING_FALSE' });
   return lista;
}

export default loadVolumes;