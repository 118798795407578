import { toastr } from "@/custom/react-redux-toastr";
import store from '@/store';

const showMsg = (title, message, type, tempo, show) => {
  const isVisible = store.getState().auth.store_user.config_show_msg || show;
  if (!isVisible) { return }
  
  const config = {
    title: title,
    message: message,
    type: type,
    timeOut: tempo || 2500,
    showCloseButton: true,
    progressBar: true,
    position: "top-right"
  };

  const options = {
    timeOut: config.timeOut,
    showCloseButton: config.showCloseButton,
    progressBar: config.progressBar,
    position: config.position
  };

  const toastrInstance =
    config.type === "info"
      ? toastr.info
      : config.type === "warning"
      ? toastr.warning
      : config.type === "error"
      ? toastr.error
      : toastr.success;

  toastrInstance(
    config.title,
    config.message || "Have fun storming the castle!",
    options
  );
}

export default showMsg
