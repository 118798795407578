import React, { memo } from 'react';
import * as R from 'reactstrap';

const Footer = ({ handleEditar }) => (
  <>    
    <R.CardFooter>
      <R.Row className="pb-2">
        <R.Col xs={12} className="flex-container flex-justify-right">
          <u  onClick={handleEditar}
              className="h6 cursor noselect text-info"
          >
            EDITAR ANÁLISE
          </u>
        </R.Col>
      </R.Row>
    </R.CardFooter>   
  </>
)

export default memo(Footer);
