import api from '../../../services/api';
import { loadNotas } from '../../../functions';
import { ShowMsg } from '../../../components';

const saveNota = async (props, form) => {
  const { dispatch, store_user } = props;
  const auth = store_user.token;
  await api.post('/add-nota', form, auth);
  ShowMsg(
    'Sucesso!',
    'Nota cadastrada com sucesso.',
    'success',
  ); 
  loadNotas(dispatch, form);
}

export default saveNota;
