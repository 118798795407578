
  const processMangasLimit = (windowSize) => {
    let limit = 1   
    if(windowSize.width >= 355)  {limit = 2}
    if(windowSize.width >= 490)  {limit = 3}
    if(windowSize.width >= 665)  {limit = 4}
    if(windowSize.width >= 865)  {limit = 5}
    if(windowSize.width >= 1085) {limit = 6}
    if(windowSize.width >= 1335) {limit = 7}
    if(windowSize.width >= 1515) {limit = 8}
    if(windowSize.width >= 1690) {limit = 9}
    if(windowSize.width >= 1865) {limit = 10}
    if(windowSize.width >= 2050) {limit = 11}
    if(windowSize.width >= 2220) {limit = 12}
    if(windowSize.width >= 2400) {limit = 13}
    if(windowSize.width >= 2580) {limit = 14}
    if(windowSize.width >= 2755) {limit = 15}
    if(windowSize.width >= 2940) {limit = 16}
    if(windowSize.width >= 3120) {limit = 17}
    if(windowSize.width >= 3290) {limit = 18}
    if(windowSize.width >= 3470) {limit = 19}
    if(windowSize.width >= 3650) {limit = 20}
    if(windowSize.width >= 3819) {limit = 21}
    if(windowSize.width >= 3997) {limit = 22}
    if(windowSize.width >= 4176) {limit = 23}
    if(windowSize.width >= 4354) {limit = 24}
    if(windowSize.width >= 4540) {limit = 25}
    return limit;
  };

  export default processMangasLimit;
