import React, { memo } from 'react';
import * as R from 'reactstrap';
import * as C from '@/components';
import { connect } from 'react-redux';
import mapState from './mapstate';
// import * as F from 'functions';
// import './style.css';
// import './responsive.css';

const CardComentario = (props) => (
  <>
    <R.Card id={props.comentario.Id} className="fadeIn border-none text-muted mb-4" style={{ background: '#141E28' }}>
      
      <C.CardComentarioHeader {...props} />
      
      <C.CardComentarioBody {...props} comentario={props.comentario} />
      
      <C.CardComentarioFooter resposta {...props} reply={props.reply} />

    </R.Card>
  </>
)

export default memo(connect(() => (mapState))(CardComentario));
