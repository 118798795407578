import React, { memo, useState, useEffect, useCallback } from 'react';
import * as R from "reactstrap";
import { connect } from 'react-redux';
import { animatedScroll, getWindowSize } from '@/functions';
import showModal from '@/components/modal/showModal';
import * as C from '@/components';
// import { getLogNavegacao, getWindowSize } from 'functions';
import * as I from 'react-icons/fa'
import mapState from './mapstate';
import LoadingIcon from './loadingIcon';
import './style.css';
import './responsive.css';

const AppContainer = (props) => {
  const {
    loading,
    openModal,
    dispatch,
    children,
    windowSize,
    isFirstLoad,
    // id_sessao,
    // location,
    // store_user,
  } = props;

  const container = document.getElementById('app-container');
  
  const [modal, set_modal] = useState('');
  const [showScrollTop, set_showScrollTop] = useState(false);

  const getWindowDimensions = useCallback(() =>{
    if (windowSize.width !== window.innerWidth) {
      dispatch({ type: '@SET_PUBLIC_WINDOW_SIZE', payload: getWindowSize() })
    }
  }, [dispatch, windowSize]);

  const scrollThis = useCallback(() => {
    animatedScroll(container);
  }, [container])

  const handleScroll = useCallback(() => {
    const position = container?.scrollTop;
    position > 700
      ? set_showScrollTop(true)
      : set_showScrollTop(false)
  }, [container]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => dispatch({ type: '@PUBLIC_LOADING_FALSE' }), 300);
    }
  }, [loading, dispatch]);

  // useEffect(() => {
  //   if (!isFirstLoad && id_sessao > 0) {

  //     // eslint-disable-next-line
  //     // const form = {
  //     //   id_sessao,
  //     //   id_user: store_user.id,
  //     //   location: location.pathname,
  //     // }

  //     // getLogNavegacao(form);
  //   }
  // }, [isFirstLoad, id_sessao, location, store_user]);

  useEffect(()=> {
    dispatch({ type:'@SET_MODAL', response: '@RESET_MODAL' });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    set_modal(showModal(props, openModal));
    // eslint-disable-next-line
  }, [openModal]);

  useEffect(() => {
    window.addEventListener("resize", getWindowDimensions);
    getWindowDimensions();    
    return () => window.removeEventListener("resize", getWindowDimensions);
  }, [getWindowDimensions]);

  // public-content-expanded
  return (
    <>
      <R.Row className="public-container" id="app-container" 
        onScroll={() => handleScroll()}   
      >
        <C.Helmet />
        <C.SidebarWeb {...props} />
        <C.Navbar {...props} />
        <C.Modal {...props} dados={modal} />

        <R.Col className="public-content">
          <div style={{ minHeight: window.innerHeight + 45 }}>
          {
            loading
            ? <LoadingIcon />
            : children
          }
          </div> 

          {
            showScrollTop
            ? <div className="public-scroll-to-top" onClick={() => scrollThis()}>
                <I.FaAngleUp  size={35} />
              </div>
            : null
          }

          <C.Footer {...props} /> 

        </R.Col>
      </R.Row>
    </>
  )
}

export default memo(connect(() => (mapState) )(AppContainer));
