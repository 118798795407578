import { useEffect } from 'react';
import { useSelector, useDispatch, createSelectorHook } from 'react-redux';
import api from '@/services/api';
import { TDispatch, IAuth, IManga, IStore_checklist } from '@/types';
import { loadChecklist } from '@/functions';

export const useChecklist = () => {
  const dispatch = useDispatch();
  const store_checklist: IStore_checklist[] = useSelector(({ manga }: IManga) => manga.store_checklist);
  const store_user = useSelector(({ auth }: IAuth) => auth.store_user);

  useEffect(() => {
    loadChecklist({
      store_user, 
      dispatch, 
      id_user: store_user.id, 
      publicacao: '202206', 
      group: false
    });
  }, [])

  return store_checklist;
}