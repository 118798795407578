import { memo, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { processTipoManga } from "@/functions";
import mapState from "./mapstate";
import "./styles.css";

const Settings = (props) => {
  const { history, dispatch, store_all_mangas } = props;

  const formatURL = (text) => {
    return text
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll("à", "a")
      .replaceAll("á", "a")
      .replaceAll("ã", "a")
      .replaceAll("â", "a")
      .replaceAll("è", "e")
      .replaceAll("é", "e")
      .replaceAll("ê", "e")
      .replaceAll("ì", "i")
      .replaceAll("í", "i")
      .replaceAll("î", "i")
      .replaceAll("ò", "o")
      .replaceAll("ó", "o")
      .replaceAll("õ", "o")
      .replaceAll("ô", "o")
      .replaceAll("ù", "u")
      .replaceAll("ú", "u")
      .replaceAll("û", "u")
      .replace(/[^a-z0-9-]/g, "");
  };

  const redirect = useCallback(
    (selected) => {
      if (selected.length > 0) {
        dispatch({ type: "@PUBLIC_LOADING_TRUE" });
        dispatch({ type: "@SET_MODAL", response: "@RESET_MODAL" });
        history.push(`/info/${selected[0].id}/${formatURL(selected[0].nome)}`);
      }
    },
    [dispatch, history]
  );

  const renderLabelKey = useCallback((store_all_mangas) => {
    const { nome, subtitulo, tipo, Editora, original } = store_all_mangas;

    const titulo = `${nome} ${subtitulo ? ` - ${subtitulo}` : ``}`;
    const titulo_original = `${original ? ` [${original}]` : ``}`;
    const titulo_editora = ` | ${Editora.nome} - ${processTipoManga(tipo)}`;

    return `${titulo.toUpperCase()}${titulo_original.toUpperCase()}${titulo_editora.toUpperCase()}`;
  }, []);

  useEffect(() => {
    document.getElementById("search-input").focus();
  }, []);

  return (
    <>
      <div style={{ width: "100%", height: 370, left: "10%" }}>
        <Typeahead
          id="search-mangas"
          labelKey={(store_all_mangas) => renderLabelKey(store_all_mangas)}
          options={store_all_mangas}
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
            <input
              {...inputProps}
              autoFocus
              type="search"
              id="search-input"
              className="public-navbar-search"
              placeholder="O que você está procurando?"
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
            />
          )}
          onChange={(selected) => redirect(selected)}
        />
      </div>
    </>
  );
};

export default memo(connect(() => mapState)(Settings));
