import React, { memo, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import { AppContainer, GridCapas, TitleWeb, TitleChecklist, Collapse } from '@/components';
import { processMangasLimit, processCheckListArray } from '@/functions';
import { Props, ISistema, IManga, IAuth } from '@/types';

const Checklist: React.FC  = (props: Props) => {
  const dispatch = useDispatch();
  
  const store_user = useSelector(({ auth }: IAuth) => auth.store_user);
  const windowSize = useSelector(({ sistema }: ISistema) => sistema.windowSize);
  const store_checklist_group = useSelector(({ manga }: IManga) => manga.store_checklist_group);
  
  return (
    <>
        <AppContainer {...props}>
          {/* @ts-ignore */}
          <Helmet>
            <title>Checklist - Meus Mangás</title>
          </Helmet>

          <TitleWeb
            {...props} 
            titulo="checklist" 
            subtitulo={ localStorage.getItem('CHECKLIST_TITLE') }
            image 
          />
          
          <TitleChecklist 
            {...props} 
            dispatch={dispatch}
            store_user={store_user}
            titulo="checklist" 
          />
          
          {
            store_checklist_group[0]?.msg !== 'Nada encontrado.' ?
              store_checklist_group.map(item => {              
           
                const dados = {
                  checklist: {
                    checklistLista: item.checklist,
                    limit: 0,
                  },    
                  visibility: {
                    pagina_preco: false,
                    publicacao: false,
                    numero: true,
                    comprar: true,
                  },      
                  colecao: {
                    active: true,
                    total: 0,
                  },         
                  mouseHover: {
                    active: true,
                    totalLinha: processMangasLimit(windowSize),
                  },
                  modal: {
                    active: true,
                    capaModal: false,
                  },
                  noClick: { 
                    active: false 
                  },
                };

                return(
                  <Collapse key={item.editora} title={item.editora}>
                    <GridCapas 
                      {...props} 
                      customKey="checklist" 
                      capas={processCheckListArray(props, dados) as never[]} 
                    />
                  </Collapse>
                )              
              })
            : null
          }

        </AppContainer>
    </>
  )
}

export default memo(Checklist);