import React from 'react';
import { Form } from 'rsuite';
import { ShowMsg } from '@/components';
import { MdArrowBack } from 'react-icons/md';

import { modelStepThree } from '../model';
import { IStep } from '../types';
import * as U from '../utils';

const StepTwo: React.FC<IStep> = (props) => {  
  const { setStep } = props;

  const [isSubmited, setIsSubmited] = React.useState<boolean>(false);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState<any>({ email: '' });

  const handleSubmit = React.useCallback(() => {
    const hasError = Object.keys(formError).length > 0;
    
    if (isSubmited && hasError) {
      setIsDisabled(false);
    }

    if (isSubmited && !hasError) {
      setIsDisabled(true);
      ShowMsg(
        'Atenção!',
        'Falha no envio de email. Favor tentar novamente.',
        'error',
        3000,
        true,
     );
    }

    setIsSubmited(false);
  }, [formError, isSubmited]);

  React.useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <div className="fadeIn">     
      <Form     
        onChange={setFormValue}
        onCheck={setFormError}
        onSubmit={() => setIsSubmited(true)}
        formValue={formValue}
        model={modelStepThree}
      >
        <>
          <hr className='mt-0 mb-4' style={{ borderColor: '#6c757d' }} />

          <U.TextField 
            name="email" 
            label="Digite o seu email *" 
            autoComplete="off"
            disabled={isDisabled}
          />
        </>
        
        <>
          <U.ButtonContainer  align="left">
            <span className="text-muted pt-2 pb-2">* campos obrigatórios</span>
          </U.ButtonContainer>
        </>

        <>
          <U.Divisor />

          <U.ButtonContainer  align="space-between">
          <span
              className='h6 mt-1 text-muted cursor pt-1'
              onClick={() => setStep(1)}
            >
              <MdArrowBack size={20} className='mr-2' />
              VOLTAR
            </span>

            <U.ButtonModal 
              submit
              label='ENVIAR' 
              color='success'
            />
          </U.ButtonContainer>
        </>
      </Form>
    </div>
  );
}

export default React.memo(StepTwo);
