import React, { memo } from 'react';
import { Row, Col } from "reactstrap";
import { MdAttachMoney } from "react-icons/md";
import FooterTitle from '../footer/footerTitle';
import FooterText from '../footer/footerText';
import Pix from '@/assets/img/PIX_CODE.jpg';

const Body = ({ dispatch, meta }) => (
  <>
    <Row>
      <Col sm={9} className="pl-4 pr-4 p-2 col-8 text-center text-sm-left">
        <FooterTitle>
          <MdAttachMoney size={28} className="donation-icon" />
          <span className="h4">Doação</span>
        </FooterTitle>

        <FooterText top="1">
          O Meus Mangás é <span className="text-orange">totalmente gratuito</span>, mas existem custos 
          para manter o servidor e a programação de novos recursos.
        </FooterText>

        <FooterText top="1">
          Se você puder, faça uma doação para o nosso trabalho continuar com excelência.
        </FooterText>
        
      </Col>

      <Col 
        sm={3} 
        className="pl-4 pr-4 p-2 col-4 text-right"
      >
        <img 
          src={Pix} 
          className="cursor"
          alt="Logo Meus Mangás" 
          height={125} width={125} 
          onClick={() => dispatch({ type:'@SET_MODAL', response: '@MODAL_DONATION' })}
        />
      </Col>

      <Col sm={6} xl={9} className="pl-4 pr-4 p-2 col-12 text-center text-sm-left">
        <FooterText top="0" >
          <span className="text-white">Meta mensal:</span> 
          <span className="text-orange pl-2">{ meta }</span>
        </FooterText>
      </Col>

      <Col sm={6} xl={3} className="l-4 pr-4 p-2 col-12 donation-link text-center text-sm-right">
        <FooterText top="0">
          <span className="text-orange">PIX: Banco Itaú<br /></span>
        </FooterText>
        <FooterText top="0">
          <span className="text-muted">pix@meusmangas.com.br</span>
        </FooterText>
      </Col>
    </Row>
  </>
)

export default memo(Body);
