import React, { memo } from 'react';
import { navigateTo, formatUrl } from '@/functions';

const Titulo = (props) => {
  ////// PROPS
  const { dados, noClick, modal, card_visibility } = props;
  const { titulo, subtitulo, numero, id_manga } = dados;
  const { capaModal } = modal;

  const { active: isNoClick } = noClick;
  const link = () => navigateTo(props, `/info/${id_manga}/${formatUrl(titulo, subtitulo)}`, 8);
  
  return(
    <>
      {
        !card_visibility ?
          <div className="pt-1 pb-1 m-0 h5">
            {/*** NUMERO ***/
              numero ?
                !capaModal
                  ? <span className="text-orange mr-2">#{ numero }</span> 
                  : <span className="ml-1">
                      <span className="text-muted h6">Volume </span>
                      <span className="text-orange"><b>#{ numero }</b></span>
                    </span> 
              : null
            }

            {/*** TITULO ***/
              titulo && !capaModal
                ? <span className="text-muted cursor h6"
                        onClick={ !isNoClick ? link : null }>
                    <b>{ titulo }</b>
                  </span> 
                : null
            }
          </div>
        : null
      }
    </>
  )
}

export default memo(Titulo);
