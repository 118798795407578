import api from '@/services/api';

const loadCatalogo = async (props, form) => {
  const empty_form = {
    tipo: '',
    id_categoria: '',
    id_editora: '',
    id_nacionalidade: '',
    id_demografia: '',
   
    id_faixa_etaria: '',
    id_formato: '',
    id_status: '', 
    
    id_roteiro_1: '',
    id_roteiro_2: '',
    id_arte_1: '',
    id_arte_2: '',
    
    generos: []
  }

  let filtro;
  !!form ? filtro = form : filtro = empty_form;
  
  const auth = props.store_user.token;
  const response = await api.post('/list-catalogo', filtro, auth);
  const { dispatch } = props;
  const lista = response.data;
  dispatch({ type: '@SET_CATALOGO', response: lista });
}

export default loadCatalogo;