import api from '@/services/api';
import { ShowMsg } from '@/components';
import processLogin from './processLogin';

const createLogin = async (props, form) => {
  const { email, password } = form;

  //// CRIAR USUARIO
  const res = await api.post('/add-new-usuario', form);

  if (res?.data?.error) {
    ShowMsg(
      'Atenção!',
      res?.data?.error,
      'error',
      3000,
      true,
   );
   
   return;
  }

  setTimeout(async () => {
    //// LOGIN
    const login = await api.post('/login', {
      email, 
      senha: password,
    });

    const newDados = {
      user: login.data,
      googleData: [],
      // pageAdmin: dados.pageAdmin,
   }
    
    //// PROCESSA LOGIN
    processLogin(props, newDados);
  }, 750);
};

export default createLogin;
