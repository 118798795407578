import React from "react";
import { connect } from "react-redux";
// import { autoLogin } from "@/functions";
import Search from "./search";
import Login from "./login";
import User from "./user";
import mapState from "./mapstate";
import "./navbar.less";
import Logo from "@/assets/img/logo_chapado.png";

const Navbar = (props) => {
  const { isLogin } = props;

  // public-navbar-expanded
  return (
    <>
      <div
        id="public-navbar"
        className="public-navbar shadow flex-container flex-justify-space-between pl-4 pr-4"
      >
        <div className="logo-container">
          <img src={Logo} alt="Logo Meus Mangás" />
          <Search {...props} />
        </div>
        {!isLogin ? <Login {...props} /> : <User {...props} />}
      </div>
    </>
  );
};

export default React.memo(connect(() => mapState)(Navbar));
