const mapState = state => ({
  isLogin: state.auth.isLogin,
  store_user: state.auth.store_user,

  store_review: state.comentarios.store_review,
  store_respostas: state.comentarios.store_respostas,
  store_interacoes: state.comentarios.store_interacoes,
});

export default mapState;
