import api from '@/services/api';

const loadInteracoes = async (props) => {
  const { dispatch, store_user, comentario } = props
  const auth = store_user.token;

  const form = {
    id_resposta: comentario.id,
    id_user: store_user.id,	
  }

  const response = await api.post(`/list-interacoes/`, form, auth);
  const respostas = response.data;

  // dispatch({ type: '@SET_ID_INTERACOES', payload: comentario.id })
  dispatch({ type: '@SET_INTERACOES', payload: respostas });
}

export default loadInteracoes;