import { combineReducers } from 'redux';
import { reducer as toastr } from "@/custom/react-redux-toastr";

import auth from './auth';
import amazon from './amazon';
import comentarios from './comentarios';
import manga from './manga';
import sidebar from './sidebar';
import sistema from './sistema';
import rank from './manga/rank';

export default combineReducers({
   toastr,
   
   auth,
   amazon,
   comentarios,
   manga,
   sidebar,
   sistema,
   rank,
});
