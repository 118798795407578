import React, { memo } from 'react';

const Titulo = ({ children, windowSize }) => (
  <>
    <hr className="bg-muted mt-0" style={{ width: '98%', marginRight: '2%' }} />
    <p style={{ letterSpacing: 1 }}
      className={`text-light mb-3 mt-1 pl-3 ${windowSize.size === 'XS' ? `h4 text-center` : `h5 text-left`}`}>
      <b>{ children }</b>
    </p>
  </>
)

export default memo(Titulo);
