 import React, { memo, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { TitleWeb, ReviewCard, ReviewBlock } from '@/components';
import { loadReviewUser } from '@/functions';
import mapState from './mapstate';

const Review = (props) => {
  const { dispatch, store_review_user, store_user, match } = props;

  const [isEditable, setIsEditable] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const handleEditar = useCallback(() => {
    setIsBlocked(!isBlocked)
  }, [isBlocked]);

  useEffect(() => {
    dispatch({ type: '@SET_REVIEW_USER', payload: [] })
    loadReviewUser(dispatch, store_user, match.params.id_manga)
  }, [dispatch, match, store_user])

  useEffect(() => {
    store_review_user[0]?.msg
      ? setIsBlocked(false)
      : setIsBlocked(true)

    store_review_user[0]?.msg
      ? setIsEditable(false)
      : setIsEditable(true)

    store_user.id !== 9999 && store_review_user.length > 0
      ? setIsVisible(true)
      : setIsVisible(false)  
  }, [store_user, store_review_user])

  return(
    isVisible 
    ? <>
        <TitleWeb 
          {...props} 
          titulo="Review" 
          subtitulo="Quais suas impressões sobre este Mangá?"
        />
        {
          isBlocked 
          ? <ReviewBlock {...props} handleEditar={() => handleEditar()} />
          : <ReviewCard {...props} handleEditar={() => handleEditar()} isEditable={isEditable} />
        }
      </>
    : null
  )
}

export default memo(connect(() => (mapState))(Review));
