
  const processUsersLimit = (windowSize) => {
    let limit = 1   
    if(windowSize.width >= 200)  { limit = 2 }
    if(windowSize.width >= 255)  { limit = 3 }
    if(windowSize.width >= 315)  { limit = 4 }
    if(windowSize.width >= 375)  { limit = 5 }
    if(windowSize.width >= 435)  { limit = 6 }
    if(windowSize.width >= 490)  { limit = 7 }
    if(windowSize.width >= 585)  { limit = 8 }
    if(windowSize.width >= 655)  { limit = 9 }
    if(windowSize.width >= 715)  { limit = 10 }
    if(windowSize.width >= 750)  { limit = 11 }
    if(windowSize.width >= 840)  { limit = 12 }
    if(windowSize.width >= 910)  { limit = 13 }
    if(windowSize.width >= 1030)  { limit = 14 }
    if(windowSize.width >= 1090)  { limit = 15 }
    if(windowSize.width >= 1230)  { limit = 16 }
    if(windowSize.width >= 1290)  { limit = 17 }
    if(windowSize.width >= 1385)  { limit = 18 }
    if(windowSize.width >= 1460)  { limit = 19 }
    if(windowSize.width >= 1540)  { limit = 20 }

    return limit;
  };

  export default processUsersLimit;
