import api from '@/services/api';

const loadReviewUser = async (dispatch, store_user, id_manga) => {
  const auth = store_user.token;

  const form = {
    id_manga,
    id_user: store_user.id,	
  }

  const response = await api.post(`/list-review-user/`, form, auth);
  const review = response.data;
  dispatch({ type: '@SET_REVIEW_USER', payload: review });
  // return review;
}

export default loadReviewUser;