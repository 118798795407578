import React from 'react';
import empty from '@/assets/capas/empty.jpg';
import { loadVolumes } from '@/functions';
import { CapaContainer } from '@/components';
import store from '@/store';
import { Props, IStore_volumes, TDispatch } from '@/types'
import { IListaVolumes } from './ListaVolumes.type';

const processModalColecaoArray = async (props: Props, dispatch: (p: TDispatch) => void, row: any) => {
  let manga: string;
  const store_user =  store.getState().auth.store_user;
  const store_colecao_user = store.getState().auth.store_colecao_user;

  const { id_manga } = row;
  
  const lista_volumes: React.ReactElement<IListaVolumes>[] = [];
  const volumes: IStore_volumes[] = await loadVolumes(dispatch, id_manga, store_user);

  dispatch({ type: '@SET_COLECAO_MODAL', response: row });
  dispatch({ type: '@SET_MODAL', response: '@MODAL_COLECAO_EDITAR' });

  const { id: id_user } = store_user;
  const { id: id_user_colecao } = store_colecao_user;

  ////// CAPAS VOLUMES
  volumes.forEach(volume => {
    const { id: id_volume, id_manga, Manga, numero, imgur_link , amazon_link, colecao } = volume;
    const { id_editora, nome: titulo, subtitulo, original } = Manga;
    manga = titulo;

    const capa = !!imgur_link ? imgur_link : empty;

    lista_volumes.push( 
      <CapaContainer {...props}       
        key={`capa-container-${id_manga}-${id_volume}`}
        dados={{
          titulo,
          subtitulo,
          original,
          numero,
          publicacao: false,
          paginas: false, 
          preco: false,
          editora: false, 
          status: false,
          id_manga, 
          id_volume,
          id_editora,
          comprar: true,
          location: '/colecao',
          amazon_link,
          capa,
        }}
        colecao={{
          active: id_user === id_user_colecao ? true : false,
          comprado: !!colecao ? true : false,
          total: 0,
        }}
        mouseHover={{
          active: false,
          totalLinha: 0,
        }}
        modal={{
          active: true,
          capaModal: true,
        }}
        noClick={{ 
          active: true 
        }}
      />
    );
  });
  
  setTimeout(() => dispatch({ type: '@SET_VOLUMES_MODAL', payload: { manga, volumes, lista_volumes } }), 750);
};

export default processModalColecaoArray;