import api from '@/services/api';

const loadAllMangas = async (props) => {
   const response = await api.get('/all-mangas');
   const { dispatch } = props;
   const lista = response.data;   
   dispatch({ type: '@SET_ALL_MANGAS', response: lista });
   // return lista;
}

export default loadAllMangas;