import { Props } from '@/types';
import { dadosHandle } from '@/functions/new/newColecao';
import { newColecao } from '@/functions';

const addColecao = async (props: Props, dados: dadosHandle) => {
  const { dispatch, store_user } = props;
  // let form: dadosHandle;

  // if (dados) {
    const form = dados;
  // } else {
  //   const { id_manga, id_volume, id_editora } = props;

  //   form = {
  //     id_user: store_user.id,
  //     id_manga,
  //     id_volume,
  //     id_editora,
  //     id_user_add: store_user.id,
  //     id_user_edt: store_user.id
  //   }
  // }
  store_user?.id === 0 && !!dispatch
    ? dispatch({ type: '@SET_MODAL', response: '@USER_LOGIN' })
    : newColecao(props, form);
}

export default addColecao;
