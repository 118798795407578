import React, { memo, useEffect } from 'react';
import { AppContainer } from '@/components';
import { Props } from '@/types';
import { useChecklist } from '@/hooks';

const Hooks: React.FC = (props: Props) => {
  const checklist = useChecklist();
  
  return (
    <div style={{ padding: 32 }}>
      <h3 style={{ color: 'white' }}>TESTES HOOKS</h3>
    </div>
  )
}

export default memo(Hooks);
