import React, { memo, useEffect, useState } from 'react';

import chibiCatalogo from '@/assets/img/chibi-catalogo.png';
import chibiColecao from '@/assets/img/chibi-colecao.png';
import chibiChecklist from '@/assets/img/chibi-checklist.png';

const Chibi = (props) =>  {
  const { match, image } = props;
  const page = match?.path.replace('/','');
  const [chibi, set_chibi] = useState('');
  const [chibiClass, set_chibiClass] = useState('');

  useEffect(() => {
    switch (page) {
      case 'catalogo': 
        set_chibi(chibiCatalogo);
        set_chibiClass('catalogo');
      break;
      case 'colecao': 
        set_chibi(chibiColecao);
        set_chibiClass('colecao');
      break;
      case 'checklist/:mes/:ano': 
        set_chibi(chibiChecklist);
        set_chibiClass('checklist');
      break;
      case 'minha-colecao/:share_id': 
        set_chibi(chibiColecao);
        set_chibiClass('colecao');
      break;
      default:
    }
  },[page])

  return (
    <>
      { 
        image && chibi ? 
          <img 
            src={chibi} 
            alt="chibi" 
            loading="lazy"
            className={`noselect public-chibi public-chibi-${chibiClass}`} 
          /> 
          : null 
      }
    </>
  )
}

export default memo(Chibi);
