import React, { memo } from 'react';
import { navigateTo, formatUrl } from '@/functions';

const Original = (props) => {
  ////// PROPS
  const { dados, noClick, modal, card_visibility } = props;
  const { titulo, subtitulo, original, id_manga } = dados;
  const { capaModal } = modal;

  const { active: isNoClick } = noClick;
  const link = () => navigateTo(props, `/info/${id_manga}/${formatUrl(titulo, subtitulo)}`, 8);
  
  return(
    <>
      {
        !card_visibility ?
          original && !capaModal
            ? <div className="text-info h6 pb-1 cursor"
                   onClick={ !isNoClick ? link : null }>
                <i>{ original }</i>
              </div> 
            : null
        : null
      }
    </>
  )
}

export default memo(Original);
