import axios, { AxiosInstance } from 'axios';

let api: AxiosInstance;
const isDevelop: boolean = import.meta.env.VITE_APP_ENV === 'DEVELOP';

const location = window.location.href.split(":");
const isHttps: boolean = location[0] === 'https';

api = axios.create({ 
  baseURL: `${isDevelop ? `http://localhost:3333` : `${isHttps ? `https` : `http`}://api.meusmangas.com.br:${isHttps ? `8443` : `3333`}`}` 
});

export default api;
