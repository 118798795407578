import api from '@/services/api';
import { ShowMsg } from '@/components';
import { checkRCI, loadRespostas, loadInteracoes } from '@/functions';

const newResposta = async (props, comentario, text_user) => {
  const { store_user, dispatch } = props;
  const auth = store_user.token;
  const { id } = comentario;
  const RCI = checkRCI(comentario);

  switch (RCI) {
    case 'REVIEW':
      /// ADD NEW RESPOSTA A REVIEW
      const resposta = {
        texto: text_user,
        id_review: id,
        id_user_add: store_user.id,
        id_user_edt: store_user.id,
      }
  
      await api.post('/add-new-respostas', resposta, auth)
               .then( null, error => ShowMsg({
                  title: 'Ops!',
                  message: error.response.data.error,
                  type: 'error',
                  tempo: 3000,
               }));
  
      ShowMsg(
        'Sucesso!',
        'Resposta enviada com sucesso.',
        'success',
      );
    break;
    case 'COMENTARIO':
      /// ADD INTERACAO A RESPOSTA
      const interacao = {

        texto: text_user,
        id_resposta: id,
        id_user_add: store_user.id,
        id_user_edt: store_user.id,
      }
  
      await api.post('/add-new-interacoes', interacao, auth)
               .then( null, error => ShowMsg({
                  title: 'Ops!',
                  message: error.response.data.error,
                  type: 'error',
                  tempo: 3000,
               }));

      loadRespostas(props, comentario.id_review);
      loadInteracoes(props, comentario.id);
      
      ShowMsg(
        'Sucesso!',
        'Resposta enviada com sucesso.',
        'success',
      );
    break;
    default:
  }
  await dispatch({ type: '@FLAG_NEW_REVIEW_TRUE' });
}

export default newResposta;
