const formatPublicacao = text => {

   let response = '';
   let _ano = text.substring(0,4);
   let _mes = text.substring(4,6);
   
   switch(_mes) {
      case "01": _mes = "Janeiro";     break;
      case "02": _mes = "Fevereiro";   break;
      case "03": _mes = "Março";       break;
      case "04": _mes = "Abril";       break;
      case "05": _mes = "Maio";        break;
      case "06": _mes = "Junho";       break;
      
      case "07": _mes = "Julho";       break;
      case "08": _mes = "Agosto";      break;
      case "09": _mes = "Setembro";    break;
      case "10": _mes = "Outubro";     break;
      case "11": _mes = "Novembro";    break;
      case "12": _mes = "Dezembro";    break;

      default: _mes = "";
   }

   response = _mes + " de " + _ano;

   return response
}

export default formatPublicacao;