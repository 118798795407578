import React from 'react';
import { AmazonContainer } from '@/components';
// import { getLogCompras } from 'functions';

const handleComprarAmazon = (link, data) => {
  // getLogCompras(data);
  window.open(link, '_blank');
};

const processAmazonOffers = (props) => {
  const _temp = [];

  const { store_amazon_offers, location, id_sessao, store_user } = props;

  if (store_amazon_offers.length === 0) { return }
  if (store_amazon_offers.Code === "NoResults") { return }

  store_amazon_offers.itens.forEach(item => {
    
    const data = {
      plataforma: 1,
      location: location.pathname,
      id_manga: 0,
      descricao: item?.ItemInfo.Title.DisplayValue,
      id_sessao,
      id_user: store_user.id,
    }
    
    _temp.push(
      <AmazonContainer 
        key={item?.ASIN}
        ASIN={item?.ASIN}
        onClick={() => handleComprarAmazon(item?.DetailPageURL, data)}
        isLoad={true}
        SRC={item?.Images.Primary.Medium.URL}
        PriceDisplayAmount={item?.Offers?.Listings[0]?.Price.DisplayAmount}
        SavingsPercentage={item?.Offers?.Listings[0]?.Price.Savings?.Percentage}
        TitleDisplayValue={item?.ItemInfo.Title.DisplayValue}
      />
    )
  })

  return _temp;
};

export default processAmazonOffers;
