import React, { memo } from 'react';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { AppContainer } from '@/components';
import { Props, IAuth } from '@/types';
import './style.css';

const TitleWeb = lazy(() => import('@/components/title'));
const CardCriarConta = lazy(() => import('@/components/card-criar-conta'));
const Tabs = lazy(() => import('./components/tabs'));
const Content = lazy(() => import('./components/content'));

const Colecao: React.FC = (props: Props) => {
  
  const store_user = useSelector(({ auth }: IAuth) => auth.store_user);

  return (
    <>
      <AppContainer {...props}>
        
        <Helmet>
          <title>Coleção - Meus Mangás</title>
        </Helmet>

        <Suspense fallback="">
          
          <TitleWeb
            {...props}
            titulo="minha coleção"
            subtitulo="lista completa dos seus mangás"
            image
          />

          {
            store_user.id !== 9999 || props?.match?.params?.share_id
            ? <>
                <Row className="p-0 m-0">
                  <Tabs {...props} />
                  <Content {...props} />
                </Row>
              </>
            : <CardCriarConta {...props} />
          }
          
        </Suspense>
      </AppContainer>
    </>
  );
}

export default memo(Colecao);
