import React, { memo, useEffect, useState, useCallback } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Row } from "reactstrap";
import { connect } from 'react-redux';
import { AppContainer } from '@/components';
import { processInfoArray, loadVolumes, loadReview } from '@/functions';
import mapState from './mapstate';
import './style.less';

const TitleWeb = lazy(() => import('@/components/title'));
const GridCapas = lazy(() => import('@/components/grid-capas'));
const OfertasAmazon = lazy(() => import('@/components/ofertas-amazon'));
const Recomendacoes = lazy(() => import('@/components/recomendacoes'));
const Review = lazy(() => import('@/components/review'));
const Comentarios = lazy(() => import('@/components/comentarios'));
const Stats = lazy(() => import('./components/stats'));

const Info = (props) => {
  const { 
    dispatch, 
    store_volumes, 
    store_user,
    windowSize, 
    match } = props;
  
  const [titulo, set_titulo] = useState('');
  const [manga_header, set_manga_header] = useState({ nome: '', subtitulo: '', original: '' });
  const [lista_capas, set_lista_capas] = useState([]);  
  
  const renderVolumes = useCallback((props) => {
    const top = document.getElementById("app-container");
    if (top) { top.scrollTo(0,0); }

    const { dispatch, store_volumes } = props;
    
    if (store_volumes.length === 0) { 
      set_titulo('');
      set_manga_header({ nome: '', subtitulo: '', original: '' }); 
      set_lista_capas([]); 
      return
    }

    const { Manga } = store_volumes[0];
    
    ////// STATUS GERAIS
    set_titulo(Manga.nome);
    set_manga_header({ 
      nome: Manga.nome, 
      subtitulo: Manga.subtitulo, 
      original: Manga.original,
    }); 

    set_lista_capas(processInfoArray(props));
    setTimeout(() => dispatch({ type: "@PUBLIC_LOADING_FALSE"}), 250);
  },[])
  
  /// FIRST LOAD
  useEffect(() => {
    const { params } = match;
    dispatch({ type: '@RESET_NOTAS' });
    dispatch({ type: '@RESET_VOLUMES' });
    dispatch({ type: '@RESET_AMAZON_OFFERS' });
    loadReview(props, params.id_manga);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { params } = match;
    loadVolumes(dispatch, params.id_manga, store_user);
    // eslint-disable-next-line
  }, [match, store_user, dispatch]);

  /// LOAD INFO
  useEffect(() => {
    renderVolumes(props);
    // eslint-disable-next-line
  },[store_volumes, windowSize]);

  return (
    <>
      <AppContainer {...props}>

        <Helmet>
          <title>{ titulo } - Meus Mangás</title>
        </Helmet>

        <Suspense fallback="">
          <TitleWeb
            {...props}
            titulo={titulo}
            addAll 
            manga={manga_header}
          />
          <Row className="p-0 m-0 pl-3">
            <Stats {...props} />
            <div className="public-info-covers">
              <GridCapas {...props} capas={lista_capas} customKey="p-0 m-0 info-covers" />
            </div>
          </Row>
          
          <OfertasAmazon {...props} />

          <Review {...props} titulo={titulo} />
          
          <Recomendacoes {...props} /> 
          
          <Comentarios {...props} id_manga={match.params.id_manga} />
        </Suspense>
      </AppContainer>
    </>
  )
}

export default memo(connect( () => (mapState))(Info));
