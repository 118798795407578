import { processModalColecaoArray } from '@/functions';

const processModalColecaoClick = async (props, row) => {
  const { dispatch } = props;
  dispatch({ type: '@RESET_VOLUMES_MODAL' });
  dispatch({ type: '@RESET_COLECAO_MODAL' });
  dispatch({ 
    type: '@SET_NOTAS', 
    response: {
      id: row.id_manga,
      nota_manga: row.nota,
      nota_user: row.nota_user,
      total_votos: row.total_votos,
    } 
  });

  processModalColecaoArray(props, dispatch, row);
};

export default processModalColecaoClick;