import React, { useState } from 'react';
import { useEffect } from 'react';
import { Avatar, Loader } from 'rsuite';
import { TypeAttributes, WithAsProps } from 'rsuite/esm/@types/common';
import './avatar-container.less';

interface propTypes extends WithAsProps {
  src?: string,
  size?: TypeAttributes.Size,
  title?: string,
  tooltip?: string
}

export default function AvatarContainer({ src, size, title, tooltip }: propTypes) {
  /** Primeira e Segunda Letra do nome da Pessoa */
  const nome = title?.toUpperCase().split(' ') || [''];
  let primeiraLetra = '';
  let segundaLetra = '';
  nome.map((nomeQuebrado, index) => {
    if (nomeQuebrado.length) {
      const letra = nomeQuebrado.split('')[0];
      if (index === 0) { primeiraLetra = letra; }
      else { 
        segundaLetra = letra;
        return false;
      }
    }
  })

  /** COTROLLERS */
  const bgColor = (Math.random() < 0.5) ? 'bg_avatar_segundaria' : 'bg_avatar_primaria'
  const [srcControle, muda_srcControle] = useState<string | undefined>(undefined);
  /** O carregando muda para false apenas: 
   * - quando eu tenho um erro na busca da imagem 
   * - quando o src vem como null ou undefined (vir como '', tem que deixar o carregando true)
   * 
   * não preciso mudar pra false quando dá tudo certo, o Avatar remove qualquer coisa dentro dele */
  const [carregando, muda_carregando] = useState<boolean>(true);

  /** INICIO */
  useEffect(() => { muda_carregando(true) }, []);

  /** AO TER MUDANÇA EM src */
  useEffect(() => { 
    muda_srcControle(src);

    const apenasNullouUndefined = !(src === null || typeof src === 'undefined');
    muda_carregando(apenasNullouUndefined);
  }, [src]);

  return (
    <Avatar
      circle
      imgProps={{
        loading: 'lazy',
        onError: () => { 
          muda_carregando(false);
          muda_srcControle(undefined); 
        }
      }}
      className={bgColor}
      alt={`imagem de usuário ${title || ''}`}
      title={tooltip}
      size={ size || 'sm' }
      src={srcControle}
    >{ carregando
      ? <Loader backdrop center></Loader>
      : `${primeiraLetra}${segundaLetra}`
    }</Avatar>
  );
};
