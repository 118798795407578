import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TitleWeb, GridCapas, CapaContainer } from '@/components';
import { loadAllMangasRecents, processRecentesArray, navigateTo } from '@/functions';
import { Props, ISistema, IManga } from '@/types';

const Recentes = (props: Props): JSX.Element => {
  const dispatch = useDispatch();

  const windowSize = useSelector(({ sistema }: ISistema) => sistema.windowSize);
  const store_all_mangas_recents = useSelector(({ manga }: IManga) => manga.store_all_mangas_recents);

  const [lista_recents, set_lista_recents] = useState<typeof CapaContainer[]>([]);

  /// FIRST LOAD
  useEffect(() => {
    loadAllMangasRecents({ dispatch }); 
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    set_lista_recents(processRecentesArray({ windowSize, store_all_mangas_recents, props }));
    // eslint-disable-next-line
  },[store_all_mangas_recents, windowSize]);

  return (
    <>
      <TitleWeb
        {...props}
        titulo="Mangás"
        subtitulo="Cadastrados recentemente"
      />
      <GridCapas 
        {...props} 
        listaCompleta
        customKey="recentes"
        capas={lista_recents}
        onClick={() => navigateTo(props, "/catalogo", 4)}
      />
    </>
  )
}

export default memo(Recentes);
