import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from "reactstrap";
import { BiFilter } from 'react-icons/bi';
import mapState from './mapstate';
import Chibi from './chibi';
import AddAll from './addAll';
import TitleLine from './title';
import './style.css';

const Title = (props) =>  {
  const { filter, id } = props;
  return (
    <>
      <Row id={id} className="p-4 m-0">
        <Col xs={12} className="flex-container flex-container-top flex-justify-space-between">
          
          <TitleLine {...props} />
          
          {
            filter
              ? <BiFilter size="36px" className="text-info cursor" />
              : null
          }
          
          <AddAll {...props} />

        </Col>

        <Chibi {...props} />

      </Row>
    </>
  )
}

export default memo(connect( () => (mapState))(Title));
