import React from "react";
import { GoogleLogin } from "react-google-login";
import { Button, Form, ButtonToolbar } from "rsuite";
import { ShowMsg } from "@/components";
import { login } from "@/functions";
import * as T from "./types";

export const Divisor: React.FC = () => (
  <hr className="mt-4 mb-4" style={{ borderColor: "#6c757d" }} />
);

export const ButtonContainer: React.FC<T.IButtonContainer> = ({
  children,
  align,
}) => (
  <ButtonToolbar
    className={`flex-container flex-justify-${align} pt-0 pb-0 pr-5 pl-5`}
  >
    {children}
  </ButtonToolbar>
);

export const ButtonModal: React.FC<T.IButton> = ({
  label,
  onClick,
  color,
  submit,
  disabled,
}) => (
  <Button
    type={`${submit ? `submit` : `button`}`}
    appearance="primary"
    className={`pl-4 pr-4 ${color ? `bg-${color}` : ``}`}
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </Button>
);

export const TextField: React.FC<T.ITextField> = ({
  name,
  label,
  autoComplete,
  type,
  disabled,
}) => (
  <Form.Group controlId={`${name}-login`} className="mt-0 pt-0">
    <Form.ControlLabel className="text-muted pb-1 pl-5 text-left">
      {label.toUpperCase()}
    </Form.ControlLabel>
    <Form.Control
      style={{ width: 325 }}
      name={name}
      disabled={disabled}
      autoComplete={autoComplete}
      type={type}
    />
  </Form.Group>
);
