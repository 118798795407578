import React, { memo } from 'react';
import FooterText from './footerText';
import { Col } from "reactstrap";

const Copyright = () => (
  <Col className="col-12 pb-3 text-center">
    <FooterText top="0">
      © Copyright 2021: Meus Mangás - Design e Desenvolvimento: Danilo Rocha
    </FooterText>
  </Col>
)

export default memo(Copyright);
