import api from '@/services/api';
import { Props, LoadVolumes } from '@/types';
import { ShowMsg } from '@/components';

const deleteColecao = async ( props: Props, id_volume: LoadVolumes['id'] ) => {
  const { dispatch, store_user } = props;
  const auth = store_user.token;
  const id_user = store_user.id;
  await api.delete(`/del-colecao/${id_user}/${id_volume}`, auth);
  dispatch({
    type: '@PUBLIC_COLECAO_ACTION',
    response: { add: false, remove: true, id_volume }
  });
  ShowMsg(
    'Sucesso!',
    'Volume removido da coleção com sucesso.',
    'info',
    2500,
  ); 
}

export default deleteColecao;
