import React, { memo, useState, useEffect } from 'react';
import { Row } from "reactstrap";
import { loadStats } from '@/functions';
import { connect } from 'react-redux';
import Links from './links';
import Contato from './contato';
import Stats from './stats';
import Desc from './desc';
import Copyright from './copyright';
import Donation from '../donation';
import mapState from './mapstate';
import './styles.css';

const Footer = (props) => {
  const { new_review_flag, dispatch, windowSize } = props;

  const initial = {
    doacao_meta: 0,
    doacao_total: 0,
    total_artistas: 0,
    total_editoras: 0,
    total_mangas: 0,
    total_usuarios: 0,
    total_volumes: 0,
  }

  const [stats, set_stats] = useState(initial);
  const [meta, set_meta] = useState(0);
  const [total, set_total] = useState(0);

  useEffect(() => {
    (async () => {
      const res = await loadStats();
      set_stats(res);
      set_meta(res.doacao_meta);
      set_total(res.doacao_total);
    })()
  }, [])

  useEffect(() => {
    if (new_review_flag) {
      dispatch({ type: '@FLAG_NEW_REVIEW_FALSE' });
      (async () => {
        const res = await loadStats();
        set_stats(res);
      })()
    }
  }, [new_review_flag, dispatch])

  return(
    <>
      <footer className="footer text-muted bg-primary-dark mt-5">
        <Row className="footer-container">

          <Donation {...props} meta={meta} total={total} />

          <Links {...props} />
          <Contato {...props} />
          <Stats {...props} stats={stats} />
          <Desc {...props} />
          
          <Copyright {...props} />
          
          {
            import.meta.env.VITE_APP_ENV === 'DEVELOP'
            ? <div className="bg-danger text-white footer-develop">
                <p className="p-0 m-0">
                  DEVELOP MODE
                  {`- ${windowSize.size} - ${windowSize.width}px`}
                </p>
              </div>
            : null
          }

        </Row>
      </footer>
    </>
  )
}

export default memo(connect(() => (mapState))(Footer));
