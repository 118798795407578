import React, { memo } from 'react';

const Paginas = ({ dados, card_visibility }) => (
  <>
    {
      !card_visibility ?
      dados?.paginas && dados?.preco
        ? <>
            <p className="h6 pt-2 m-0 text-muted">{ dados?.paginas } páginas</p>
            <p className="h6 pt-2 m-0 text-orange">R$ { parseFloat(dados?.preco).toFixed(2) }</p>
          </>
        : null
      : null
    }
  </>
)

export default memo(Paginas);
