const mapState = state => ({
  store_user: state.auth.store_user,
  store_colecao_user: state.auth.store_colecao_user,
  
  store_nota: state.manga.store_nota,
  store_volumes_modal: state.manga.store_volumes_modal,
  store_volumes_modal_nome: state.manga.store_volumes_modal_nome,
  store_volumes_modal_elements: state.manga.store_volumes_modal_elements,
  store_colecao_modal: state.manga.store_colecao_modal,

  colecao_action: state.sistema.colecao_action,
  colecao_btnAll_action: state.sistema.colecao_btnAll_action,
  windowSize: state.sistema.windowSize,
});

export default mapState;